<template>
  <div class="list-item">
    <router-link replace to="/register">注册账号</router-link>
  </div>
  <div class="list-item">
    <router-link replace to="/">立即登录</router-link>
  </div>
  <div class="list-item">
    <router-link replace to="/forget">忘记密码</router-link>
  </div>
</template>

<script>
export default {
  name: 'LoginNav',
}
</script>

<style scoped>
.list-item {
  padding: 8px 0;
}

.list-item a {
  display: block;
  padding: 4px 16px;
}

.router-link-active {
  background-color: var(--app-color-primary);
  border-radius: 4px;
  color: #ffffff;
}
</style>
