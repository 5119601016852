<template>
  <a-spin :spinning="loading" tip="请稍等...">
    <a-card :bordered="false">
      <a-form ref="formRef" :model="formData" :rules="formRules" layout="vertical">
        <a-form-item label="标价" name="price">
          <a-input-number v-model:value="formData.price" addon-before="￥" addonAfter="RMB" placeholder="请输入标价"/>
        </a-form-item>
        <a-form-item label="标题" name="title">
          <a-input v-model:value="formData.title" placeholder="请输入标题" type="text"/>
        </a-form-item>
        <a-form-item label="描述" name="desc">
          <a-textarea v-model:value="formData.desc" :auto-size="{ minRows: 3, maxRows: 5 }" placeholder="请输入描述"/>
        </a-form-item>
        <a-form-item label="内容" name="content">
          <div ref="editor" class="editor-wrap"></div>
        </a-form-item>
        <br>
        <div class="text-center">
          <a-button :loading="loading" type="primary" @click="submitForm">提交</a-button>
        </div>
      </a-form>
    </a-card>
  </a-spin>
</template>

<script>
import {defineComponent, getCurrentInstance, onBeforeUnmount, onMounted, reactive, ref, toRaw} from "vue"
import WangEditor from "wangeditor"

export default defineComponent({
  emits: ['drawerClose'],
  props: ['drawerData'],
  setup() {
    const loading = ref(false)
    const {proxy} = getCurrentInstance()
    proxy.$store.dispatch('aliyun/setStsConfig')//上传操作前，检测STS
    /*----编辑器----*/
    const editor = ref()
    let instance
    onMounted(() => {
      instance = new WangEditor(editor.value)
      instance.config.height = 400
      instance.config.menus = [
        'bold',
        'fontSize',
        'italic',
        'underline',
        'strikeThrough',
        'foreColor',
        'backColor',
        'link',
        'list',
        'justify',
        'quote',
        'image',
        'video',
        'table',
      ]
      //上传视频
      instance.config.uploadVideoMaxSize = 204800
      instance.config.uploadVideoAccept = ['mp4']
      instance.config.showLinkVideo = false
      instance.config.customUploadVideo = function (files, insertVideoFn) {
        loading.value = true
        let d = files[0]
        let ext = proxy.$tools.getFileExt(d.name)
        let fileName = proxy.$tools.makeFileName()
        proxy.$oss.upload('editor', fileName + ext, d).then(r => {
          loading.value = false
          if (r && r.res.statusCode === 200) {
            insertVideoFn(proxy.$oss.sign(r.name))
          } else {
            proxy.$message.error('上传中断，请刷新页面后重试！')
            return false
          }
        })
      }
      //上传图片
      instance.config.uploadImgMaxLength = 1
      instance.config.uploadImgMaxSize = 10 * 1024 * 1024
      instance.config.showLinkImg = false
      instance.config.customUploadImg = async function (files, insertImgFn) {
        loading.value = true
        let d = files[0]
        let isSize = d.size / 1024 / 1024 < 1, baseFile = await proxy.$tools.getBase64(d)
        if (!isSize) {
          let obj = await proxy.$img.imgCompress(baseFile, {width: 1000, quality: 0.75})
          baseFile = obj.img
          d = proxy.$img.dataURLtoFile(baseFile, 'icon.jpg')
        }
        let ext = proxy.$tools.getFileExt(d.name)
        let fileName = proxy.$tools.makeFileName()
        proxy.$oss.upload('editor', fileName + ext, d).then(r => {
          loading.value = false
          if (r && r.res.statusCode === 200) {
            insertImgFn(proxy.$oss.sign(r.name))
          } else {
            proxy.$message.error('上传中断，请刷新页面后重试！')
            return false
          }
        })
      }
      instance.create()
      instance.txt.html(formData.content)
    })

    onBeforeUnmount(() => {
      instance.destroy()
      instance = null
    })

    const formRef = ref()
    let validPrice = async (_rule, value) => {
      if (!value) {
        return Promise.reject('标价必须填写！')
      }
      if (!Number.isInteger(value)) {
        return Promise.reject('请输入标价(整数)');
      } else {
        if (value < 1 || value > 100) {
          return Promise.reject('标价范围1-100');
        } else {
          return Promise.resolve();
        }
      }
    }
    const formData = reactive({id: '', title: '', desc: '', content: '', price: undefined})
    const formRules = reactive({
      price: [
        {required: true, validator: validPrice, trigger: 'change',},
      ],
      title: [
        {required: true, message: '名称必须填写！', trigger: 'change',},
        {max: 64, message: '名称最多64个字符！', trigger: 'blur',},
      ],
      desc: [
        {required: true, message: '描述必须填写！', trigger: 'change',},
        {max: 200, message: '描述最多200字符！', trigger: 'blur',},
      ],
      content: [
        {required: true, message: '内容必须填写！', trigger: 'change',},
      ],
    })
    const submitForm = () => {
      loading.value = true
      formData.content = proxy.$tools.replaceSrc(instance.txt.html())
      formRef.value.validate().then(async () => {
        //提交方法
        requestSaveData(toRaw(formData))
        //提交方法
      }).catch(() => {
        loading.value = false
      })
    }
    //发送请求
    const requestSaveData = obj => {
      proxy.$requests.investSave(obj).then(r => {
        if (r.code === 0) {
          proxy.$message.success(r.msg, 1.2).then(
              () => {
                loading.value = false
                proxy.$emit('drawerClose')
              },
              () => {
              }
          )

        } else {
          loading.value = false
          proxy.$message.error(r.msg)
        }
      })
    }
    /*----初始化数据----*/
    if (proxy.$props.drawerData && Object.keys(proxy.$props.drawerData).length !== 0) {
      try {
        formData.id = proxy.$props.drawerData.id
        formData.price = proxy.$props.drawerData.price
        formData.title = proxy.$props.drawerData.title
        formData.desc = proxy.$props.drawerData.desc
        formData.content = proxy.$tools.replaceSrc(proxy.$props.drawerData.content, {
          image: {width: '30%'},
          video: {width: '640', height: '360'}
        })
      } catch (e) {
        proxy.$message.error('未知错误', 1.2).then(() => {
          proxy.$emit('drawerClose')
        }, () => {
        })
      }
    }
    return {
      editor,
      loading,
      formRef,
      formData,
      formRules,
      submitForm,
    }
  }
})
</script>
