<template>
  <a-spin :spinning="loading" tip="请稍等...">
    <a-card :bordered="false">
      <div class="title-panel">
        <div class="title">主体信息</div>
        <div class="sub-title">
          <router-link class="text-primary" to="/certify">信息有误，我要变更</router-link>
        </div>
      </div>
      <a-descriptions :column="2">
        <template v-if="tpl && tpl.account_type === 1">
          <a-descriptions-item label="账户类型">个人</a-descriptions-item>
          <a-descriptions-item label="证件类型">身份证</a-descriptions-item>
          <a-descriptions-item label="姓名">{{ tpl.name }}</a-descriptions-item>
          <a-descriptions-item label="身份证">{{ tpl.id_card }}</a-descriptions-item>
        </template>
        <template v-if="tpl && tpl.account_type === 2">
          <a-descriptions-item label="账户类型">企业</a-descriptions-item>
          <a-descriptions-item label="企业法人">{{ tpl.legal_person }}</a-descriptions-item>
          <a-descriptions-item label="企业名称">{{ tpl.company_name }}</a-descriptions-item>
          <a-descriptions-item label="社会信用代码">{{ tpl.social_code }}</a-descriptions-item>
        </template>
      </a-descriptions>
      <br>
      <div class="title-panel">
        <div class="title">项目信息</div>
      </div>
      <a-descriptions :column="2">
        <a-descriptions-item label="项目名称">{{ tpl?.project_name }}</a-descriptions-item>
        <a-descriptions-item label="项目区域">{{ tpl?.prov }}{{ tpl?.city }}{{ tpl?.district }}</a-descriptions-item>
        <a-descriptions-item label="详细地址">{{ tpl?.address }}</a-descriptions-item>
      </a-descriptions>
      <br>
      <div class="title-panel">
        <div class="title">补充材料</div>
        <div class="sub-title text-primary cursor" @click="contractDownLoadBtn">下载合同</div>
      </div>

      <a-form ref="formRef" :label-col="{style:{width:'100px'}}" :model="formData" :rules="formRules">
        <a-row :gutter="20">
          <a-col :span="24">
            <a-form-item label="合同材料" name="contract" style="min-height: 120px">
              <a-upload v-model:file-list="fileList" :before-upload="beforeUpload"
                        list-type="picture-card" @change="uploadChange" @preview="handlePreview">
                <div v-if="fileList.length < 5">
                  <plus-outlined/>
                  <div class="ant-upload-text">添加</div>
                </div>
              </a-upload>
              <a-modal :footer="null" :visible="previewVisible" @cancel="cancelPreview">
                <img :src="previewImage" alt="example" style="width: 100%"/>
              </a-modal>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <a-alert v-if="tpl?.status===3 && tpl?.verify_content" :message="tpl?.verify_content" type="warning"/>
      <br>
      <br>
      <div v-if="!loading" style="display: flex;justify-content: center;align-items: center">
        <a-button style="min-width: 160px" type="primary" @click="submitBtn">提 交</a-button>
      </div>
      <br>
      <div style="color: #f60c16;font-size: 13px;">TIPS1: 每一个项目的合同材料，都是由系统自动生成</div>
      <div style="color: #f60c16;font-size: 13px;">TIPS2: 确认提交后，系统会自动检查数据材料是否需要补充</div>

      <div class="contract-wrap">
        <div id="contract-id">
          <div class="contract-item">
            <div class="contract-sn">合同编号：{{ tpl?.sn }}-1</div>
            <p class="title-two">房地产代理销售协议</p>
            <p class="title-two">（底价包销合作方式）</p>
            <br>
            <p class="no-indent">委托代理方（甲方）：</p>
            <p class="no-indent">地址：</p>
            <br>
            <p class="no-indent">代理方（乙方）：苏州慧智荟智慧建筑科技有限公司</p>
            <p class="no-indent">地址：苏州工业园区东沈浒路200号315</p>
            <br>
            <p>
              甲乙双方经过友好协商，根据《中华人民共和国民法通则》和《中华人民共和国合同法》的有关规定，在平等互利的基础上，本着“精诚合作，互惠互利，共同发展”的原则，就甲方委托乙方（独家）代理销售甲方开发经营或拥有的＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿项目达成以下协议，并承诺共同遵守：</p>
            <p class="title-one">一、底价包销合作方式</p>
            <p>1、合作范围;</p>
            <p>2、甲方指定乙方为在（地区）的独家销售代理;</p>
            <p>3、销售甲方指定的由甲方在＿＿＿＿＿＿＿＿＿＿＿＿＿＿兴建的＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿项目;</p>
            <p>4、该项目为住宅;</p>
            <p>5、销售面积共计＿＿＿＿＿平方米。</p>
            <p class="title-one">二、包销价格</p>
            <p>销售基价（本代理项目的均价）由甲乙双方确定为＿＿＿＿＿元／平方米。</p>
            <p class="title-one">三、保证金支付方式及数额：</p>
            <p>1、代销协议签订后十个工作日以内乙方支付甲方定金人民币＿＿＿＿＿万元。</p>
            <p>2、五证齐全后乙方再支付甲方人民币＿＿＿＿＿万元。</p>
            <p class="title-one">四、 代理佣金及支付</p>
            <p>1、乙方的代理佣金为实际销售价格超出销售基价的所有款项，代理佣金由甲方以人民币形式支付。</p>
          </div>
          <div class="contract-item">
            <div class="contract-sn">合同编号：{{ tpl?.sn }}-2</div>
            <p>2、甲方同意按下列方式支付代理佣金：
              甲方在正式销售合同签订并获得首期房款后，乙方对该销售合同中指定房地产的代销责任即告完成，以签订《商品房购销合同》的总金额为依据按月全额结算，并按比例同期返还乙方所交纳的保证金，由甲方在结算后的３天内将代理佣金和返还的保证金全部支付乙方，乙方在收到甲方转来的代理佣金后应开具收据。</p>
            <p>3、因客户对临时买卖合同违约而没收的定金和签订《商品房购销合同》后收取的违约金，由乙方支配。</p>
            <p class="title-one">五、合作期限</p>
            <p>1、本合同约定的代理期限为＿＿＿＿个月，五证齐全后开始计算销售时间；</p>
            <p>2、在本合同有效代理期内，除非甲方或乙方违约，双方不得单方面终止本合同；</p>
            <p>3、在本合同有效代理期内，甲方不得指定其他代理商。</p>
            <p class="title-one">六、 费用负担</p>
            <p>本项目的推广费用、销售人员薪金及日常费用由乙方承担。</p>
            <p class="title-one">七、 甲方的责任</p>
            <p>1、甲方应向乙方提供以下文件和资料：</p>
            <p>（1）甲方营业执照副本复印件和银行帐户。</p>
            <p>
              （2）新开发建设项目，甲方应提供政府有关部门对开发建设＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿项目批准的五证（包括：国有土地使用权证、建设用地规划许可证、建设工程规划许可证、建设工程施工许可证、商品房销售（预售）许可证）；
              旧有房地产，甲方应提供房屋所有权证书、国有土地使用权证书。</p>
            <p>（3）关于代售项目的所需的有关资料，包括：外形图、平面图、地理位置图、室内设备、建设标准、电器配备、楼层高度、面积、规格及物管费的估算等。</p>
            <p>（4）乙方代理销售该项目所需的收据、销售合同，以实际使用的数量为准，余数全部退给甲方。以上文件和资料，甲方应于本合同签订后＿＿＿＿天内向乙方交付齐全。
              甲方保证若客户购买的＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿的实际情况与其提供的材料不符合或产权不清，所发生的任何纠纷均由甲方负责。</p>
            <p>2、甲方应积极配合乙方的销售，并保证乙方客户所订的房号不发生误打。</p>
            <p>3、甲方应按时按本合同的规定向乙方支付各项费用。</p>
          </div>
          <div class="contract-item">
            <div class="contract-sn">合同编号：{{ tpl?.sn }}-3</div>
            <p class="title-one">八、 乙方的责任</p>
            <p>1、在合同期内，乙方按照约定的销售计划进行销售，并配合甲方财务人员进行账款的催受事宜。</p>
            <p>2、乙方在销售过程中，应根据甲方提供的＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿项目的特性和状况向客户作如实介绍，尽力促销，不得夸大、隐瞒或过度承诺。</p>
            <p class="title-one">九、合同的终止和变更</p>
            <p>
              1、在本合同到期时，双方若同意终止本合同，双方应通力协作作妥善处理终止合同后的有关事宜，结清与本合同有关的法律经济等事宜。本合同一但终止，双方的合同关系即告结束，甲乙双方不再互相承担任何经济及法律责任，但甲方未按本合同的规定向乙方支付应付费用的除外。</p>
            <p>2、经双方同意可签订变更或补充合同，其条款与本合同具有同等法律效力。 </p>
            <p class="title-one">十、违约责任</p>
            <p>1、如甲方不按期向乙方支付应得的代理费或应返还的保证金，则每延迟一天，甲方须向乙方支付 3‰ 的滞纳金，超过30天则按甲方违约处理，并向乙方补偿相应的损失。</p>
            <p>2、因不可抗力的因素造成经济损失，由甲、乙双方各自承担。</p>
            <p>3、在签署本合同后至合同期满止，如甲方违约不将该项目交由乙方总代理或中途无故终止合同，则甲方需向乙方双倍返还已经支付的保证金；如乙方违约无故终止合同，则乙方需向甲方赔偿经济损失＿＿＿＿＿万元人民币。</p>
            <p class="title-one">十一、其他条款</p>
            <p>1、甲方派设专职财务人员负责收款，办理银行按揭，乙方全力进行协助。</p>
            <p>2、本合同自双方签字盖章之日起生效，委托期限结束，代理费在十天内结算，如未能按时结算，该合同继续生效，结清后合同自动终止。</p>
            <p>3、如有未尽事宜，双方应本着友好合作的态度协商解决，修改条款由甲、乙双方共同签定补充合同。补充合同与本合同享有同等法律效力。如协商不能解决，约定由该项目所在地仲裁机构或人民法院裁定。</p>
            <p>4、本合同一式四份，双方各执两份，具有同等法律效力。本合同一式四份，双方各执两份，具有同等法律效力。</p>
          </div>
          <div class="contract-item contract-end">
            <div class="contract-sn">合同编号：{{ tpl?.sn }}-4</div>
            <p class="no-indent"><span>甲方：</span><span>乙方：苏州慧智荟智慧建筑科技有限公司</span></p>
            <p class="no-indent"><span>法定代表人：（盖章）</span><span>法定代表人：（盖章）</span></p>
            <p class="no-indent"><span>委托代理人：</span><span>委托代理人：</span></p>
            <p class="no-indent"><span>地址：</span><span>地址：</span></p>
            <p class="no-indent"><span>邮政编码：</span><span>邮政编码：</span></p>
            <p class="no-indent"><span>联系电话：</span><span>联系电话：</span></p>
            <p class="no-indent"><span>签约时间：</span><span>签约时间：</span></p>
          </div>
        </div>
      </div>
    </a-card>
  </a-spin>
</template>

<script>
import {defineComponent, getCurrentInstance, onMounted, reactive, ref, toRaw} from "vue"

export default defineComponent({
  emits: ['drawerClose'],
  props: ['drawerData'],
  setup() {
    const loading = ref(false)
    const {proxy} = getCurrentInstance()
    proxy.$store.dispatch('aliyun/setStsConfig')//上传操作前，检测STS
    const tpl = ref()
    const contractDownLoadBtn = async () => {
      loading.value = true
      await proxy.$pdf.houseContractToPDF('contract-id', tpl.value?.project_name + tpl.value?.sn)
      loading.value = false
    }
    /*----表单---*/
    const formRef = ref()
    const formData = reactive({
      id: '',
      contract: [],
    })
    const formRules = reactive({
      contract: [
        {required: true, message: '合同材料必须上传！', trigger: 'change',},
      ],
    })

    /*-----上传----*/
    const previewVisible = ref(false)
    const previewImage = ref('')
    const fileList = ref([])
    //取消预览
    const cancelPreview = () => {
      previewVisible.value = false
    }
    //预览图片
    const handlePreview = async file => {
      previewImage.value = file.thumbUrl
      previewVisible.value = true
    }
    const beforeUpload = () => {
      return false
    }
    const handleChange = ({fileList: newFileList}) => {
      fileList.value = newFileList
    }
    const uploadChange = async (r) => {
      let fileData = r.file
      if (r.fileList.length === 0) {
        fileList.value = []
        formData.contract = []
      }
      if (fileData.type !== 'image/jpeg' && fileData.type !== 'image/png') {
        fileList.value.pop()
        proxy.$message.error('上传图片格式错误，只支持jpg/jpeg/png', 2)
        return false
      }
      if (fileData.status && fileData.status === "removed") {
        return false
      }

      let isSize = fileData.size / 1024 / 1024 < 1, baseFile = await proxy.$tools.getBase64(fileData)
      // 大图重新绘制
      if (!isSize) {
        let obj = await proxy.$img.imgCompress(baseFile, {width: 1000, quality: 0.75})
        baseFile = obj.img
        let d = proxy.$img.dataURLtoFile(baseFile, 'icon.jpg')
        fileList.value.pop()
        fileList.value.push({
          lastModified: d.lastModified,
          lastModifiedDate: d.lastModifiedDate,
          originFileObj: d,
          percent: 0,
          size: d.size,
          thumbUrl: baseFile,
          type: d.type,
          name: fileData.name,
          uid: fileData.uid,
        })
        formData.contract.push(d)
      } else {
        formData.contract.push(fileData)
      }

      formRef.value.validate('contract')
    }

    //提交
    const submitBtn = () => {
      loading.value = true
      formRef.value.validate().then(async () => {
        let files = formData.contract
        for (let i = 0; i < files.length; i++) {
          if (files[i] instanceof File) {
            let ext = proxy.$tools.getFileExt(files[i].name);
            let fileName = proxy.$tools.makeFileName();
            await proxy.$oss.upload('contract', fileName + ext, files[i]).then(r => {
              if (r && r.res.statusCode === 200) {
                files[i] = r.name
              } else {
                proxy.$message.error('上传中断，请刷新页面后重试！')
                return false;
              }
            })
          }

        }

        formData.id = tpl.value.id
        //提交方法
        requestSaveData(toRaw(formData))
        //提交方法
      }).catch(() => {
        loading.value = false
      })

    }
    //发送请求
    const requestSaveData = obj => {
      proxy.$requests.setProjectAddendum(obj).then(r => {
        if (r.code === 0) {
          proxy.$message.success(r.msg, 1.5).then(() => {
            loading.value = false
            proxy.$emit('drawerClose')
          })
        } else {
          proxy.$message.error(r.msg, 2.5).then(() => {
            loading.value = false
          })
        }
      })
    }

    onMounted(async () => {
      /*----初始化数据----*/
      try {
        loading.value = true
        if (!proxy.$props.drawerData || Object.keys(proxy.$props.drawerData).length === 0) {
          throw '页面数据加载失败！'
        }
        tpl.value = proxy.$props.drawerData
        if (proxy.$props.drawerData?.contract) {
          let img = proxy.$props.drawerData.contract.split(','), arr = []
          for (let i = 0; i < img.length; i++) {
            arr.push({
              uid: i + 1,
              name: '材料' + (i + 1),
              status: 'done',
              thumbUrl: proxy.$oss.sign(img[i]),
            })
          }
          // console.log(arr)
          fileList.value = arr
          formData.contract = img
        }
      } catch (e) {
        proxy.$message.error('未知错误  ' + e, 2.5).then(() => {
          proxy.$emit('drawerClose')
        }, () => {
        })
      } finally {
        loading.value = false
      }
    });

    return {
      loading,
      tpl,
      formRef,
      formData,
      formRules,
      submitBtn,
      fileList,
      previewVisible,
      previewImage,
      cancelPreview,
      handlePreview,
      beforeUpload,
      handleChange,
      uploadChange,
      contractDownLoadBtn
    }
  }
})
</script>


<style scoped>
.contract-wrap {
  width: 800px;
  overflow: hidden;
  opacity: 0;
  height: 1px;
}

.contract-item {
  width: 595px;
  height: 842px;
  padding: 75px 60px;
  font-family: 宋体, serif;
  position: relative;
}

.contract-item p {
  line-height: 19px;
  margin-bottom: 4px;
  text-indent: 2em;
  font-size: 13px;
}

.contract-item .title-one {
  text-indent: 0;
  font-size: 13px;
  font-weight: 700;
  margin-top: 12px;
}

.contract-item .title-two {
  text-indent: 0;
  font-size: 17px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
}

.contract-item .no-indent {
  text-indent: 0;
}

.contract-end {
  margin-top: 300px;
}

.contract-end p {
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
}

.contract-end p span {
  display: block;
  width: 50%;
}

.contract-sn {
  position: absolute;
  left: 60px;
  top: 30px;
  font-size: 8px;
  -webkit-transform-origin-x: 0;
  -webkit-transform: scale(0.60);
}

</style>
