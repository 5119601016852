import html2canvas from 'html2canvas';
import jsPDF from 'jspdf'

export default {
    test: function () {
        console.log('test')
    },
    houseContractToPDF: async (htmlId, title = "合同", bgColor = "#fff") => {
        let PDF = new jsPDF("p", 'pt', 'a4');
        let pdfDom = document.getElementById(htmlId)
        const childNodes = pdfDom.children;
        for (let i = 0; i < childNodes.length; i++) {
            const childNode = childNodes[i];
            const A4Width = 595.28;
            let canvas = await html2canvas(childNode, {
                scale: 2,
                useCORS: true,
                backgroundColor: bgColor,
            });

            let imgWidth = A4Width;
            let imgHeight = (A4Width / canvas.width) * canvas.height;
            let pageData = canvas.toDataURL("image/jpeg", 1.0);
            PDF.addImage(pageData, "JPEG", 0, 0, imgWidth, imgHeight);
            if (i + 1 < childNodes.length) {
                PDF.addPage();
            }
        }
        PDF.save(title + ".pdf");

    },
    htmlToPDF: async (htmlId, title = "报表", bgColor = "#fff") => {
        let pdfDom = document.getElementById(htmlId)
        const A4Width = 595.28;
        const A4Height = 841.89;
        let canvas = await html2canvas(pdfDom, {
            scale: 2,
            useCORS: true,
            backgroundColor: bgColor,
        });
        let pageHeight = (canvas.width / A4Width) * A4Height;
        let leftHeight = canvas.height;
        let position = 0;
        let imgWidth = A4Width;
        let imgHeight = (A4Width / canvas.width) * canvas.height;
        /*
         根据自身业务需求  是否在此处键入下方水印代码
        */
        let pageData = canvas.toDataURL("image/jpeg", 1.0);
        let PDF = new jsPDF("p", 'pt', 'a4');
        if (leftHeight < pageHeight) {
            PDF.addImage(pageData, "JPEG", 0, 0, imgWidth, imgHeight);
        } else {
            while (leftHeight > 0) {
                PDF.addImage(pageData, "JPEG", 0, position, imgWidth, imgHeight);
                leftHeight -= pageHeight;
                position -= A4Height;
                if (leftHeight > 0) PDF.addPage();
            }
        }
        PDF.save(title + ".pdf");
    }

}
