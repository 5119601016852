<template>
  <ul class="nav-box header-menu-panel">
    <li>
      <a :href="website.site_domain?website.site_domain:'/'" target="_blank">网站</a>
    </li>
    <li>企业</li>
    <li class="cursor" @click="msgDrawerData.visible=!msgDrawerData.visible">
      <a-badge :count="listMsg.length" dot>
        通知
      </a-badge>
    </li>
    <li>
      <a-dropdown>
        <div style="cursor: pointer;height: 100%;">
          <a-avatar v-if="userinfo && userinfo.avatar" :size="30" :src="$oss.sign(userinfo.avatar)"></a-avatar>
          <a-avatar v-else :size="30" style="background-color: var(--app-color-primary)">
            <template #icon>
              <UserOutlined/>
            </template>
          </a-avatar>
        </div>
        <template #overlay>
          <a-menu class="user-drop-wrap">
            <div v-if="userinfo" class="user-drop-info">
              <div v-if="userinfo.avatar" :style="{backgroundImage: 'url(' + $oss.sign(userinfo.avatar) + ')'}"
                   class="bg-cover user-avatar">
              </div>
              <div v-else class="user-avatar">
                <UserOutlined/>
              </div>
              <span style="padding-left: 12px;">{{ userinfo.nickname ? userinfo.nickname : '用户' }}</span>
            </div>
            <a-menu-divider/>
            <a-menu-item v-for="(item,index) in userNav.data" :key="index">
              <router-link :to="item.link">
                <component :is="item.icon"></component>
                {{ item.name }}
              </router-link>
            </a-menu-item>
            <a-menu-divider/>
            <a-menu-item>
              <a class="text-center" @click="logout">
                <LogoutOutlined/>
                退出控制台
              </a>
<!--              <router-link class="text-center" to="/login">-->
<!--                                <LogoutOutlined/>-->
<!--                                退出控制台-->
<!--              </router-link>-->
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
    </li>
  </ul>

  <a-drawer v-model:visible="msgDrawerData.visible" :bodyStyle="{padding:0}" :closable="false" :destroyOnClose="true"
            :keyboard="false" :maskClosable="false" :width="msgDrawerData.width" placement="right">
    <div class="layer-panel">
      <div class="layer-title">
        <div class="title">{{ msgDrawerData.title }}</div>
        <div class="icon" @click="msgDrawerData.visible=!msgDrawerData.visible">
          <close-outlined/>
        </div>
      </div>
      <div class="layer-body bg-white">
        <div v-if="listMsg && listMsg.length>0" class="msg-list-box">
          <div v-for="(item,index) in listMsg" :key="index" class="list-item" @click="showMsg(index)">
            <div class="item-head">
              <span>通知消息</span>
              <span>{{ item.effect_time }}</span>
            </div>
            <div class="item-content">{{ item.title }}</div>
          </div>
        </div>
        <div v-else class="padding">
          <div style="padding-top: 30vh">
            <a-empty :description="false"/>
          </div>
        </div>
      </div>
    </div>
  </a-drawer>
  <a-drawer v-model:visible="drawerData.visible" :bodyStyle="{padding:0}" :closable="false" :destroyOnClose="true"
            :keyboard="false" :maskClosable="false" :width="drawerData.width" placement="right">
    <div class="layer-panel">
      <div class="layer-title">
        <div class="title">{{ drawerData.title }}</div>
        <div class="icon" @click="drawerClose">
          <close-outlined/>
        </div>
      </div>
      <div class="layer-body bg-white">
        <component :is="drawerData.component" v-if="drawerData.component" :drawerData="drawerData.data"
                   @drawerClose="drawerClose"></component>
      </div>
    </div>
  </a-drawer>
</template>

<script>
import {computed, defineComponent, getCurrentInstance, reactive, ref, watch} from "vue"
import NoticeDetail from "@/components/tpl/NoticeDetail"

export default defineComponent({
  components: {NoticeDetail},
  setup() {
    const {proxy} = getCurrentInstance()
    const website = computed(() => proxy.$store.getters["system/getWebsiteConfig"])
    const userinfo = computed(() => proxy.$store.state.token.userinfo)
    const initMsgDrawer = () => ({visible: false, title: '站内信', width: 360, data: '',})
    const msgDrawerData = reactive(initMsgDrawer())
    const initDrawer = () => ({visible: false, title: '站内信', width: 600, component: 'NoticeDetail', data: '',})
    const drawerData = reactive(initDrawer())
    const drawerClose = () => {
      Object.assign(drawerData, initDrawer())
    }
    watch(proxy.$router.currentRoute, () => {
      Object.assign(drawerData, initDrawer())
      Object.assign(msgDrawerData, initMsgDrawer())
    })

    const listMsg = ref([])
    const requestList = () => {
      proxy.$requests.unreadMsgList().then(r => {
        if (r.code === 0) {
          listMsg.value = r.data
        }
      })
    }
    const showMsg = index => {
      drawerData.data = listMsg.value[index]
      drawerData.visible = true
      let arr = []
      arr.push(listMsg.value[index].id)
      proxy.$requests.msgAction({type: 1, arr_id: arr})
      listMsg.value.splice(index, 1)
      if (listMsg.value.length === 0) {
        requestList()
      }
    }
    const logout=()=>{
      const config = computed(() => proxy.$store.getters["system/getCookieConfig"])
      proxy.$cookies.removeTokenCookie(config.value)
      proxy.$tools.logoutClear()
      proxy.$router.push('/login')
    }
    requestList()
    return {
      website,
      userinfo,
      userNav: proxy.$inits.userNav,
      listMsg,
      showMsg,
      drawerClose,
      drawerData,
      msgDrawerData,
      logout,
    }
  },
})
</script>

<style scoped>
.user-head-wrap {
  height: var(--app-header-height);
  line-height: var(--app-header-height);
}

.user-head-wrap .menu-wrap li {
  float: left;
  padding: 0 15px;
  line-height: inherit;
}

.menu-wrap-dropdown {
  height: var(--app-header-height);
  display: flex;
  align-items: center;
}


.user-drop-wrap {
  width: 180px;
}

.user-drop-wrap li span {
  display: inline-block;
}

.user-drop-wrap li a {
  display: inline-block;
  padding: 5px 20px;
}

.user-drop-wrap li a span {
  margin-right: 10px;
}

.user-drop-info {
  padding: 10px 20px;
  display: flex;
  align-items: center;
}

.user-avatar {
  background-size: cover;
  height: 30px;
  width: 30px;
  border-radius: 30px;
  line-height: 30px;
  text-align: center;
  color: #ffffff;
  overflow: hidden;
}

.msg-list-box {
  font-size: 14px;
}

.msg-list-box .list-item {
  padding: 16px;
  border-bottom: 1px solid var(--app-color-gray);
  cursor: pointer;
}

.msg-list-box .list-item:hover {
  background-color: var(--app-color-gray);
}

.msg-list-box .item-head {
  display: flex;
  justify-content: space-between;
  color: var(--app-color-grey);
}

.msg-list-box .item-content {
  padding: 10px 0;
}
</style>
