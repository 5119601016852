import {getCookie, isCookieEnabled, setCookie} from 'tiny-cookie'

Number.isInteger = Number.isInteger || function (value) {
    return typeof value === 'number' && isFinite(value) && Math.floor(value) === value
}
export default {
    //检查浏览器是否支持cookie
    isEnabled: function () {
        return isCookieEnabled()
    },

    //设置cookie
    set: function (key, value, daysOrOptions) {
        var opts = daysOrOptions
        if (Number.isInteger(daysOrOptions)) {
            opts = {expires: daysOrOptions}
        }
        return setCookie(key, value, opts)
    },

    //获取cookie
    get: function (key) {
        return getCookie(key)
    },

    //删除cookie
    remove: function (key, options) {
        var opts = {expires: -1}
        if (options !== undefined) {
            opts = Object.assign(options, opts)
        }
        setCookie(key, '', opts)
    },

    //设置TokenCookie
    setTokenCookie: function (e) {
        if (!e.value) {
            throw new Error("参数['value']丢失！")
        }
        if (!e.token_name) {
            throw new Error("参数['token_name']丢失！")
        }
        if (!e.exp_name) {
            throw new Error("参数['exp_name']丢失！")
        }
        if (!e.expire) {
            throw new Error("参数['expire']丢失！")
        }
        if (!e.domain) {
            throw new Error("参数['domain']丢失！")
        }
        if (document.domain === 'localhost') {
            e.domain = 'localhost'
        }
        this.set(e.token_name, e.value, {expires: e.expire + 's', domain: e.domain})
        this.set(e.exp_name, Date.parse(new Date()) / 1000 + 3660, {expires: '3600s', domain: e.domain})
    },

    //删除TokenCookie
    removeTokenCookie: function (e) {
        if (!e.token_name) {
            throw new Error("参数['token_name']丢失！")
        }
        if (!e.exp_name) {
            throw new Error("参数['exp_name']丢失！")
        }
        if (document.domain === 'localhost') {
            e.domain = 'localhost'
        }
        this.remove(e.token_name, {domain: e.domain})
        this.remove(e.exp_name, {domain: e.domain})
    },

    //判断TokenCookie是否有效。有效刷新TokenCookie并返回true，无效返回false
    hasTokenCookie: function (e) {
        if (!e.token_name) {
            throw new Error("参数['token_name']丢失！")
        }
        if (!e.exp_name) {
            throw new Error("参数['exp_name']丢失！")
        }
        if (!e.domain) {
            throw new Error("参数['domain']丢失！")
        }
        if (!this.get(e.token_name)) return false
        if (!this.get(e.exp_name)) return false

        this.set(e.exp_name, Date.parse(new Date()) / 1000 + 3660, {expires: '3600s', domain: e.domain})
        return true
    }
}
