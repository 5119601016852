//个人设置目录
const userNav = {
    label: '个人中心',
    data: [
        {name: '账号设置', link: '/profile', icon: 'UserOutlined'},
        {name: '我的订单', link: '/order', icon: 'ScheduleOutlined'},
        {name: '安全设置', link: '/safe', icon: 'LockOutlined'},
        {name: '站内信', link: '/notice', icon: 'BellOutlined'},
        {name: '操作日志', link: '/log', icon: 'DatabaseOutlined'},
    ]
}
//业务管理目录
const businessNav = {
    label: '业务管理',
    data: [
        {name: '投资咨询', link: '/invest', icon: 'Invest', auth: ['isCertify']},
        {name: '房产中心', link: '/house', icon: 'House', auth: ['isCertify']},
        // {name: '需求管理', link: '/demand', icon: 'Demand', auth: ['isCertify']},
        // {name: '供应管理', link: '/supply', icon: 'Supply', auth: ['isCertify']},
    ]
}
//企业管理
const companyNav = {
    label: '企业管理',
    data: [
        {name: '企业信息', link: '/company', icon: 'Building', auth: ['isCertify', 'isCompany']},
        {name: '新闻列表', link: '/news', icon: 'News', auth: ['isCertify', 'isCompany', 'allCompany']},
        {name: '相册中心', link: '/album', icon: 'PictureOutlined', auth: ['isCertify', 'isCompany', 'allCompany']},
        {name: '视频中心', link: '/video', icon: 'VideoCameraOutlined', auth: ['isCertify', 'isCompany', 'allCompany']},
        {name: '招聘管理', link: '/job', icon: 'Job', auth: ['isCertify', 'isCompany', 'allCompany']},
    ]
}
//阿里云OSS配置
const ossConfig = {
    bucket: "jianjiangwang",
    region: "oss-cn-shanghai",
    host_url: 'https://oss.jianjiangwang.com',
    oss_url: 'http://jianjiangwang.oss-cn-shanghai.aliyuncs.com',
    endpoint: 'http://oss-cn-shanghai.aliyuncs.com',
}
//自定义正则规则
const regex = {
    phone: '^1[3-9][0-9]{9}$',
    number: '^\\d+$',
    chs: '[\u4e00-\u9fa5]',
    password: '^(?![0-9]+$)(?![a-zA-Z]+$)(?![A-Za-z]+$)(?![_]+$)[0-9_A-Za-z]{5,16}$', // 至少2种
    mail: '\\w+([-+.]\\w+)*@\\w+([-.]\\w+)*\\.\\w+([-.]\\w+)*',
}
//企业规模(人数)
const companyScale = ['少于15人', '15-50人', '50-150人', '150-500人', '500-2000人', '2000人以上']
//代码提示
const codeData = {
    20000: {status: 'error', title: '未知错误', sub_title: '未匹配到对应的code，请确认信息后，再重试。'},
    20041: {status: 'warning', title: '实名认证未完成', sub_title: '您的账号尚未进行实名认证，请核对并修改以下信息后，再重试。'},
    20042: {status: 'warning', title: '实名认证未完成', sub_title: '您的实名认证信息在审核中或审核未通过，请核对并修改以下信息后，再重试。'},
    20043: {status: 'warning', title: '权限提醒', sub_title: '您的账号权限不足，请核对并修改以下信息后，再重试。'},
    20044: {status: 'warning', title: '完善提醒', sub_title: '您的企业信息待更新，请核对并修改以下信息后，再重试。'},
    20051: {status: 'error', title: '错误提示', sub_title: '未知路由访问，请确认链接地址是否正确。'},
}
export default {userNav, businessNav, companyNav, ossConfig, regex, codeData, companyScale}
