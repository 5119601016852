import {
    Alert,
    Avatar,
    Badge,
    Button,
    Card,
    Carousel,
    Cascader,
    Checkbox,
    Col,
    ConfigProvider,
    Descriptions,
    DatePicker,
    Divider,
    Drawer,
    Dropdown,
    Empty,
    Form,
    Image,
    Input,
    InputNumber,
    Layout,
    List,
    Menu,
    message,
    Modal,
    PageHeader,
    Popconfirm,
    Radio,
    Rate,
    Result,
    Row,
    Select,
    Skeleton,
    Space,
    Spin,
    Statistic,
    Steps,
    Switch,
    Table,
    Tabs,
    Typography,
    Upload,
} from 'ant-design-vue'

export default {
    install: (app) => {
        // 设置为 false 以阻止 vue 在启动时生成生产提示
        app.config.productionTip = false
        app.config.globalProperties.$message = message
        //
        app.use(Rate)
        app.use(InputNumber)
        app.use(Typography)
        app.use(Divider)
        app.use(List)
        app.use(Tabs)
        app.use(Statistic)
        app.use(PageHeader)
        app.use(Descriptions)
        app.use(DatePicker)
        app.use(Switch)
        app.use(Result)
        app.use(Steps)
        app.use(Radio)
        app.use(Upload)
        app.use(Cascader)
        app.use(Carousel)
        app.use(Modal)
        app.use(Popconfirm)
        app.use(Table)
        app.use(Select)
        app.use(Space)
        app.use(Card)
        app.use(Empty)
        app.use(Skeleton)
        app.use(Avatar)
        app.use(Menu)
        app.use(Dropdown)
        app.use(Badge)
        app.use(Drawer)
        app.use(Row)
        app.use(Col)
        app.use(Alert)
        app.use(Layout)
        app.use(Spin)
        app.use(Checkbox)
        app.use(Image)
        app.use(ConfigProvider)
        app.use(Button)
        app.use(Form)
        app.use(Input)
    }
}
