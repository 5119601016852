<template>
  <login-head logo-tips="找回密码" />
  <div class="section-wrap login-content-panel">
    <forget-form />
  </div>
  <page-footer/>
</template>

<script>
import LoginHead from "@/components/tpl/LoginHead"
import PageFooter from "@/components/tpl/PageFooter"
import ForgetForm from "@/components/tpl/ForgetForm"

export default {
  name: "Index",
  components: {
    LoginHead,
    PageFooter,
    ForgetForm,
  }
}
</script>

<style scoped>
</style>
