<template>
  <a-spin :spinning="loading" tip="请稍等...">
    <a-card :bordered="false">
      <a-form ref="formRef" :label-col="{style:{width:'100px'}}" :model="formData" :rules="formRules">
        <a-row :gutter="20">
          <a-col :span="24">
            <a-form-item label="项目名称" name="project_name">
              <a-input v-model:value="formData.project_name" placeholder="请输入项目名称" type="text"/>
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="项目描述" name="desc">
              <a-textarea v-model:value="formData.desc" :auto-size="{ minRows: 3, maxRows: 5 }" placeholder="请输入描述"/>
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="选择省市" name="area">
              <a-cascader v-model:value="formData.area" :field-names="{ label: 'name', value: 'name' }"
                          :options="areaData" placeholder="请选择"/>
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="详细地址" name="address">
              <a-input v-model:value="formData.address" placeholder="请输入详细地址" type="text"/>
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <div class="text-center">
              <a-button :loading="loading" type="primary" @click="submitForm">提交</a-button>
            </div>
          </a-col>
        </a-row>
      </a-form>
    </a-card>
  </a-spin>
</template>

<script>
import {defineComponent, getCurrentInstance, onMounted, reactive, ref, toRaw} from "vue"

export default defineComponent({
  emits: ['drawerClose'],
  props: ['drawerData'],
  setup() {
    const loading = ref(false)
    const {proxy} = getCurrentInstance()

    const areaData = proxy.$inits.area

    /*----表单---*/
    const formRef = ref()
    const formData = reactive({
      id: '',
      project_name: '',
      area: [],
      address: '',
      desc:'',
    })
    const formRules = reactive({
      project_name: [
        {required: true, message: '项目名称必须填写！', trigger: 'change',},
        {max: 64, message: '项目名称最多64个字符！', trigger: 'blur',},
      ],
      area: [
        {required: true, message: '省市区必须选择！', trigger: 'change',},
      ],
      address: [
        {required: true, message: '详细地址必须填写！', trigger: 'change',},
        {max: 64, message: '详细地址最多64个字符！', trigger: 'blur',},
      ],
      desc: [
        {required: true, message: '项目描述必须填写！', trigger: 'change',},
        {max: 200, message: '项目描述最多200个字符！', trigger: 'blur',},
      ],
    })

    const onInputSearch = (searchValue) => {
      formData[searchValue] = 10
      console.log('use value', searchValue);
    };

    /*---提交按钮---*/
    const submitForm = () => {
      loading.value = true
      formRef.value.validate().then(async () => {
        //提交方法
        requestSaveData(toRaw(formData))
        //提交方法
      }).catch(() => {
        loading.value = false
      })
    }
    //发送请求
    const requestSaveData = obj => {
      proxy.$requests.saveHouseProject(obj).then(r => {
        if (r.code === 0) {
          proxy.$message.success(r.msg, 1.2).then(
              () => {
                loading.value = false
                proxy.$emit('drawerClose')
              },
              () => {
              }
          )

        } else {
          loading.value = false
          proxy.$message.error(r.msg)
        }
      })
    }
    onMounted(async () => {
      /*----初始化数据----*/
      try {
        loading.value = true
        const objData = proxy.$props.drawerData
        if (objData && Object.keys(objData).length !== 0) {
          formData.id = objData.id
          formData.project_name = objData.project_name
          formData.area = [objData.prov, objData.city, objData.district]
          formData.address = objData.address
          formData.desc = objData.desc
        }

      } catch (e) {
        proxy.$message.error('未知错误  ' + e, 2.5).then(() => {
          proxy.$emit('drawerClose')
        }, () => {
        })
      } finally {
        loading.value = false
      }
    });
    return {
      loading,
      areaData,
      formRef,
      formData,
      formRules,
      submitForm,
      onInputSearch,
    }
  }
})
</script>
