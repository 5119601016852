<template>
  <a-spin :spinning="loading" tip="请稍等...">
    <a-card :bordered="false">
      <a-form ref="formRef" :model="formData" :rules="formRules" layout="vertical">
        <a-form-item label="类型" name="category">
          <a-radio-group v-model:value="formData.category">
            <a-radio value="1">企业新闻</a-radio>
            <a-radio value="2">行业新闻</a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="标题" name="title">
          <a-input v-model:value="formData.title" placeholder="请输入标题" type="text"/>
        </a-form-item>
        <a-form-item label="描述" name="desc">
          <a-textarea v-model:value="formData.desc" :auto-size="{ minRows: 3, maxRows: 5 }" placeholder="请输入描述"/>
        </a-form-item>

        <a-form-item label="缩略图" name="icon" style="min-height: 120px">
          <a-upload v-model:file-list="fileList" :before-upload="beforeUpload"
                    list-type="picture-card" @change="uploadChange" @preview="handlePreview">
            <div v-if="fileList.length < 1">
              <plus-outlined/>
              <div class="ant-upload-text">添加</div>
            </div>
          </a-upload>
          <a-modal :footer="null" :visible="previewVisible" @cancel="cancelPreview">
            <img :src="previewImage" alt="example" style="width: 100%"/>
          </a-modal>
        </a-form-item>
        <a-form-item label="内容" name="content">
          <div ref="editor" class="editor-wrap"></div>
        </a-form-item>
        <br>
        <div class="text-center">
          <a-button :loading="loading" type="primary" @click="submitForm">提交</a-button>
        </div>
      </a-form>
    </a-card>
  </a-spin>
</template>

<script>
import {defineComponent, getCurrentInstance, onBeforeUnmount, onMounted, reactive, ref, toRaw} from "vue"
import WangEditor from "wangeditor"

export default defineComponent({
  emits: ['drawerClose'],
  props: ['drawerData'],
  setup() {
    const loading = ref(false)
    const {proxy} = getCurrentInstance()
    proxy.$store.dispatch('aliyun/setStsConfig')//上传操作前，检测STS
    /*----编辑器----*/
    const editor = ref()
    let instance
    onMounted(() => {
      instance = new WangEditor(editor.value)
      instance.config.height = 500
      instance.config.menus = [
        'bold',
        'fontSize',
        'italic',
        'underline',
        'strikeThrough',
        'foreColor',
        'backColor',
        'link',
        'list',
        'justify',
        'quote',
        'image',
        'video',
        'table',
      ]
      //上传视频
      instance.config.uploadVideoMaxSize = 204800
      instance.config.uploadVideoAccept = ['mp4']
      instance.config.showLinkVideo = false
      instance.config.customUploadVideo = function (files, insertVideoFn) {
        loading.value = true
        let d = files[0]
        let ext = proxy.$tools.getFileExt(d.name)
        let fileName = proxy.$tools.makeFileName()
        proxy.$oss.upload('editor', fileName + ext, d).then(r => {
          loading.value = false
          if (r && r.res.statusCode === 200) {
            insertVideoFn(proxy.$oss.sign(r.name))
          } else {
            proxy.$message.error('上传中断，请刷新页面后重试！')
            return false
          }
        })
      }
      //上传图片
      instance.config.uploadImgMaxLength = 1
      instance.config.uploadImgMaxSize = 10 * 1024 * 1024
      instance.config.showLinkImg = false
      instance.config.customUploadImg = async function (files, insertImgFn) {
        loading.value = true
        let d = files[0]
        let isSize = d.size / 1024 / 1024 < 1, baseFile = await proxy.$tools.getBase64(d)
        if (!isSize) {
          let obj = await proxy.$img.imgCompress(baseFile, {width: 1000, quality: 0.75})
          baseFile = obj.img
          d = proxy.$img.dataURLtoFile(baseFile, 'icon.jpg')
        }
        let ext = proxy.$tools.getFileExt(d.name)
        let fileName = proxy.$tools.makeFileName()
        proxy.$oss.upload('editor', fileName + ext, d).then(r => {
          loading.value = false
          if (r && r.res.statusCode === 200) {
            insertImgFn(proxy.$oss.sign(r.name))
          } else {
            proxy.$message.error('上传中断，请刷新页面后重试！')
            return false
          }
        })
      }
      instance.create()
      instance.txt.html(formData.content)
    })

    onBeforeUnmount(() => {
      instance.destroy()
      instance = null
    })

    const formRef = ref()
    const formData = reactive({id: '', title: '', desc: '', icon: '', content: '', category: '1'})
    let validUpload = (_rule, value) => {
      if (value === '' || !value) {
        return Promise.reject('请选择上传图片！')
      }
      if (value instanceof File) {
        if (value.type !== 'image/jpeg' && value.type !== 'image/png') {
          return Promise.reject('上传图片格式(jpg/jpeg/png)')
        }
      }
      return Promise.resolve()
    }
    const formRules = reactive({
      category: [
        {required: true, message: '类型必须选择！', trigger: 'change',},
      ],
      title: [
        {required: true, message: '名称必须填写！', trigger: 'change',},
        {max: 64, message: '名称最多64个字符！', trigger: 'blur',},
      ],
      desc: [
        {required: true, message: '描述必须填写！', trigger: 'change',},
        {max: 200, message: '描述最多200字符！', trigger: 'blur',},
      ],
      icon: [
        {required: true, validator: validUpload, trigger: 'change',},
      ],
      content: [
        {required: true, message: '内容必须填写！', trigger: 'change',},
      ],
    })
    const submitForm = () => {
      loading.value = true
      formData.content = proxy.$tools.replaceSrc(instance.txt.html())
      formRef.value.validate().then(async () => {
        if (formData.icon instanceof File) {
          let poster_file = formData.icon
          await proxy.$oss.upload(
              'news',
              proxy.$tools.makeFileName() + proxy.$tools.getFileExt(poster_file.name),
              poster_file
          ).then(r => {
            if (r && r.res.statusCode === 200) {
              formData.icon = r.name
            } else {
              proxy.$message.error('上传中断，请刷新页面后重试！')
              loading.value = false
              return false
            }
          })
        }
        if (!formData.icon) {
          proxy.$message.error('缩略图数据丢失！')
          loading.value = false
          return false
        }
        //提交方法
        requestSaveData(toRaw(formData))
        //提交方法
      }).catch(() => {
        loading.value = false
      })
    }
    //发送请求
    const requestSaveData = obj => {
      proxy.$requests.newsSave(obj).then(r => {
        if (r.code === 0) {
          proxy.$message.success(r.msg, 1.2).then(
              () => {
                loading.value = false
                proxy.$emit('drawerClose')
              },
              () => {
              }
          )

        } else {
          loading.value = false
          proxy.$message.error(r.msg)
        }
      })
    }
    /*-----上传----*/
    const previewVisible = ref(false)
    const previewImage = ref('')
    const fileList = ref([])
    //取消预览
    const cancelPreview = () => {
      previewVisible.value = false
    }
    //预览图片
    const handlePreview = async file => {
      previewImage.value = file.thumbUrl
      previewVisible.value = true
    }
    const beforeUpload = () => {
      return false
    }
    const handleChange = ({fileList: newFileList}) => {
      fileList.value = newFileList
    }
    const uploadChange = async (r) => {
      if (r.fileList.length > 0) {
        let fileData = r.file
        if (fileData.type === 'image/jpeg' || fileData.type === 'image/png') {
          let isSize = fileData.size / 1024 / 1024 < 1, baseFile = await proxy.$tools.getBase64(fileData)
          if (!isSize) {
            let obj = await proxy.$img.imgCompress(baseFile, {width: 1000, quality: 0.75})
            baseFile = obj.img
            let d = proxy.$img.dataURLtoFile(baseFile, 'icon.jpg')
            fileList.value = [{
              lastModified: d.lastModified,
              lastModifiedDate: d.lastModifiedDate,
              originFileObj: d,
              percent: 0,
              size: d.size,
              thumbUrl: baseFile,
              type: d.type,
              name: fileData.name,
              uid: fileData.uid,
            }]
            formData.icon = d
          }else {
            formData.icon = fileData
          }
        } else {
          fileList.value = []
        }
        formRef.value.validate('icon')
      } else {
        fileList.value = []
        formData.icon = ''
      }
    }
    /*----初始化数据----*/
    if (proxy.$props.drawerData && Object.keys(proxy.$props.drawerData).length !== 0) {
      try {
        formData.id = proxy.$props.drawerData.id
        formData.title = proxy.$props.drawerData.title
        formData.desc = proxy.$props.drawerData.desc
        formData.icon = proxy.$props.drawerData.icon
        formData.category = String(proxy.$props.drawerData.category)
        formData.content = proxy.$tools.replaceSrc(proxy.$props.drawerData.content, {
          image: {width: '30%'},
          video: {width: '640', height: '360'}
        })
        fileList.value.push({
          uid: 1,
          name: proxy.$props.drawerData.icon,
          status: 'done',
          thumbUrl: proxy.$oss.sign(proxy.$props.drawerData.icon),
        });
      } catch (e) {
        proxy.$message.error('未知错误', 1.2).then(() => {
          proxy.$emit('drawerClose')
        }, () => {
        })
      }
    }
    return {
      editor,
      loading,
      formRef,
      formData,
      formRules,
      fileList,
      submitForm,
      beforeUpload,
      handleChange,
      uploadChange,
      cancelPreview,
      handlePreview,
      previewVisible,
      previewImage,
    }
  }
})
</script>
