<template>
  <div class="notice-main">
    <div class="notice-title">{{ drawerData.title }}</div>
    <div class="notice-tips">{{ drawerData.effect_time }}</div>
    <div class="notice-content">{{ drawerData.content }}</div>
  </div>
</template>

<script>
export default {
  props: ['drawerData'],
  name: "NoticeDetail",
}
</script>
<style scoped>
.notice-main{
  padding: 20px;
}
.notice-title{
  text-align: center;
  font-size: 16px;
}
.notice-tips{
  text-align: right;
  color: #999999;
  font-size: 12px;
  padding: 10px 0;
}
.notice-content{
  padding: 10px 0;
}
</style>
