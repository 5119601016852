<template>
  <login-head logo-tips="用户登录" />
  <div class="section-wrap login-content-panel">
    <login-form />
  </div>
  <page-footer/>
</template>

<script>
import LoginHead from "@/components/tpl/LoginHead"
import PageFooter from "@/components/tpl/PageFooter"
import LoginForm from "@/components/tpl/LoginForm"

export default {
  name: "Index",
  components: {
    LoginHead,
    PageFooter,
    LoginForm,
  }
}
</script>

<style scoped>
</style>
