<template>
  <div v-html="tpl"></div>
</template>

<script>
import {defineComponent, getCurrentInstance, ref} from "vue"

export default defineComponent({
  setup() {
    const tpl = ref('')
    const {proxy} = getCurrentInstance()
    tpl.value = proxy.$route.query.htmlData ? proxy.$route.query.htmlData : '支付调起失败'
    proxy.$nextTick(() => {
      document.forms[0].submit()
    })
    return {
      tpl,
    }
  },
})
</script>

