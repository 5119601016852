<template>
  <div class="side-panel scrollbar">
    <div class="nav-label">{{ navData.label }}</div>
    <ul class="side-nav">
      <li v-for="(item ,index) in navData.data" :key="index">
        <router-link :to="item.link">{{ item.name }}</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import {getCurrentInstance} from "vue"

export default {
  props: ['navData'],
  name: "SideNav",
  setup() {
    const {proxy} = getCurrentInstance()
    return {
      path: proxy.$route.path
    }
  }
}
</script>

<style scoped>
</style>
