<template>
  <SideNav :nav-data="navData"/>
  <div class="main-panel scrollbar">
    <a-row :gutter="[20,20]">
      <a-col :span="24">
        <a-card class="card-panel">
          <div class="title-panel">
            <div class="title">{{ columnTitle }}</div>
          </div>
          <a-form ref="formRef" :label-col="labelCol" :model="formData">
            <a-form-item label="订单状态" name="searchStatus">
              <a-radio-group v-model:value="formData.searchStatus">
                <a-radio-button v-for="(item,index) in orderState" :key="index" :value="item.value">
                  {{ item.label }}
                </a-radio-button>
              </a-radio-group>
            </a-form-item>
            <a-form-item label="支付方式" name="payType">
              <a-radio-group v-model:value="formData.payType">
                <a-radio-button v-for="(item,index) in payState" :key="index" :value="item.value">
                  {{ item.label }}
                </a-radio-button>
              </a-radio-group>
            </a-form-item>
            <a-form-item :wrapperCol="{span:4}" label="搜索" name="searchText">
              <a-input v-model:value="formData.searchText" placeholder="请输入订单号" type="text"/>
            </a-form-item>
            <a-form-item style="text-align: center">
              <a-space :size="100">
                <a-button v-preventReClick type="primary" @click="submitForm">搜索</a-button>
                <a-button v-preventReClick @click="resetForm">重置</a-button>
              </a-space>
            </a-form-item>
          </a-form>
        </a-card>
      </a-col>
      <a-col :span="24">
        <a-card class="card-panel">
          <a-table :columns="tableCol" :data-source="tableData" :loading="loading" :pagination="paginate"
                   rowKey="id" style="font-size: 13px" @change="onPaginateChange">
            <template #bodyCell="{ column,record,index }">
              <template v-if="column.dataIndex === 'title'">
                <span>{{ record.title }}</span>
                <span style="padding-left: 10px;">×{{ record.rows }}</span>
              </template>
              <template v-if="column.dataIndex === 'price'">
                <span>{{ record.price > 0 ? record.price / 100 * record.rows : 0 }}</span>
              </template>
              <template v-if="column.dataIndex === 'pay_time'">
                <span v-if="record.status === 3" class="text-error">
                  支付截至时间 <br>
                  {{ record.pay_limit }}
                </span>
                <span v-else>{{ record.pay_time }}</span>
              </template>
              <template v-if="column.dataIndex === 'pay_type'">
                <span v-if="record.pay_type === 1">微信</span>
                <span v-else-if="record.pay_type === 2">支付宝</span>
                <span v-else>其他</span>
              </template>
              <template v-if="column.dataIndex === 'status'">
                <span v-if="record.status === 1">已关闭</span>
                <span v-else-if="record.status === 2">售后中</span>
                <span v-else-if="record.status === 3">待付款</span>
                <span v-else-if="record.status === 4">待发货</span>
                <span v-else-if="record.status === 5">待收货</span>
                <span v-else-if="record.status === 6">待评价</span>
                <span v-else-if="record.status === 7">已完成</span>
                <span v-else>其他</span>
              </template>
              <template v-if="column.dataIndex === 'operation'">
                <div class="table-body-operation">
                  <a-button v-if="record.status === 3" type="link" @click="payOrder(record)">立即支付</a-button>
                  <a-button v-if="record.status === 5" type="link">确认收货</a-button>
                  <a-button v-if="record.status === 6" type="link" @click="evalBtn(record.id)">我要评价</a-button>
                  <a-dropdown>
                    <a class="text-primary" @click.prevent>
                      更多
                      <DownOutlined/>
                    </a>
                    <template #overlay>
                      <a-menu>
                        <a-menu-item v-if="record.status === 3">
                          <a-popconfirm cancel-text="取消" ok-text="确定" title="确定关闭该订单吗?"
                                        @confirm="closeOrder(record.pay_sn)">
                            <span style="padding: 0 20px;font-size: 10px">关闭订单</span>
                          </a-popconfirm>
                        </a-menu-item>
                        <a-menu-item>
                          <span style="padding: 0 20px;font-size: 10px" @click="orderDetail(record)">订单详情</span>
                        </a-menu-item>
                        <a-menu-item>
                          <span style="padding: 0 20px;font-size: 10px" @click="orderRecord(record)">订单记录</span>
                        </a-menu-item>
                        <a-menu-item v-if="record.status === 4">
                          <span style="padding: 0 20px;font-size: 10px" @click="orderNote(record)">订单备注</span>
                        </a-menu-item>
                        <a-menu-item v-if="record.status === 1||record.status === 7">
                          <span style="padding: 0 20px;font-size: 10px" @click="delOrder(index)">订单删除</span>
                        </a-menu-item>
                        <a-menu-item v-if="record.status === 5">
                          <span style="padding: 0 20px;font-size: 10px">发起售后</span>
                        </a-menu-item>
                      </a-menu>
                    </template>
                  </a-dropdown>
                </div>
              </template>
            </template>
          </a-table>
        </a-card>
      </a-col>

    </a-row>
  </div>

  <a-modal v-model:visible="evalData.visible" :footer="null" :keyboard="false" :maskClosable="false" :width="800"
           title="订单评价" @cancel="modalClose">
    <div class="eval-panel">
      <div class="list-item" style="align-items: flex-start;margin-bottom: 20px;">
        <div class="item-label">评价描述：</div>
        <div class="item-content">
          <a-textarea v-model:value="evalData.content" :maxlength="128" placeholder="符合评价规则，评价不超过128个字符"/>
        </div>
      </div>
      <div class="list-item" style="align-items: flex-start;">
        <div class="item-label">上传图片：</div>
        <div class="item-content">
          <a-upload
              v-model:file-list="fileEvalList"
              :before-upload="beforeUpload"
              accept=".jpg,.png,.jpeg"
              list-type="picture-card"
              @change="uploadChange"
              @preview="handlePreview"
          >
            <div v-if="fileEvalList.length < 6">
              <plus-outlined/>
            </div>
          </a-upload>
          <a-modal :footer="null" :visible="previewVisible" @cancel="handleCancel">
            <img :src="previewImage" alt="example" style="width: 100%"/>
          </a-modal>
        </div>
      </div>
      <div class="list-item">
        <div class="item-label">商品评价：</div>
        <div class="item-content">
          <a-rate v-model:value="evalData.goods_eval"/>
        </div>
      </div>
      <div class="list-item">
        <div class="item-label">商家服务：</div>
        <div class="item-content">
          <a-rate v-model:value="evalData.service_eval"/>
        </div>
      </div>
      <div class="list-item">
        <div class="item-label">物流服务：</div>
        <div class="item-content">
          <a-rate v-model:value="evalData.express_eval"/>
        </div>
      </div>
      <div class="list-item" style="justify-content: center;padding: 30px 0">
        <a-button v-preventReClick type="primary" @click="submitEval">提交</a-button>
      </div>
    </div>
  </a-modal>
  <a-modal v-model:visible="detailModal" :footer="null" :keyboard="false" :maskClosable="false" :width="800"
           title="订单详情" @cancel="modalClose">
    <a-descriptions v-if="rowsData.model !==1" :column="2" title="收货信息">
      <a-descriptions-item label="收件人">{{ rowsData.accept_name ?? '-' }}</a-descriptions-item>
      <a-descriptions-item label="联系电话">{{ rowsData.accept_phone ?? '-' }}</a-descriptions-item>
      <a-descriptions-item label="收件地址">
        {{ rowsData.accept_prov ?? '' }} {{ rowsData.accept_city ?? '' }} {{ rowsData.accept_addr ?? '-' }}
      </a-descriptions-item>
    </a-descriptions>
    <a-divider v-if="rowsData.model !==1"/>
    <a-descriptions :column="2" title="订单信息">
      <a-descriptions-item label="订单编号">{{ rowsData.sn ?? '-' }}</a-descriptions-item>
      <a-descriptions-item label="订单状态">
        <span v-if="rowsData.status === 1">已关闭</span>
        <span v-else-if="rowsData.status === 2">售后中</span>
        <span v-else-if="rowsData.status === 3">待付款</span>
        <span v-else-if="rowsData.status === 4">待发货</span>
        <span v-else-if="rowsData.status === 5">待收货</span>
        <span v-else-if="rowsData.status === 6">待评价</span>
        <span v-else-if="rowsData.status === 7">已完成</span>
        <span v-else>其他</span>
      </a-descriptions-item>
      <a-descriptions-item label="商品数量">×{{ rowsData.rows ?? '' }}</a-descriptions-item>
      <a-descriptions-item label="商品总价">
        {{ rowsData.price > 0 ? rowsData.price / 100 * rowsData.rows : 0 }}元
      </a-descriptions-item>
      <a-descriptions-item label="支付邮费">
        {{ rowsData.express_price > 0 ? rowsData.express_price / 100 : 0 }}元
      </a-descriptions-item>
      <a-descriptions-item label="优惠券金额">
        {{ rowsData.coupon_price > 0 ? rowsData.coupon_price / 100 : 0 }}元
      </a-descriptions-item>
      <a-descriptions-item label="积分抵扣">
        {{ rowsData.pay_point > 0 ? rowsData.pay_point / 100 : 0 }}元
      </a-descriptions-item>
      <a-descriptions-item label="实际支付">
        <span
            v-if="rowsData.price * rowsData.rows + rowsData.express_price + rowsData.coupon_price > rowsData.pay_point">
          {{
            (rowsData.price * rowsData.rows + rowsData.express_price + rowsData.coupon_price - rowsData.pay_point) / 100
          }}元
        </span>
        <span v-else>0元</span>
      </a-descriptions-item>
      <a-descriptions-item label="创建时间">{{ rowsData.create_time ?? '' }}</a-descriptions-item>
      <a-descriptions-item label="支付时间">{{ rowsData.pay_time ?? '' }}</a-descriptions-item>
      <a-descriptions-item label="支付方式">
        {{ rowsData.pay_type === 1 ? '微信' : '支付宝' }}
      </a-descriptions-item>
    </a-descriptions>
  </a-modal>
  <a-modal v-model:visible="recordModal" :footer="null" :keyboard="false" :maskClosable="false" :width="800"
           title="订单记录" @cancel="modalClose">
    <a-card :bordered="false">
      <a-descriptions :column="1" bordered>
        <a-descriptions-item label="订单生成">{{ rowsData.create_time ?? '-' }}</a-descriptions-item>
        <a-descriptions-item label="订单支付">{{ rowsData.pay_time ?? '-' }}</a-descriptions-item>
        <a-descriptions-item label="商家发货">{{ rowsData.send_time ?? '-' }}</a-descriptions-item>
        <a-descriptions-item label="收货时间">{{ rowsData.accept_time ?? '-' }}</a-descriptions-item>
        <a-descriptions-item label="评价时间">{{ rowsData.eval_time ?? '-' }}</a-descriptions-item>
      </a-descriptions>
    </a-card>
  </a-modal>
  <a-modal v-model:visible="noteData.visible" :footer="null" :keyboard="false" :maskClosable="false" :width="800"
           title="添加备注" @cancel="modalClose">
    <div class="eval-panel">
      <div class="list-item" style="align-items: flex-start;margin-bottom: 20px;">
        <div class="item-content">
          <a-textarea v-model:value="noteData.note" :auto-size="{ minRows: 4, maxRows: 6 }" :maxlength="128"
                      placeholder="备注不超过260个字符"/>
        </div>
      </div>
      <div class="list-item" style="justify-content: center;padding: 20px 0">
        <a-button v-preventReClick type="primary" @click="submitNote">提交</a-button>
      </div>
    </div>
  </a-modal>
</template>

<script>
import {defineComponent, getCurrentInstance, reactive, ref, toRaw} from "vue"
import SideNav from "@/components/nav/SideNav"

export default defineComponent({
  components: {SideNav},
  setup() {
    const loading = ref(false)
    const {proxy} = getCurrentInstance()
    const navData = proxy.$inits.userNav
    const columnTitle = ref('')
    navData.data.filter((item) => {
      if (item.link === proxy.$route.path) {
        return columnTitle.value = item.name
      }
    })
    const initNote = () => ({
      visible: false,
      order_id: '',
      note: '',
    })
    const noteData = reactive(initNote())
    const orderNote = item => {
      noteData.visible = true
      noteData.order_id = item.id
      noteData.note = item.note
    }
    const initEval = () => ({
      visible: false,
      order_id: '',
      content: '',
      poster: '',
      goods_eval: 3,
      service_eval: 3,
      express_eval: 3
    })
    const evalData = reactive(initEval())
    const evalBtn = r => {
      evalData.order_id = r
      evalData.visible = true
      proxy.$store.dispatch('aliyun/setStsConfig')//上传操作前，检测STS
    }
    const modalClose = () => {
      fileEvalList.value = []
      Object.assign(evalData, initEval())
      Object.assign(rowsData, initRows())
      Object.assign(noteData, initNote())
    }
    const tableData = ref([])
    const tableCol = [
      {title: '订单号', dataIndex: 'sn', width: 220,},
      {title: '商品信息', dataIndex: 'title', ellipsis: true,},
      {title: '金额', dataIndex: 'price', align: 'center', width: 120,},
      {title: '支付方式', dataIndex: 'pay_type', align: 'center', width: 120,},
      {title: '支付时间', dataIndex: 'pay_time', align: 'center', width: 180,},
      {title: '订单状态', dataIndex: 'status', align: 'center', width: 120,},
      {title: '操作', dataIndex: 'operation', align: 'center', width: 220,}
    ]
    const initPaginate = () => ({
      total: 0,
      current: 1,
      pageSize: 8,
      page: 1,
      limit: 80,
      count: 0,
      showSizeChanger: false
    })
    const paginate = reactive(initPaginate())
    const onPaginateChange = pageNumber => {
      paginate.current = pageNumber.current
      if (pageNumber.current === Math.ceil(pageNumber.total / pageNumber.pageSize)) {
        if (pageNumber.count === pageNumber.total) {
          proxy.$message.info('没有数据了')
          return false
        }
        ++paginate.page
        requestList()
      }
    }
    let formId = proxy.$tools.randomStr()//表单ID
    const formRef = ref()

    const initFormData = () => ({searchStatus: '', payType: '', searchText: ''})
    const formData = reactive(initFormData())
    //提交表单
    const submitForm = () => {
      Object.assign(paginate, initPaginate())
      tableData.value = []
      requestList()
    }
    //重置表单
    const resetForm = () => {
      formRef.value.resetFields()
      Object.assign(paginate, initPaginate())
      Object.assign(formData, initFormData())
      tableData.value = []
      requestList()
    }

    const requestList = async () => {
      loading.value = !loading.value
      let d = toRaw(formData)
      await proxy.$requests.orderList({
        page: paginate.page,
        limit: paginate.limit,
        searchText: d.searchText,
        searchStatus: d.searchStatus,
        payType: d.payType,
      }).then(r => {
        loading.value = !loading.value
        if (r.code === 0) {
          tableData.value = tableData.value.concat(r.data)
          paginate.total = tableData.value.length
          paginate.count = r.count
        } else {
          proxy.$message.error(r.msg)
        }
      })
    }
    requestList()
    /*-------------------上传-----------*/
    const fileEvalList = ref([])
    const previewVisible = ref(false)
    const previewImage = ref('')
    const handleCancel = () => {
      previewVisible.value = false;
    }

    const handlePreview = async file => {
      if (!file.url && !file.preview) {
        file.preview = await proxy.$tools.getBase64(file.originFileObj)
      }

      previewImage.value = file.url || file.preview
      previewVisible.value = true
    }
    const beforeUpload = () => {
      return false
    }
    const uploadChange = async info => {
      let resFileList = info.fileList.slice(0, 6)
      if (info.file.type !== 'image/jpeg' && info.file.type !== 'image/png') {
        proxy.$message.error('请选择图片上传')
        resFileList = resFileList.filter(item => {
          return item.uid !== info.file.uid
        })
      } else {
        let infoFile = info.file, isSize = info.file.size / 1024 / 1024 < 1
        if (!isSize) {
          let sn = proxy.$tools.randomStr(10)
          let baseFile = await proxy.$tools.getBase64(infoFile)
          let obj = await proxy.$img.imgCompress(baseFile, {width: 1000, quality: 0.75})
          baseFile = obj.img
          let fileData = proxy.$img.dataURLtoFile(baseFile, sn + '.jpg')
          fileData.uid = infoFile.uid

          resFileList = resFileList.map(item => {
            if (item.uid === fileData.uid) {
              item.lastModified = fileData.lastModified
              item.lastModifiedDate = fileData.lastModifiedDate
              item.name = fileData.name
              item.originFileObj = fileData
              item.percent = 0
              item.size = fileData.size
              item.thumbUrl = baseFile
              item.type = fileData.type
            }
            return item
          })
        }
      }

      fileEvalList.value = resFileList
    }
    const submitEval = async () => {
      loading.value = true
      if (!evalData.order_id) {
        proxy.$message.error('获取订单信息失败')
        loading.value = false
        return false
      }
      let poster = []
      if (fileEvalList.value.length > 0) {
        for (let i = 0; i < fileEvalList.value.length; i++) {
          if (fileEvalList.value[i].originFileObj && fileEvalList.value[i].originFileObj instanceof File) {
            let ext = proxy.$tools.getFileExt(fileEvalList.value[i].name)
            let fileName = proxy.$tools.makeFileName();
            await proxy.$oss.upload('eval', fileName + ext, fileEvalList.value[i].originFileObj).then(r => {
              if (r && r.res.statusCode === 200) {
                fileEvalList.value[i].originFileObj = r.name
              } else {
                proxy.$message.error('上传中断，请刷新页面后重试！')
                loading.value = false
                return false;
              }
            })
          }
          poster.push(fileEvalList.value[i].originFileObj)
        }
      }

      evalData.poster = poster.join(',')
      proxy.$requests.orderEval(toRaw(evalData)).then(r => {
        loading.value = false
        if (r.code === 0) {
          proxy.$message.success(r.msg)
          tableData.value = tableData.value.map(item => {
            if (item.id === evalData.order_id) {
              item.status = 7
            }
            return item
          })
          modalClose()
        } else {
          proxy.$message.error(r.msg)
        }
      })
    }
    //关闭订单
    const closeOrder = paySn => {
      loading.value = true
      proxy.$requests.orderClose({pay_sn: paySn}).then(r => {
        loading.value = false
        if (r.code === 0) {
          proxy.$message.success(r.msg)
          tableData.value = tableData.value.map(item => {
            if (item.pay_sn === paySn) {
              item.status = 1
            }
            return item
          })
        } else {
          proxy.$message.error(r.msg)
        }
      })
    }
    //删除订单
    const delOrder = index => {
      loading.value = true
      let tData = tableData.value
      proxy.$requests.orderDel({order_id: tData[index].id}).then(r => {
        loading.value = false
        if (r.code === 0) {
          proxy.$message.success(r.msg)
          let key = (paginate.current - 1) * paginate.pageSize + index
          tData.splice(key, 1)
          paginate.total = tData.length
          let ceil = Math.ceil(key / paginate.pageSize)
          if (key % paginate.pageSize === 0 && key !== paginate.total) { //整除且key不等于总数
            paginate.current = ceil + 1
          } else {
            paginate.current = ceil > 0 ? ceil : 1
          }
          if (tData.length === 0) {
            requestList()
          }
        } else {
          proxy.$message.error(r.msg)
        }
      })
    }
    //立即支付
    const payOrder = item => {
      let now = proxy.$tools.formatDate() //当前时间
      if (item.pay_limit > now) {
        loading.value = true
        proxy.$requests.orderPayLimit({order_id: item.id}).then(async r => {
          loading.value = false
          if (r.code === 0) {
            await proxy.$store.commit('pay/setOrder', r.data)
            await proxy.$router.replace('/pay/order')
          } else {
            proxy.$message.error(r.msg)
          }
        })
      } else {
        proxy.$message.error('该订单支付超时')
      }
    }
    //添加备注
    const submitNote = () => {
      let obj = toRaw(noteData)
      if(!obj.note){
        proxy.$message.error('提交订单备注不能为空')
        return false
      }
      loading.value = true
      proxy.$requests.orderNote(obj).then(r => {
        loading.value = false
        if (r.code === 0) {
          proxy.$message.success(r.msg)
          tableData.value = tableData.value.map(item => {
            if (item.id === obj.order_id) {
              item.note = obj.note
            }
            return item
          })
          modalClose()
        } else {
          proxy.$message.error(r.msg)
        }
      })
    }
    //初始化
    const initRows = () => ({})
    const rowsData = ref(initRows())
    //订单详情
    const detailModal = ref(false)
    const orderDetail = item => {
      detailModal.value = true
      rowsData.value = item
    }
    //订单记录
    const recordModal = ref(false)
    const orderRecord = item => {
      recordModal.value = true
      rowsData.value = item
    }
    return {
      labelCol: {style: {width: '100px'}},
      orderState: [
        {label: '全部', value: ''},
        {label: '待付款', value: 3},
        {label: '待发货', value: 4},
        {label: '待收货', value: 5},
        {label: '待评价', value: 6},
        {label: '已完成', value: 7},
        {label: '售后中', value: 2},
      ],
      payState: [
        {label: '全部', value: ''},
        {label: '微信', value: 1},
        {label: '支付宝', value: 2},
      ],
      loading,
      navData,
      columnTitle,
      formId: formId,
      userInfoNav: proxy.$inits.userInfoNav,
      formRef,
      formData,
      submitForm,
      resetForm,
      onPaginateChange,
      tableData,
      tableCol,
      paginate,
      evalData,
      evalBtn,
      fileEvalList,
      previewVisible,
      previewImage,
      handleCancel,
      handlePreview,
      beforeUpload,
      uploadChange,
      submitEval,
      modalClose,
      closeOrder,
      delOrder,
      payOrder,
      orderDetail,
      orderRecord,
      rowsData,
      detailModal,
      recordModal,
      noteData,
      submitNote,
      orderNote,
    }
  },
})
</script>

<style scoped>
.eval-panel .list-item {
  display: flex;
  align-items: flex-end;
  margin-bottom: 8px;
}

.eval-panel .list-item .item-label {
  width: 80px;

}

.eval-panel .list-item .item-content {
  flex: 1;
}
</style>
