<template>
  <a-card :bordered="false">
    <a-typography>
      <a-typography-title :level="4" style="font-weight: unset;color: #333;">业务介绍</a-typography-title>
      <a-typography-paragraph>
        建匠网为房产信息供应商提供信息发布渠道。同时也为平台注册的业务员提供房屋分销业务。
      </a-typography-paragraph>
    </a-typography>
  </a-card>
  <a-card :bordered="false">
    <a-typography>
      <a-typography-title :level="4" style="font-weight: unset;color: #333;">使用说明</a-typography-title>
      <a-typography-paragraph>
        <blockquote>
          <p>1.我的项目：房产信息供应商通过平台发布房产信息并上传相关材料。</p>
          <p>2.我的锁单：平台注册的业务员为消费者提供房屋销售业务时，对该房屋进行平台锁定操作。</p>
          </blockquote>
      </a-typography-paragraph>
    </a-typography>
  </a-card>
</template>

<script>
export default {
  name: "HouseOverview"
}
</script>

<style scoped>

</style>
