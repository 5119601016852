<template>
  <a-card :bordered="false">
    <a-typography>
      <a-typography-title :level="4" style="font-weight: unset;color: #333;">业务介绍</a-typography-title>
      <a-typography-paragraph>
        建匠网为供应商和需求方提供一个投资信息有偿共享平台，提供了投资信息的发布、购买、查看等功能。
      </a-typography-paragraph>
    </a-typography>
  </a-card>
  <a-card :bordered="false">
    <a-typography>
      <a-typography-title :level="4" style="font-weight: unset;color: #333;">使用说明</a-typography-title>
      <a-typography-paragraph>
        <blockquote>
          <p>1.我的发布：平台为供应商提供投资相关信息的发布功能。</p>
          <p>2.我的购买：平台为需求方提供投资相关信息的购买功能。</p>
          </blockquote>
      </a-typography-paragraph>
    </a-typography>
  </a-card>
</template>

<script>
export default {
  name: "InvestOverview"
}
</script>

<style scoped>

</style>
