<template>
  <div class="main-panel scrollbar" style="width: 100%;">
    <a-row :gutter="[20,20]">
      <a-col :lg="6" :md="12" :sm="12" :xs="24">
        <a-card class="card-panel">
          <a-skeleton :loading="loading" :paragraph="{ rows: 2 }" :title="false">
            <a-statistic :precision="0" :value="dataRows.news" title="新闻数量"
                         value-style="text-align: center;font-size:38px"/>
          </a-skeleton>
        </a-card>
      </a-col>
      <a-col :lg="6" :md="12" :sm="12" :xs="24">
        <a-card class="card-panel">
          <a-skeleton :loading="loading" :paragraph="{ rows: 2 }" :title="false">
            <a-statistic :precision="0" :value="dataRows.album" title="相册数量"
                         value-style="text-align: center;font-size:38px"/>
          </a-skeleton>
        </a-card>
      </a-col>
      <a-col :lg="6" :md="12" :sm="12" :xs="24">
        <a-card class="card-panel">
          <a-skeleton :loading="loading" :paragraph="{ rows: 2 }" :title="false">
            <a-statistic :precision="0" :value="dataRows.video" title="视频数量"
                         value-style="text-align: center;font-size:38px"/>
          </a-skeleton>
        </a-card>
      </a-col>
      <a-col :lg="6" :md="12" :sm="12" :xs="24">
        <a-card class="card-panel">
          <a-skeleton :loading="loading" :paragraph="{ rows: 2 }" :title="false">
            <a-statistic :precision="0" :value="dataRows.job" title="招聘数量"
                         value-style="text-align: center;font-size:38px"/>
          </a-skeleton>
        </a-card>
      </a-col>
      <a-col :sm="12" :xs="24">
        <a-card class="card-panel" style="height: 485px">
          <a-tabs>
            <a-tab-pane key="1" tab="我的新闻">
              <a-skeleton :loading="loading" :paragraph="{ rows: 2 }" :title="false">
                <a-list :data-source="data.news">
                  <template #renderItem="{ item }">
                    <a-list-item>{{ item.title }}</a-list-item>
                  </template>
                </a-list>
              </a-skeleton>
            </a-tab-pane>
            <a-tab-pane key="2" tab="我的相册">
              <a-skeleton :loading="loading" :paragraph="{ rows: 2 }" :title="false">
                <a-list :data-source="data.album">
                  <template #renderItem="{ item }">
                    <a-list-item>{{ item.title }}</a-list-item>
                  </template>
                </a-list>
              </a-skeleton>
            </a-tab-pane>
          </a-tabs>
        </a-card>
      </a-col>
      <a-col :sm="12" :xs="24">
        <a-card class="card-panel" style="height: 485px">
          <a-skeleton :loading="loading" :paragraph="{ rows: 2 }" :title="false">
            <a-tabs>
              <a-tab-pane key="1" tab="我的视频">
                <a-skeleton :loading="loading" :paragraph="{ rows: 2 }" :title="false">
                  <a-list :data-source="data.video">
                    <template #renderItem="{ item }">
                      <a-list-item>{{ item.title }}</a-list-item>
                    </template>
                  </a-list>
                </a-skeleton>
              </a-tab-pane>
              <a-tab-pane key="2" tab="我的招聘">
                <a-skeleton :loading="loading" :paragraph="{ rows: 2 }" :title="false">
                  <a-list :data-source="data.job">
                    <template #renderItem="{ item }">
                      <a-list-item>{{ item.title }}</a-list-item>
                    </template>
                  </a-list>
                </a-skeleton>
              </a-tab-pane>
            </a-tabs>
          </a-skeleton>
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import {defineComponent, getCurrentInstance, ref} from "vue"

export default defineComponent({
  setup() {
    const {proxy} = getCurrentInstance()
    const loading = ref(true)
    const dataRows = ref({news: 0, album: 0, video: 0, job: 0})
    const data = ref({
      news: [], album: [], video: [], job: []
    })
    /*初始化数据*/
    proxy.$requests.homeData({}).then(r => {
      loading.value = false
      if (r.code === 0) {
        let d = r.data;
        dataRows.value = d.rows
        data.value = d.data
      } else {
        proxy.$message.error(r.msg)
      }
    })
    return {
      loading,
      dataRows,
      data
    }
  },
})
</script>

<style>
</style>
