import api from "@/plugins/request/api"

const state = {
    profile: ''
}
const mutations = {
    setProfile(state, data) {
        state.profile = data
    },
    updateProfile(state, data) {
        if (state.profile && state.profile.constructor === Object) {
            state.profile = Object.assign(state.profile, data)
        }
    },
}

const getters = {
    getProfile: state => {
        return state.profile
    }
}
const actions = {
    setProfile(context) {
        if (!context.state.profile) {
            api.userProfile({}).then(r => {
                if (r.code === 0) {
                    let d = r.data
                    context.commit('setProfile', d)
                    context.commit('token/setUserInfo', {
                        account_type: d.account_type,
                        avatar: d.avatar,
                        nickname: d.nickname,
                        sex: d.sex,
                        sn: d.sn,
                        status: d.status,
                        verify: d.verify
                    }, {root: true})
                }
            })
        }
    },

}
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}

