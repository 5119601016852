const state = {
    token: null, //初始化token
    userinfo: null,
}

const mutations = {
    //存储token方法 设置token等于外部传递进来的值
    setToken(state, data) {
        state.token = data
    },
    //存储userInfo
    setUserInfo(state, data) {
        state.userinfo = data
    },
}

export default {
    namespaced: true,
    state,
    mutations,
}
