import api from "@/plugins/request/api"
import cookie from "@/plugins/cookie/cookie"

const state = {
    sts: null,
}
const mutations = {
    setSts(state, data) {
        state.sts = data
    },
}

const getters = {
    getSts(state) {
        return state.sts
    },
}
const actions = {
    setStsConfig(context, payload = {}) {
        if (!context.state.sts || context.state.sts.length === 0 || !cookie.get('sts')) {
            api.ossSts(payload).then(r => {
                if (r.code === 0) {
                    let d = r.data
                    context.commit('setSts', d)
                    let exp = new Date(d.expiration) - new Date() - 900000
                    cookie.set('sts', Date.parse(new Date(d.expiration)), {expires: String(exp / 1000) + 's'})
                }
            })
        }
    },
}
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}

