<template>
  <a-config-provider :locale="locale">
    <router-view/>
  </a-config-provider>
</template>

<script>

import {defineComponent, getCurrentInstance} from 'vue';
import zhCN from 'ant-design-vue/es/locale/zh_CN';

export default defineComponent({
  setup() {
    const {proxy} = getCurrentInstance();
    proxy.$store.dispatch('system/setSystemConfig');
    return {
      locale: zhCN,
    }
  },
})
</script>

<style>
</style>
