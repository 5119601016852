import oss from '@/plugins/oss/oss'
import store from '@/store'

Date.prototype.Format = function (fmt) {
    let o = {
        "M+": this.getMonth() + 1, //月份
        "d+": this.getDate(), //日
        "h+": this.getHours(), //小时
        "m+": this.getMinutes(), //分
        "s+": this.getSeconds(), //秒
        "q+": Math.floor((this.getMonth() + 3) / 3), //季度
        "S": this.getMilliseconds() //毫秒
    }
    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length))
    for (let k in o)
        if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)))
    return fmt
}

export default {
    /**
     * 省市区数据层级切割
     * @return
     * @param data： 省市区数据
     * @param level： 层级
     * @param index
     * */
    fmtArea: function (data, level, index = 0) {
        index++
        for (const key in data) {
            if (level > index) {
               this.fmtArea(data[key].children,level,index)
            }else {
                delete data[key].children
            }

        }
        return data
    },
    /**
     * 数字位数不够，进行前补零
     * @return {string}自定义函数名：PrefixZero
     * @param num： 被操作数
     * @param len： 固定的总位数
     */
    PrefixZero: function (num, len) {
        if (String(num).length > len) return num
        return (Array(len).join(0) + num).slice(-len)
    },
    /**
     * 时间戳转化为年 月 日 时 分 秒
     * date: date对象
     * format：返回格式，支持自定义，yyyy-MM-dd hh:mm:ss
     */
    ft: function (date, format) {
        return new Date(date).Format(format)
    },
    /**
     * 时间戳转化为年 月 日 时 分 秒
     * date: date对象
     * format：返回格式，支持自定义，yyyy-MM-dd hh:mm:ss
     */
    formatDate: function (date = '', format = 'yyyy-MM-dd hh:mm:ss') {
        if (date) {
            return new Date(date).Format(format)
        } else {
            return new Date().Format(format)
        }
    },

    /**
     * 将秒转换为 分:秒
     * s int 秒数
     */
    mts: function (s) {
        //  计算分钟
        //  算法：将秒数除以60，然后下舍入，既得到分钟数
        let h
        h = Math.floor(s / 60)
        //  计算秒
        //  算法：取得秒%60的余数，既得到秒数
        s = Math.floor(s % 60) + 1
        //  将变量转换为字符串
        h += ''
        s += ''
        //  如果只有一位数，前面增加一个0
        h = (h.length === 1) ? '0' + h : h
        s = (s.length === 1) ? '0' + s : s
        return h + ':' + s
    },

    /**
     * 比较大小排序
     */
    compare: function (prop) {
        return function (obj1, obj2) {
            let val1 = obj1[prop]
            let val2 = obj2[prop]
            if (!isNaN(Number(val1)) && !isNaN(Number(val2))) {
                val1 = Number(val1)
                val2 = Number(val2)
            }
            if (val1 < val2) {
                return 1
            } else if (val1 > val2) {
                return -1
            } else {
                return 0
            }
        }
    },

    /**
     * 随机字符串
     */
    randomStr: function (len) {
        len = len || 32
        let $chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890'
        let maxPos = $chars.length
        let str = ''
        for (let i = 0; i < len; i++) {
            str += $chars.charAt(Math.floor(Math.random() * maxPos))
        }
        return str
    },

    /**
     * 获取文件后缀名
     */
    getFileExt: function (file) {
        return file.substring(file.lastIndexOf(".")).toLowerCase()
    },

    /**
     * 生成文件名
     */
    makeFileName: function () {
        let date = new Date().Format("yyyyMMddhhmmss")
        return date + '_' + Math.round(Math.random() * 10000 + 10000)
    },
    /**
     * 文件转Base64
     * */
    getBase64: function (file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result)
            reader.onerror = error => reject(error)
        })
    },
    /**
     * 构建上传列表结构
     * */
    setFileList: function (uid, name, url) {
        return {
            uid: uid,
            name: name,
            status: 'done',
            url: url,
        }
    },
    /**
     * 递归树
     * */
    toTree: function (data) {
        // 删除 所有 children,以防止多次调用
        data.forEach(function (item) {
            delete item.children
        })

        let map = {}
        data.forEach(function (item, index) {
            item.index = index
            map[item.id] = item
        })

        let val = []
        data.forEach(function (item) {
            let parent = map[item.pid]
            if (parent) {
                (parent.children || (parent.children = [])).push(item)
            } else {
                val.push(item)
            }
        })
        return val
    },
    /**
     * 替换图片视频的src，并签名
     * @param dom;
     * @param option;
     */
    replaceSrc: function (dom, option = {}) {
        let img = '', video = ''
        if (option.image) {
            let obj_img = option.image
            for (let i in obj_img) {
                img += ' ' + i + '="' + obj_img[i] + '"'
            }
        }
        if (option.video) {
            let obj_video = option.video;
            for (let i in obj_video) {
                video += ' ' + i + '="' + obj_video[i] + '"'
            }
        }
        dom = dom.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/gi, function (match, capture) {
            return '<img src="' + oss.sign(capture) + '"' + img + '>'
        })
        dom = dom.replace(/<video [^>]*src=['"]([^'"]+)[^>]*>/gi, function (match, capture) {
            return '<video src="' + oss.sign(capture) + '" controls="controls"' + video + '>'
        })
        return dom
    },
    //处理元素焦点变化事件
    isFocus: function (e) {
        let attr = e.currentTarget.parentElement.getAttribute('class')
        attr = attr.indexOf('el-focused') === -1 ? attr + ' el-focused' : attr.replace('el-focused', '')
        e.currentTarget.parentElement.setAttribute('class', attr)
    },
    //验证对象键值为真
    validObjValue: function (obj) {
        for (let key in obj) {
            if (!obj[key]) {
                return false
            }
        }
        return true
    },
    //账号登出清除vuex
    logoutClear: function () {
        store.commit('user/setProfile', '')
        store.commit('auth/setAuth', {})
        store.commit('aliyun/setSts', null)
    }
}
