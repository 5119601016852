<template>
  <div class="bg-white form-panel">
    <div class="left-side">
      <div class="label">登录</div>
      <a-form ref="formRef" :model="formData" :rules="formRules" layout="vertical">
        <a-form-item class="form-item" name="phone">
          <a-input v-model:value="formData.phone" placeholder="请输入手机号码" type="text">
            <template #prefix>
              <UserOutlined style="color: rgba(0, 0, 0, 0.25)"/>
            </template>
          </a-input>
        </a-form-item>
        <a-form-item class="form-item" name="pwd">
          <a-input v-model:value="formData.pwd" placeholder="请输入密码" type="password">
            <template #prefix>
              <LockOutlined style="color: rgba(0, 0, 0, 0.25)"/>
            </template>
          </a-input>
        </a-form-item>
        <a-form-item class="form-item">
          <Vcode :show="isShowMachine" @close="isShowMachine = !isShowMachine" @success="onSuccessMachine"/>
          <div v-if="formData.machine" class="machine-success">验证通过</div>
          <div v-else class="man-machine" @click="isShowMachine = !isShowMachine">点击按钮进行验证</div>
        </a-form-item>
        <a-form-item class="form-btn">
          <a-button :loading="loading" block type="primary" size="large" @click="submitForm">提 交</a-button>
        </a-form-item>
      </a-form>
    </div>
    <div class="right-side">
      <login-nav></login-nav>
    </div>
  </div>
</template>

<script>
import LoginNav from "@/components/nav/LoginNav"
import {computed, defineComponent, getCurrentInstance, reactive, ref, toRaw} from 'vue'

export default defineComponent({
  components: {
    LoginNav
  },
  setup() {
    const loading = ref(false)
    const {proxy} = getCurrentInstance()
    const cookie_config = computed(() => proxy.$store.getters["system/getCookieConfig"])
    const formRef = ref()
    const formData = reactive({      phone: '',      pwd: '',      machine: false,    })
    const isShowMachine = ref(false)
    const onSuccessMachine = () => {
      isShowMachine.value = !isShowMachine.value
      formData.machine = true
    }

    const formRules = reactive({
      phone: [
        {required: true, message: '手机号码必须填写！', trigger: 'change',},
        {
          pattern: proxy.$inits.regex.phone,
          message: '手机号码不正确！',
          trigger: 'blur',
        }
      ],
      pwd: [
        {required: true, message: '密码必须填写！', trigger: 'change',},
        {min: 5, message: '密码不正确！', trigger: 'blur',},
      ],
    })

    //提交表单
    const submitForm = () => {
      loading.value = !loading.value
      formRef.value.validate().then(() => {
        let d = toRaw(formData)
        if (!d.machine) {
          loading.value = !loading.value
          proxy.$message.error('请点击按钮进行验证！')
          return false
        }
        proxy.$requests.appLogin({
          phone: d.phone,
          pwd: proxy.$md5.make(d.pwd)
        }).then(r => {
          loading.value = !loading.value
          if (r.code === 0) {
            formRef.value.resetFields() //重置表单
            let token = r.data.token
            let userinfo = r.data.info
            let system_cookie = cookie_config.value
            token.token_name = system_cookie.token_name
            token.exp_name = system_cookie.exp_name
            token.domain = system_cookie.domain

            proxy.$store.commit('token/setToken', token)
            proxy.$store.commit('token/setUserInfo', userinfo)
            proxy.$cookies.setTokenCookie(token)
            proxy.$router.push('/home')
          } else {
            proxy.$message.error(r.msg)
          }
        })
      }).catch(() => {
        loading.value = !loading.value
      })
    }

    return {
      loading,
      formRef,
      formData,
      formRules,
      submitForm,
      isShowMachine,
      onSuccessMachine,
    }
  },
})
</script>

<style scoped>
</style>
