import VideoCapture from "video-capture"

export default {
    videoCapture: async function (url) {
        try {
            let {dataURL} = await new VideoCapture(url).capture(1)
            return dataURL
        } catch (e) {
            console.log(e)
        }
    }
}
