<template>
  <div class="section-wrap">
    <div class="container-panel login-header-panel">
      <router-link class="block" title="建匠网" to="/">
        <div class="logo-img"></div>
        <div class="logo-tips">{{ logoTips }}</div>
      </router-link>
      <div v-if="website.business_tel" class="tel-info">
        招商热线：<span>{{ website.business_tel }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, defineComponent, getCurrentInstance} from "vue";

export default defineComponent({
  props: ['logoTips'],
  setup() {
    const {proxy} = getCurrentInstance();
    const website = computed(() => proxy.$store.getters["system/getWebsiteConfig"]);
    return {
      website
    }
  }
})
</script>

<style scoped>
</style>
