<template>
  <a-spin :spinning="loading" tip="请稍等...">
    <a-card :bordered="false">
      <a-form ref="formRef" :label-col="{style:{width:'100px'}}" :model="formData" :rules="formRules">
        <a-row :gutter="10">
          <a-col :span="24">
            <a-form-item label="标题" name="title">
              <a-input v-model:value="formData.title" placeholder="请输入标题" type="text"/>
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="门牌号" name="house_code">
              <a-input v-model:value="formData.house_code" placeholder="xx栋xx室" type="text"/>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="卧室" name="bedroom">
              <a-input-number v-model:value="formData.bedroom" addon-after="个" max="99" min="0"/>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="客厅" name="parlor">
              <a-input-number v-model:value="formData.parlor" addon-after="个" max="99" min="0"/>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="厨房" name="galley">
              <a-input-number v-model:value="formData.galley" addon-after="个" max="99" min="0"/>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="卫生间" name="toilet">
              <a-input-number v-model:value="formData.toilet" addon-after="个" max="99" min="0"/>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="总楼层" name="total_level">
              <a-input-number v-model:value="formData.total_level" addon-after="层" max="999" min="1"/>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="位于楼层" name="active_level">
              <a-input-number v-model:value="formData.active_level" addon-after="层" max="999" min="1"/>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="装修" name="renovation">
              <a-radio-group v-model:value="formData.renovation">
                <a-radio value="毛坯">毛坯</a-radio>
                <a-radio value="简装">简装</a-radio>
                <a-radio value="精装">精装</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="建筑类型" name="house_type">
              <a-radio-group v-model:value="formData.house_type">
                <a-radio value="普通住宅">普通住宅</a-radio>
                <a-radio value="商住两用">商住两用</a-radio>
                <a-radio value="商业住宅">商业住宅</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="电梯" name="elevator">
              <a-radio-group v-model:value="formData.elevator">
                <a-radio value="1">有</a-radio>
                <a-radio value="0">无</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="建筑面积" name="house_area">
              <a-input-number v-model:value="formData.house_area" addon-after="平方" max="9999" min="1"/>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="产权年限" name="property_right">
              <a-input-number v-model:value="formData.property_right" addon-after="年" max="999" min="1"/>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="挂牌时间" name="listing_date">
              <a-date-picker v-model:value="formData.listing_date"/>
            </a-form-item>
          </a-col>

          <a-col :span="24">
            <a-form-item label="建筑朝向" name="house_toward">
              <a-radio-group v-model:value="formData.house_toward">
                <a-radio value="南">南</a-radio>
                <a-radio value="西南">西南</a-radio>
                <a-radio value="西">西</a-radio>
                <a-radio value="西北">西北</a-radio>
                <a-radio value="北">北</a-radio>
                <a-radio value="东北">东北</a-radio>
                <a-radio value="东">东</a-radio>
                <a-radio value="东南">东南</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="展示图" name="images" style="min-height: 120px">
              <div class="form-upload-area">
                <div v-for=" (item,index) in formData.images" :key="index" class="form-upload-item">
                  <a-image :src="signImg(item)"/>
                </div>
                <div class="form-upload-item" @click="openUploadModal">
                  <span style="font-size: 20px">+</span>
                  <span>上传</span>
                </div>
              </div>
            </a-form-item>
          </a-col>

          <a-col :span="24">
            <a-form-item label="状态" name="status">
              <a-radio-group v-model:value="formData.status">
                <a-radio :value="2">上架</a-radio>
                <a-radio :value="1">待上架</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <div class="text-center">
              <a-button :loading="loading" type="primary" @click="submitForm">提交</a-button>
            </div>
          </a-col>
        </a-row>
      </a-form>
    </a-card>
    <a-modal v-model:visible="modalData.visible" :bodyStyle="{padding:0}" :closable="false" :footer="null"
             :keyboard="false" :maskClosable="false" :width="830">
      <a-spin :spinning="uploadLoading" tip="上传中...">
        <div class="modal-title">项目图库</div>
        <div class="upload-area">
          <div v-for=" (item,index) in modalData.imgList" :key="index" class="upload-item">
            <div :class="{'img-active':isImgChecked(item)}" class="upload-item-img" @click="imgCheckedBtn(item)">
              <img :src="signImg(item)" alt="example"/>
            </div>
            <div v-if="!isImgChecked(item)" class="upload-item-action" @click="imgDeleteBtn(item)">删除</div>
          </div>
          <div v-if="modalData.imgList.length<25">
            <a-upload :before-upload="beforeUpload" :show-upload-list="false"
                      list-type="picture-card" style="width: 148px;height: 148px;" @change="uploadChange">
              <plus-outlined/>
              <div class="ant-upload-text">上传</div>
            </a-upload>
          </div>
        </div>
        <div class="modal-action">
          <div class="modal-tips">
            最多可选择 6 张图片，选择 <span class="text-primary">{{ modalData.selectList.length }}</span> 张。
          </div>
          <div class="modal-action-btn">
            <a-button style="margin-right: 30px" @click="cancelModalBtn">取消</a-button>
            <a-button type="primary" @click="okModalBtn">确定</a-button>
          </div>
        </div>
      </a-spin>
    </a-modal>
  </a-spin>
</template>

<script>
import {defineComponent, getCurrentInstance, onMounted, reactive, ref, toRaw} from "vue"
import dayjs from 'dayjs';

export default defineComponent({
  emits: ['drawerClose'],
  props: ['drawerData'],
  setup() {
    const loading = ref(false)
    const uploadLoading = ref(false)
    const {proxy} = getCurrentInstance()

    proxy.$store.dispatch('aliyun/setStsConfig')//上传操作前，检测STS
    const areaData = proxy.$inits.area

    const tpl = ref()

    /*----表单---*/
    const formRef = ref()

    const formData = reactive({
      id: '',
      hp_id: '',
      title: '',
      images: [],
      house_code: null,
      bedroom: null,
      parlor: null,
      galley: null,
      toilet: null,
      house_area: null,
      house_toward: null,
      elevator: null,
      renovation: null,
      total_level: null,
      active_level: null,
      house_type: null,
      property_right: null,
      listing_date: null,
      status: 2
    })
    const formRules = reactive({
      title: [
        {required: true, message: '名称必须填写！', trigger: 'change',},
        {max: 64, message: '名称最多64个字符！', trigger: 'blur',},
      ],
      images: [
        {required: true, message: '展示图必须上传！', trigger: 'change',},
      ],
      house_code: [
        {required: true, message: '门牌号必须填写！', trigger: 'change',},
        {max: 32, message: '门牌号最多32个字符！', trigger: 'blur',},
      ],
      bedroom: [
        {required: true, message: '卧室数量必须标明！', trigger: 'change',},
      ],
      parlor: [
        {required: true, message: '客厅数量必须标明！', trigger: 'change',},
      ],
      galley: [
        {required: true, message: '厨房数量必须标明！', trigger: 'change',},
      ],
      toilet: [
        {required: true, message: '卫生间数量必须标明！', trigger: 'change',},
      ],
      house_area: [
        {required: true, message: '建筑面积必须标明！', trigger: 'change',},
      ],
      house_toward: [
        {required: true, message: '建筑朝向必须标明！', trigger: 'change',},
      ],
      elevator: [
        {required: true, message: '有无电梯必须标明！', trigger: 'change',},
      ],
      renovation: [
        {required: true, message: '装修情况必须标明！', trigger: 'change',},
      ],
      total_level: [
        {required: true, message: '总楼层必须标明！', trigger: 'change',},
      ],
      active_level: [
        {required: true, message: '位于楼层必须标明！', trigger: 'change',},
      ],
      house_type: [
        {required: true, message: '建筑类型必须标明！', trigger: 'change',},
      ],
      property_right: [
        {required: true, message: '产权年限必须标明！', trigger: 'change',},
      ],
      listing_date: [
        {required: true, message: '挂牌时间必须标明！', trigger: 'change',},
      ],
      status: [
        {required: true, message: '状态必须选择！', trigger: 'change',},
      ],
    })

    /*-----图库---*/
    const initModal = () => ({
      visible: false,
      imgList: [],
      uploadList: [],
      deleteList: [],
      selectList: [],
    })

    const remoteImages = ref([])
    const modalData = reactive(initModal())
    const openUploadModal = async () => {
      modalData.visible = true
      modalData.selectList = [...formData.images]
      let listImg = await listHouseImg(tpl.value.id)
      let uniqueArr = [...listImg, ...formData.images]
      modalData.imgList = [...new Set(uniqueArr)]
    }
    const signImg = (item) => {
      return proxy.$oss.sign(item)
    }

    const isImgChecked = (item) => {
      if (modalData.selectList.length > 0) {
        return modalData.selectList.indexOf(item) !== -1
      }
      return false
    }
    const imgCheckedBtn = (item) => {
      let index = modalData.selectList.indexOf(item)
      if (index === -1) {
        if (modalData.selectList.length < 6) {
          modalData.selectList.push(item)
        } else {
          proxy.$message.error('最多选择6张配图', 2)
        }
      } else {
        modalData.selectList.splice(index, 1)
      }
    }
    const imgDeleteBtn = item => {
      let listData = new Set(modalData.imgList)
      listData.delete(item)
      modalData.imgList = Array.from(listData)

      let uploadData = new Set(modalData.uploadList)
      uploadData.delete(item)
      modalData.uploadList = Array.from(uploadData)

      let list = remoteImages.value
      for (let i = 0; i < list.length; i++) {
        if (list[i]['link'] === item) {
          modalData.deleteList.push(list[i]['id'])
        }

      }
    }
    const cancelModalBtn = () => {
      Object.assign(modalData, initModal())
    }

    const okModalBtn = () => {
      if (modalData.uploadList.length === 0 && modalData.deleteList.length === 0) {
        formData.images = modalData.selectList
        remoteImages.value = []
        Object.assign(modalData, initModal())
      } else {
        imgChangeRequest({
          hp_id: tpl.value.id,
          upload_img: modalData.uploadList,
          delete_img: modalData.deleteList,
        })
      }
    }

    const imgChangeRequest = async obj => {
      await proxy.$requests.setHouseImgChange(obj).then(r => {
        if (r.code === 0) {
          formData.images = modalData.selectList
          remoteImages.value = []
          Object.assign(modalData, initModal())
        } else {
          proxy.$message.error(r.msg, 1.5).then(
              () => {
                Object.assign(modalData, initModal())
              },
              () => {
              }
          )
        }
      })

    }

    const listHouseImg = async id => {
      return await proxy.$requests.getHouseImg({hp_id: id}).then(r => {
        if (r.code === 0) {
          remoteImages.value = r.data
          return r.data.map(row => row['link'])
        } else {
          proxy.$message.error(r.msg, 1.5).then(
              () => {
                Object.assign(modalData, initModal())
              },
              () => {
              }
          )
        }
      })
    }
    /*-----上传----*/
    const beforeUpload = () => {
      return false
    }
    const uploadChange = async (r) => {
      let fileData = r.file
      if (fileData.type !== 'image/jpeg' && fileData.type !== 'image/png') {
        proxy.$message.error('上传图片格式错误，只支持jpg/jpeg/png', 2)
        return false
      }
      if (fileData.status && fileData.status === "removed") {
        return false
      }

      let isSize = fileData.size / 1024 / 1024 < 1, baseFile = await proxy.$tools.getBase64(fileData)
      // 大图重新绘制
      if (!isSize) {
        let obj = await proxy.$img.imgCompress(baseFile, {width: 1000, quality: 0.75})
        baseFile = obj.img
        fileData = proxy.$img.dataURLtoFile(baseFile, 'icon.jpg')
      }

      uploadLoading.value = true
      let ext = proxy.$tools.getFileExt(fileData.name);
      let fileName = proxy.$tools.makeFileName();
      await proxy.$oss.upload('house', fileName + ext, fileData).then(r => {
        uploadLoading.value = false
        if (r && r.res.statusCode === 200) {
          modalData.imgList.push(r.name)
          modalData.uploadList.push(r.name)
        } else {
          proxy.$message.error('上传中断，请稍后重试！')
          return false;
        }
      })
    }

    /*---提交按钮---*/
    const submitForm = () => {
      loading.value = true
      formRef.value.validate().then(async () => {
        //提交方法
        requestSaveData(toRaw(formData))
        //提交方法
      }).catch(() => {
        loading.value = false
      })
    }
    //发送请求
    const requestSaveData = obj => {
      let data = {...obj}
      data.listing_date = dayjs(obj.listing_date).format('YYYY-MM-DD')
      proxy.$requests.houseSave(data).then(r => {
        if (r.code === 0) {
          proxy.$message.success(r.msg, 1.2).then(
              () => {
                loading.value = false
                proxy.$emit('drawerClose')
              },
              () => {
              }
          )

        } else {
          loading.value = false
          proxy.$message.error(r.msg)
        }
      })
    }

    /*----初始化数据----*/
    onMounted(async () => {
      let tplData = proxy.$props.drawerData
      try {
        if (!tplData || Object.keys(tplData).length === 0) {
          throw '页面数据加载失败！'
        }
        tpl.value = tplData
        formData.hp_id = tplData.id
        if (tplData.formData) {
          formData.id = tplData.formData.id
          formData.title = tplData.formData.title
          formData.images = tplData.formData.images.split(',')
          formData.house_code = tplData.formData.house_code
          formData.bedroom = tplData.formData.bedroom
          formData.parlor = tplData.formData.parlor
          formData.galley = tplData.formData.galley
          formData.toilet = tplData.formData.toilet
          formData.house_area = tplData.formData.house_area
          formData.house_toward = tplData.formData.house_toward
          formData.elevator = tplData.formData.elevator
          formData.renovation = tplData.formData.renovation
          formData.total_level = tplData.formData.total_level
          formData.active_level = tplData.formData.active_level
          formData.house_type = tplData.formData.house_type
          formData.property_right = tplData.formData.property_right
          formData.listing_date = dayjs(tplData.formData.listing_date)
          formData.status = tplData.formData.status
        }
      } catch (e) {
        proxy.$message.error('未知错误  ' + e, 2).then(() => {
          proxy.$emit('drawerClose')
        }, () => {
        })
      }
    });
    return {
      loading,
      uploadLoading,
      modalData,
      openUploadModal,
      areaData,
      formRef,
      formData,
      formRules,
      submitForm,
      beforeUpload,
      uploadChange,
      signImg,
      isImgChecked,
      imgCheckedBtn,
      imgDeleteBtn,
      cancelModalBtn,
      okModalBtn,
    }
  }
})
</script>
<style scoped>
.form-upload-area {
  display: flex;
  flex-wrap: wrap;
}

.form-upload-item {
  width: 120px;
  height: 120px;
  margin-inline-end: 8px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.02);
  border: 1px dashed #d9d9d9;
  border-radius: 8px;
  overflow: hidden;
}

.upload-area :deep(.ant-upload-select-picture-card) {
  width: 148px;
  height: 148px;
}

.upload-area {
  display: flex;
  padding: 15px;
  flex-wrap: wrap;
  max-height: 550px;
  overflow-y: scroll;
}

.upload-item {
  width: 148px;
  margin-inline-end: 8px;
  margin-bottom: 8px;
}

.upload-item:hover {
  border-color: #1677ff;
  color: #1677ff;
}

.upload-item-img {
  width: 100%;
  height: 148px;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.02);
  border: 1px dashed #d9d9d9;
  border-radius: 8px;
  cursor: pointer;
}

.upload-item-img:hover {
  border-color: #1677ff;
}

.img-active {
  position: relative;
}

.img-active:before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  background-color: #000000a1;
}

.img-active:after {
  position: absolute;
  content: '√';
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #1677ff;
  font-weight: bold;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
}

.upload-item-img img {
  width: 100%;
}

.upload-item-action {
  padding-top: 5px;
  font-size: 10px;
  text-align: center;
  cursor: pointer;
}

.upload-item .upload-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  text-align: center;
}

.modal-title {
  padding: 10px 15px;
  font-size: 18px;
  border-bottom: 1px solid #f2f2f2;
}

.modal-action {
  padding: 20px 15px;
  border-top: 1px solid #f2f2f2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.modal-tips {
  flex: 1;
  font-size: 10px;
}
</style>
