<template>
  <a-card :loading="loading" class="card-panel" style="padding: 30px;">
    <a-descriptions :column="1" title="投资咨询">
      <a-descriptions-item label="编号">{{ tpl.sn }}</a-descriptions-item>
      <a-descriptions-item label="标题">{{ tpl.title }}</a-descriptions-item>
      <a-descriptions-item label="描述">{{ tpl.desc }}</a-descriptions-item>
      <a-descriptions-item label="价格">￥{{ tpl.price ? tpl.price / 100 : 0 }}</a-descriptions-item>
    </a-descriptions>
    <a-divider/>
    <div style="text-align: right">
      <a-button :loading="btnLoading" type="primary" @click="confirmBtn">立即购买</a-button>
    </div>
  </a-card>
</template>

<script>
import {defineComponent, getCurrentInstance, ref} from "vue"

export default defineComponent({
  setup() {
    const loading = ref(true)
    const {proxy} = getCurrentInstance()
    const tpl = ref({id: null, sn: null, title: null, desc: null, price: 0})
    const params = proxy.$route.params
    proxy.$store.commit('pay/setOrder', null)
    proxy.$requests.investDetail(params).then(r => {
      loading.value = false
      if (r.code === 0) {
        if (r.is_buy) {
          proxy.$router.replace({path: '/invest/buy'})
          return false
        }
        if (r.data) {
          tpl.value = r.data
        } else {
          proxy.$router.replace({path: '/tips', query: {code: 20062, msg: '您访问的投资咨询信息不存在或已下架！'}})
        }
      } else {
        proxy.$router.replace({path: '/tips', query: {code: 20061, msg: r.msg}})
      }
    })
    const btnLoading = ref(false)
    const confirmBtn = async () => {
      let goods = [
        {model: 1, id: tpl.value.id, title: tpl.value.title, rows: 1, spec: null, price: tpl.value.price, icon: null},
      ]
      await proxy.$store.commit('pay/setOrder', {is_express: 0, goods: goods})
      await proxy.$router.replace('/pay/order')
    }
    return {
      loading,
      tpl,
      btnLoading,
      confirmBtn,
    }
  },
})
</script>

<style scoped>

</style>
