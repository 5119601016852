<template>
  <div class="main-panel" style="width: 100%">
    <router-view/>
  </div>

</template>

<script>
</script>

<style scoped>

</style>
