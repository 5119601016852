<template>
  <SideNav :nav-data="navData"/>
  <div class="main-panel scrollbar">
    <a-row :gutter="[20,20]">
      <a-col span="24">
        <a-card class="card-panel">
          <a-form ref="formRef" :model="formData" class="padding-sm" layout="inline">
            <a-form-item label="关键字" name="searchText">
              <a-input v-model:value="formData.searchText" placeholder="请输入" type="text"/>
            </a-form-item>
            <a-form-item label="状态" name="searchStatus">
              <a-select v-model:value="formData.searchStatus" placeholder="请选择" style="width: 175px">
                <a-select-option value="1">正常</a-select-option>
                <a-select-option value="2">下线</a-select-option>
                <a-select-option value="3">待审核</a-select-option>
                <a-select-option value="4">未通过</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item>
              <a-button v-preventReClick block type="primary" @click="submitForm">搜索</a-button>
            </a-form-item>
            <a-form-item>
              <a-button v-preventReClick block @click="resetForm">重置</a-button>
            </a-form-item>
          </a-form>
        </a-card>
      </a-col>
      <a-col span="24">
        <a-card class="card-panel">
          <div class="table-header-operation">
            <a-button type="primary" @click="onAddBtn">+ 新增</a-button>
          </div>
          <a-table :columns="tableCol" :data-source="tableData" :loading="loading" :pagination="paginate"
                   rowKey="id" @change="onPaginateChange">
            <template #bodyCell="{ column,record,index }">
              <template v-if="column.dataIndex === 'status'">
                <span v-if="record.status === 3" class="text-warning">待审核</span>
                <span v-else-if="record.status === 4" class="text-error">被驳回</span>
                <span v-else>正常</span>
              </template>
              <template v-if="column.dataIndex === 'play'">
                <div v-if="record.link" class="table-body-operation" style="font-size: 16px">
                  <a class="text-primary" @click="onPlayRow(record)">
                    <play-square-two-tone/>
                  </a>
                </div>
              </template>
              <template v-if="column.dataIndex === 'operation'">
                <div class="table-body-operation">
                  <a class="text-primary" @click="onEditRow(record)">编辑</a>
                  <a-popconfirm cancel-text="取消" ok-text="确定" title="确定执行删除操作?" @confirm="onDeleteRow(index)">
                    <a class="text-primary">删除</a>
                  </a-popconfirm>
                </div>
              </template>
            </template>
          </a-table>
        </a-card>
      </a-col>
    </a-row>
  </div>

  <a-drawer v-model:visible="drawerData.visible" :bodyStyle="{padding:0}" :closable="false" :destroyOnClose="true"
            :keyboard="false" :maskClosable="false" :width="900" placement="right">
    <div class="layer-panel">
      <div class="layer-title">
        <div class="title">{{ drawerData.title }}</div>
        <div class="icon" @click="drawerClose">
          <close-outlined/>
        </div>
      </div>
      <div class="layer-body bg-white">
        <component :is="drawerData.component" v-if="drawerData.component" :drawerData="drawerData.data"
                   @drawerClose="drawerClose"></component>
      </div>
    </div>
  </a-drawer>
  <a-modal v-model:visible="modalVisible" :footer="null" :keyboard="false" :maskClosable="false" :width="768"
           title="播放视频" @cancel="ModalClose">
    <video-player ref="videoPlayer"
                  :options="playerOptions"
                  :playsinline="true"
                  class="vjs-custom-skin">
    </video-player>
  </a-modal>
</template>

<script>
import {defineComponent, getCurrentInstance, reactive, ref, toRaw} from "vue"
import Video from "@/views/pages/video/Video"
import SideNav from "@/components/nav/SideNav"
import VideoPlayer from "vue-video-player/src"

window.videojs = VideoPlayer.videojs

export default defineComponent({
  components: {SideNav,Video},
  setup() {
    const loading = ref(false)
    const {proxy} = getCurrentInstance()
    const navData = proxy.$inits.companyNav
    const formRef = ref()
    const formData = reactive({})
    const tableData = ref([])
    const tableCol = [
      {title: '编号', dataIndex: 'sn', width: 120,},
      {title: '标题', dataIndex: 'title', ellipsis: true,},
      {title: '播放', dataIndex: 'play', align: 'center', width: 120,},
      {title: '状态', dataIndex: 'status', align: 'center', width: 120,},
      {title: '发布时间', dataIndex: 'create_time', align: 'center', width: 180,},
      {title: '操作', dataIndex: 'operation', align: 'center', width: 220,}
    ]
    const initPaginate = () => ({
      total: 0,
      current: 1,
      pageSize: 8,
      page: 1,
      limit: 80,
      count: 0,
      showSizeChanger: false
    })
    const paginate = reactive(initPaginate())
    const onPaginateChange = pageNumber => {
      paginate.current = pageNumber.current
      if (pageNumber.current === Math.ceil(pageNumber.total / pageNumber.pageSize)) {
        if (pageNumber.count === pageNumber.total) {
          proxy.$message.info('没有数据了')
          return false
        }
        ++paginate.page
        requestList()
      }
    }
    //提交表单
    const submitForm = () => {
      Object.assign(paginate, initPaginate())
      tableData.value = []
      requestList()
    }
    //重置表单
    const resetForm = () => {
      formRef.value.resetFields()
      Object.assign(paginate, initPaginate())
      tableData.value = []
      requestList()
    }

    const initDrawer = () => ({visible: false, title: '', component: '', data: '',})
    const drawerData = reactive(initDrawer())
    const drawerClose = async () => {
      tableData.value = []
      if (drawerData.data) {
        let p = paginate.page
        for (let i = 0; i < p; i++) {
          paginate.page = i + 1
          await requestList()
        }
      } else {
        Object.assign(paginate, initPaginate())
        await requestList()
      }
      Object.assign(drawerData, initDrawer())
    }
    const onAddBtn = () => {
      drawerData.title = '添加视频'
      drawerData.visible = true
      drawerData.component = 'Video'
      drawerData.data = ''
    }
    const onEditRow = r => {
      drawerData.title = '编辑视频'
      drawerData.visible = true
      drawerData.component = 'Video'
      drawerData.data = {
        id: r.id,
        title: r.title,
        desc: r.desc,
        icon: r.icon,
        link: r.link
      }
    }
    const onDeleteRow = index => {
      let tData = tableData.value
      proxy.$requests.videoDel({id: tData[index].id}).then(r => {
        if (r.code === 0) {
          proxy.$message.success(r.msg)
          let key = (paginate.current - 1) * paginate.pageSize + index
          tData.splice(key, 1)
          paginate.total = tData.length
          let ceil = Math.ceil(key / paginate.pageSize)
          if (key % paginate.pageSize === 0 && key !== paginate.total) { //整除且key不等于总数
            paginate.current = ceil + 1
          } else {
            paginate.current = ceil > 0 ? ceil : 1
          }
          if (tData.length === 0) {
            requestList()
          }

        } else {
          proxy.$message.error(r.msg)
        }
      })
    }

    const requestList = async () => {
      loading.value = !loading.value
      let d = toRaw(formData)
      await proxy.$requests.videoList({
        page: paginate.page,
        limit: paginate.limit,
        searchStatus: d.searchStatus,
        searchText: d.searchText,
      }).then(r => {
        loading.value = !loading.value
        if (r.code === 0) {
          tableData.value = tableData.value.concat(r.data)
          paginate.total = tableData.value.length
          paginate.count = r.count
        } else {
          proxy.$message.error(r.msg)
        }
      })
    }
    requestList()
    /*--------模态框---------*/
    const modalVisible = ref(false)
    const onPlayRow = r => {
      playerOptions.value.sources = [{
        type: "video/mp4",
        src: proxy.$oss.sign(r.link)
      }]
      playerOptions.value.poster = proxy.$oss.sign(r.icon)
      modalVisible.value = true
    }

    const ModalClose = () => {
      onPlayerPause()
      modalVisible.value = false
    }
    /*--------视频播放器---------*/
    const playerOptions = ref({
      width: '720',
      height: '405',
      language: 'zh-CN',
      sources: [{
        type: "video/mp4",
        src: ""
      }],
      poster: "",
    })
    //自定义暂停
    const onPlayerPause = () => {
      let player = proxy.$refs.videoPlayer.player
      player.pause()
    }
    return {
      loading,
      navData,
      formRef,
      formData,
      tableCol,
      tableData,
      paginate,
      drawerData,
      submitForm,
      resetForm,
      onPaginateChange,
      drawerClose,
      onAddBtn,
      onEditRow,
      onDeleteRow,
      onPlayRow,
      ModalClose,
      modalVisible,
      playerOptions,
    }
  },
})
</script>

<style scoped>

</style>
