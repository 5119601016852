<template>
  <login-head logo-tips="账号注册"/>
  <div class="section-wrap login-content-panel">
    <register-form/>
  </div>
  <page-footer/>
</template>

<script>
import LoginHead from "@/components/tpl/LoginHead"
import PageFooter from "@/components/tpl/PageFooter"
import RegisterForm from "@/components/tpl/RegisterForm"

export default {
  name: "Index",
  components: {
    LoginHead,
    PageFooter,
    RegisterForm,
  }
}
</script>

<style scoped>
</style>
