<template>
  <a-spin :spinning="loading" tip="请稍等...">
    <a-card :bordered="false" class="card-panel">
      <div class="title-panel">
        <div class="title">项目信息</div>
      </div>
      <a-descriptions :column="2">
        <a-descriptions-item label="项目名称">{{ tpl?.project_name }}</a-descriptions-item>
        <a-descriptions-item label="项目区域">{{ tpl?.prov }}{{ tpl?.city }}{{ tpl?.district }}</a-descriptions-item>
        <a-descriptions-item label="详细地址">{{ tpl?.address }}</a-descriptions-item>
      </a-descriptions>

      <a-divider/>

      <div style="display: flex;">
        <div class="table-header-operation" style="flex: 1;">
          <a-button type="primary" @click="onAddBtn">+ 添加项目房产</a-button>
        </div>
        <a-form ref="formRef" :model="formData" class="padding-sm" layout="inline">
          <a-form-item label="关键字" name="searchText">
            <a-input v-model:value="formData.searchText" placeholder="请输入" type="text"/>
          </a-form-item>
          <a-form-item>
            <a-button v-preventReClick block type="primary" @click="submitForm">搜索</a-button>
          </a-form-item>
          <a-form-item>
            <a-button v-preventReClick block @click="resetForm">重置</a-button>
          </a-form-item>
        </a-form>
      </div>

      <a-table :columns="tableCol" :data-source="tableData" :pagination="paginate"
               rowKey="id" size="middle" @change="onPaginateChange">
        <template #bodyCell="{ column,record,index }">
          <template v-if="column.dataIndex === 'key'">
            <span>{{ index + 1 }}</span>
          </template>
          <template v-if="column.dataIndex === 'status'">
            <span v-if="record.status === 1" class="text-warning">待上架</span>
            <span v-else-if="record.status === 2">已上架</span>
            <span v-else-if="record.status === 3" class="text-primary">已锁单</span>
            <span v-else-if="record.status === 4" class="text-success">已成交</span>
            <span v-else>其他</span>
          </template>
          <template v-if="column.dataIndex === 'operation'">
            <div class="table-body-operation">
              <a class="text-primary" @click="onShowRow(record)">查看</a>
              <a v-if="record.status===1||record.status===2" class="text-primary" @click="onEditRow(record)">编辑</a>
            </div>
          </template>
        </template>
      </a-table>
    </a-card>
  </a-spin>
  <a-drawer v-model:visible="drawerData.visible" :bodyStyle="{padding:0}" :closable="false" :destroyOnClose="true"
            :keyboard="false" :maskClosable="false" :width="900" placement="right">
    <div class="layer-panel">
      <div class="layer-title">
        <div class="title">{{ drawerData.title }}</div>
        <div class="icon" @click="drawerClose">
          <close-outlined/>
        </div>
      </div>
      <div class="layer-body bg-white">
        <component :is="drawerData.component" v-if="drawerData.component" :drawerData="drawerData.data"
                   @drawerClose="drawerClose"></component>
      </div>
    </div>
  </a-drawer>
</template>

<script>
import {defineComponent, getCurrentInstance, onMounted, reactive, ref, toRaw} from "vue"
import House from "@/views/pages/house/House"
import HouseShow from "@/views/pages/house/HouseShow";

export default defineComponent({
  components: {House,HouseShow},
  setup() {
    const loading = ref(false)
    const {proxy} = getCurrentInstance()
    const navData = proxy.$inits.appContentNav
    const tpl = ref()
    const formRef = ref()
    const formData = reactive({})
    const tableData = ref([])
    const tableCol = [
      {title: '编号', dataIndex: 'key', width: 80,},
      {title: '标题', dataIndex: 'title', ellipsis: true,},
      {title: '状态', dataIndex: 'status', align: 'center', width: 120,},
      {title: '发布时间', dataIndex: 'create_time', align: 'center', width: 180,},
      {title: '操作', dataIndex: 'operation', align: 'center', width: 220,}
    ]
    const initPaginate = () => ({
      total: 0,
      current: 1,
      pageSize: 8,
      page: 1,
      limit: 80,
      count: 0,
      showSizeChanger: false
    })
    const paginate = reactive(initPaginate())
    const onPaginateChange = pageNumber => {
      paginate.current = pageNumber.current
      if (pageNumber.current === Math.ceil(pageNumber.total / pageNumber.pageSize)) {
        if (pageNumber.count === pageNumber.total) {
          proxy.$message.info('没有数据了')
          return false
        }
        ++paginate.page
        requestList()
      }
    }
    //提交表单
    const submitForm = () => {
      Object.assign(paginate, initPaginate())
      tableData.value = []
      requestList()
    }
    //重置表单
    const resetForm = () => {
      formRef.value.resetFields()
      Object.assign(paginate, initPaginate())
      tableData.value = []
      requestList()
    }

    const initDrawer = () => ({visible: false, title: '', component: '', data: '',})
    const drawerData = reactive(initDrawer())
    const drawerClose = async () => {
      tableData.value = []
      if (drawerData.data) {
        let p = paginate.page
        for (let i = 0; i < p; i++) {
          paginate.page = i + 1
          await requestList()
        }
      } else {
        Object.assign(paginate, initPaginate())
        await requestList()
      }
      Object.assign(drawerData, initDrawer())
    }
    const onAddBtn = () => {
      drawerData.title = '添加房产信息'
      drawerData.visible = true
      drawerData.component = 'House'
      drawerData.data = tpl.value

    }
    const onEditRow = r=>{
      drawerData.title = '编辑房产信息'
      drawerData.visible = true
      drawerData.component = 'House'
      const d = {...tpl.value}
      d.formData = r
      drawerData.data = d
    }
    const onShowRow = r => {
      drawerData.title = '查看'
      drawerData.visible = true
      drawerData.component = 'HouseShow'
      drawerData.data = {
        id: r.id,
        sn: r.sn,
      }
    }

    const requestList = async () => {
      loading.value = true
      let d = toRaw(formData)
      await proxy.$requests.houseList({
        page: paginate.page,
        limit: paginate.limit,
        searchText: d.searchText,
        hp_id: tpl.value.id
      }).then(r => {
        loading.value = false
        if (r.code === 0) {
          tableData.value = tableData.value.concat(r.data)
          paginate.total = tableData.value.length
          paginate.count = r.count
        } else {
          proxy.$message.error(r.msg)
        }
      })
    }


    onMounted(async () => {
      /*----初始化数据----*/
      try {
        loading.value = true
        const params = proxy.$route.params
        if (!params || !params['id']) {
          throw '页面数据加载失败！'
        }
        await proxy.$requests.getHouseProject(params).then(r => {
          if (r.code === 0) {
            tpl.value = r.data
          } else {
            throw r.msg
          }
        })
        await requestList()
      } catch (e) {
        loading.value = false
        proxy.$message.error('未知错误  ' + e, 3)
        proxy.$router.go(-1)
      }
    });

    return {
      loading,
      navData,
      formRef,
      formData,
      tableCol,
      tableData,
      paginate,
      drawerData,
      submitForm,
      resetForm,
      onPaginateChange,
      drawerClose,
      onShowRow,
      tpl,
      onAddBtn,
      onEditRow,

    }
  },
})
</script>

<style scoped>

</style>
