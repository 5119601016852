import Icon, {
    BellOutlined,
    DatabaseOutlined,
    LockOutlined,
    MobileOutlined,
    PictureOutlined,
    SafetyOutlined,
    SendOutlined,
    UserOutlined,
    VideoCameraOutlined,
    LogoutOutlined,
    CloseOutlined,
    PlaySquareTwoTone,
    EyeTwoTone,
    LeftCircleOutlined,
    RightCircleOutlined,
    PlusOutlined,
    UploadOutlined,
    EditOutlined,
    CheckOutlined,
    CloseCircleOutlined,
    WarningOutlined,
    WechatOutlined,
    AlipayOutlined,
    ScheduleOutlined,
    DownOutlined,
    SearchOutlined,
} from '@ant-design/icons-vue'
import icon from './icon'

export default {
    install: (app) => {
        app.component('SearchOutlined', SearchOutlined)
        app.component('DownOutlined', DownOutlined)
        app.component('ScheduleOutlined', ScheduleOutlined)
        app.component('WechatOutlined', WechatOutlined)
        app.component('AlipayOutlined', AlipayOutlined)
        app.component('WarningOutlined', WarningOutlined)
        app.component('CloseCircleOutlined', CloseCircleOutlined)
        app.component('CheckOutlined', CheckOutlined)
        app.component('EditOutlined', EditOutlined)
        app.component('UploadOutlined', UploadOutlined)
        app.component('PlusOutlined', PlusOutlined)
        app.component('LeftCircleOutlined', LeftCircleOutlined)
        app.component('RightCircleOutlined', RightCircleOutlined)
        app.component('EyeTwoTone', EyeTwoTone)
        app.component('PlaySquareTwoTone', PlaySquareTwoTone)
        app.component('CloseOutlined', CloseOutlined)
        app.component('LogoutOutlined', LogoutOutlined)
        app.component('DatabaseOutlined', DatabaseOutlined)
        app.component('BellOutlined', BellOutlined)
        app.component('LockOutlined', LockOutlined)
        app.component('UserOutlined', UserOutlined)
        app.component('MobileOutlined', MobileOutlined)
        app.component('SafetyOutlined', SafetyOutlined)
        app.component('SendOutlined', SendOutlined)
        app.component('Icon', Icon)
        app.component('VideoCameraOutlined', VideoCameraOutlined)
        app.component('PictureOutlined', PictureOutlined)
        app.component('Menu', {
            template: icon.menu
        })
        app.component('Job', {
            template: icon.job
        })
        app.component('Supply', {
            template:  icon.supply
        })
        app.component('News', {
            template:  icon.news
        })
        app.component('Demand', {
            template:  icon.demand
        })
        app.component('IdCard', {
            template:  icon.idCard
        })
        app.component('Building', {
            template:  icon.building
        })
        app.component('Invest', {
            template:  icon.invest
        })
        app.component('House', {
            template:  icon.house
        })
    }
}
