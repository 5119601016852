<template>
  <SideNav :nav-data="navData"/>
  <div class="main-panel scrollbar">
    <a-card class="card-panel">
      <a-card :bordered="false">
        <div class="title-panel">
          <div class="title">{{ columnTitle }}</div>
          <div class="sub-title text-primary cursor" @click="onEditBtn">编辑</div>
        </div>
        <div class="text-center" style="padding: 30px 0;">
          <a-avatar v-if="tpl.company_logo" :size="128" :src="$oss.sign(tpl.company_logo)"/>
          <a-avatar v-else :size="128">
            <template #icon>
              <Building/>
            </template>
          </a-avatar>
        </div>
        <a-skeleton :loading="loading" :paragraph="{ rows: 4 }">
          <a-descriptions>
            <a-descriptions-item label="公司名称">{{ tpl.company_name }}</a-descriptions-item>
            <a-descriptions-item label="公司简称">
              <span v-if="tpl.company_abbr">{{ tpl.company_abbr }}</span>
              <span v-else class="text-error">待完善</span>
            </a-descriptions-item>
            <a-descriptions-item label="所属行业">
              <span v-if="tpl.company_industry">{{ tpl.company_industry.join(' - ') }}</span>
              <span v-else class="text-error">待完善</span>
            </a-descriptions-item>
            <a-descriptions-item label="企业规模">
              <span v-if="tpl.company_scale">{{ tpl.company_scale }}</span>
              <span v-else class="text-error">待完善</span>
            </a-descriptions-item>
          </a-descriptions>
        </a-skeleton>
      </a-card>
    </a-card>
  </div>

  <a-drawer v-model:visible="drawerData.visible" :bodyStyle="{padding:0}" :closable="false" :destroyOnClose="true"
            :keyboard="false" :maskClosable="false" :width="drawerData.width" placement="right">
    <div class="layer-panel">
      <div class="layer-title">
        <div class="title">{{ drawerData.title }}</div>
        <div class="icon" @click="drawerClose">
          <close-outlined/>
        </div>
      </div>
      <div class="layer-body bg-white">
        <a-spin :spinning="loading" tip="请稍等...">
          <a-card :bordered="false">
            <a-form ref="formRef" :model="formData" :rules="formRules" layout="vertical">
              <a-form-item label="企业LOGO" name="company_logo" style="min-height: 118px;">
                <a-upload v-model:file-list="fileList" :before-upload="beforeUpload" list-type="picture-card"
                          @change="uploadChange" @preview="handlePreview">
                  <div v-if="fileList.length < 1">
                    <plus-outlined/>
                    <div class="ant-upload-text">上传</div>
                  </div>
                </a-upload>
                <a-modal :footer="null" :visible="previewVisible" @cancel="cancelPreview">
                  <img :src="previewImage" alt="example" style="width: 100%"/>
                </a-modal>
              </a-form-item>
              <a-form-item label="企业简称" name="company_abbr">
                <a-input v-model:value="formData.company_abbr" placeholder="不超过10个字符"/>
              </a-form-item>
              <a-form-item label="企业简介" name="company_desc">
                <a-textarea v-model:value="formData.company_desc" :auto-size="{ minRows: 4, maxRows: 4 }"
                            placeholder="不超过180个字符"/>
              </a-form-item>
              <a-form-item label="所属行业" name="company_industry">
                <a-cascader v-model:value="formData.company_industry" :field-names="{ label: 'name', value: 'name' }"
                            :options="industryData" placeholder="请选择"/>
              </a-form-item>
              <a-form-item label="企业规模" name="company_scale">
                <a-select v-model:value="formData.company_scale" placeholder="请输入">
                  <a-select-option v-for="(item,index) in companyScale" :key="index" :value="item">
                    {{ item }}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item class="text-center">
                <a-button :loading="loading" type="primary" @click="submitForm">提 交</a-button>
              </a-form-item>
            </a-form>
          </a-card>
        </a-spin>
      </div>
    </div>
  </a-drawer>
</template>

<script>
import {defineComponent, getCurrentInstance, reactive, ref, toRaw} from "vue"
import SideNav from "@/components/nav/SideNav"

export default defineComponent({
  components: {SideNav},
  setup() {
    const loading = ref(true)
    const {proxy} = getCurrentInstance()
    const navData = proxy.$inits.companyNav
    const columnTitle = ref('')
    navData.data.filter((item) => {
      if (item.link === proxy.$route.path) {
        return columnTitle.value = item.name
      }
    })
    const industryData = proxy.$inits.industry
    const companyScale = proxy.$inits.companyScale
    const formRef = ref();
    const initData = () => ({
      id: '',
      company_name: '',
      company_abbr: '',
      company_logo: '',
      company_desc: '',
      company_industry: [],
      company_scale: ''
    })
    const tpl = ref(initData())
    const formData = reactive(initData())
    //验证上传文件
    let validFile = (_rule, value) => {
      if (value === '' || !value) {
        return Promise.reject('LOGO必须上传！')
      }
      if (value instanceof File) {
        if (value.type !== 'image/jpeg' && value.type !== 'image/png') {
          return Promise.reject('上传图片格式(jpg/jpeg/png)')
        }
      }
      return Promise.resolve()
    }
    const formRules = reactive({
      company_logo: [
        {validator: validFile, required: true, trigger: 'change',},
      ],
      company_abbr: [
        {required: true, message: '企业简称必须填写！', trigger: 'change',},
        {max: 10, message: '企业简称不超过10个字符！', trigger: 'blur',}
      ],
      company_desc: [
        {required: true, message: '企业简介必须填写！', trigger: 'change',},
        {max: 180, message: '企业简介不超过180个字符！', trigger: 'blur',}
      ],
      company_industry: [
        {required: true, type: 'array', message: '所属行业必须选择！', trigger: 'change',}
      ],
      company_scale: [
        {required: true, message: '企业规模必须选择！', trigger: 'change',}
      ],
    });
    const initDrawer = () => ({visible: false, title: '', width: '800',})
    const drawerData = reactive(initDrawer())
    const drawerClose = async () => {
      Object.assign(drawerData, initDrawer())
    }
    const onEditBtn = () => {
      drawerData.title = '编辑'
      drawerData.visible = true

      proxy.$store.dispatch('aliyun/setStsConfig')//上传操作前，检测STS
      if (tpl.value.company_logo) {
        fileList.value = [
          proxy.$tools.setFileList(1, tpl.value.company_logo, proxy.$oss.sign(tpl.value.company_logo))
        ]
      }
    }
    /*-----上传----*/
    const previewVisible = ref(false)
    const previewImage = ref('')
    const fileList = ref([])
    //取消预览
    const cancelPreview = () => {
      previewVisible.value = false
    }
    //预览图片
    const handlePreview = async file => {
      previewImage.value = file.thumbUrl
      previewVisible.value = true
    }
    const beforeUpload = () => {
      return false
    }
    const handleChange = ({fileList: newFileList}) => {
      fileList.value = newFileList
    }
    const uploadChange = async (r) => {
      if (r.fileList.length > 0) {
        let fileData = r.file
        if (fileData.type === 'image/jpeg' || fileData.type === 'image/png') {
          let isSize = fileData.size / 1024 / 1024 < 0.2, baseFile = await proxy.$tools.getBase64(fileData)
          if (!isSize) {
            let obj = await proxy.$img.imgCompress(baseFile, {width: 200, quality: 0.75})
            baseFile = obj.img
            let d = proxy.$img.dataURLtoFile(baseFile, 'icon.jpg')
            fileList.value = [{
              lastModified: d.lastModified,
              lastModifiedDate: d.lastModifiedDate,
              originFileObj: d,
              percent: 0,
              size: d.size,
              thumbUrl: baseFile,
              type: d.type,
              name: fileData.name,
              uid: fileData.uid,
            }]
            formData.company_logo = d
          } else {
            formData.company_logo = fileData
          }
        } else {
          fileList.value = []
        }
        formRef.value.validate('company_logo')
      } else {
        fileList.value = []
        formData.company_logo = ''
      }
    }
    //提交表单
    const submitForm = () => {
      loading.value = true
      formRef.value.validate().then(async () => {
        if (formData.company_logo instanceof File) {
          let poster_file = formData.company_logo
          await proxy.$oss.upload(
              'logo',
              proxy.$tools.makeFileName() + proxy.$tools.getFileExt(poster_file.name),
              poster_file
          ).then(r => {
            if (r && r.res.statusCode === 200) {
              formData.company_logo = r.name
            } else {
              proxy.$message.error('上传中断，请刷新页面后重试！')
              loading.value = false
              return false
            }
          })
        }
        if (!formData.company_logo) {
          proxy.$message.error('企业LOGO数据丢失！')
          loading.value = false
          return false
        }
        requestAction(toRaw(formData))
      }).catch(() => {
        loading.value = false
      })
    }

    const requestAction = obj => {
      proxy.$requests.editCompany(obj).then(r => {
        if (r.code === 0) {
          tpl.value = obj
          proxy.$message.success(r.msg, 2).then(
              () => {
                loading.value = false
                drawerClose()
              },
              () => {
              }
          )
        } else {
          loading.value = false
          proxy.$message.error(r.msg)
        }
      })
    }
    /*初始化数据*/
    proxy.$requests.userCompany({}).then(r => {
      if (r.code === 0) {
        loading.value = false
        let data = r.data
        data.company_industry = data.company_industry ? data.company_industry.split(',') : []
        tpl.value = data
        formData.id = data.id
        formData.company_name = data.company_name
        formData.company_logo = data.company_logo
        formData.company_abbr = data.company_abbr
        formData.company_desc = data.company_desc
        formData.company_industry = data.company_industry
        formData.company_scale = data.company_scale
      }
    })
    return {
      loading,
      navData,
      columnTitle,
      tpl,
      industryData,
      companyScale,
      drawerData,
      drawerClose,
      formRef,
      formData,
      formRules,
      onEditBtn,
      fileList,
      cancelPreview,
      handlePreview,
      beforeUpload,
      handleChange,
      uploadChange,
      previewVisible,
      previewImage,
      submitForm,
    }
  },
})
</script>

<style scoped>

</style>
