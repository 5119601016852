import {createApp} from 'vue'
import App from './App.vue'
import router from "@/router"
import store from '@/store'
import antdIcon from '@/plugins/antd-icon'
import antdUi from '@/plugins/antd-ui'
import cookie from '@/plugins/cookie'
import directive from '@/plugins/directive'
import image from '@/plugins/image'
import init from '@/plugins/init'
import md5 from '@/plugins/md5'
import oss from '@/plugins/oss'
import request from '@/plugins/request'
import storage from '@/plugins/storage'
import tool from '@/plugins/tool'
import vcode from '@/plugins/vcode'
import video from "@/plugins/video"
import pdf from "@/plugins/pdf"

import '@/assets/css/style.css' //放在所有样式的最下面
const app = createApp(App)
app.use(router)
app.use(store)
app.use(antdIcon)
app.use(antdUi)
app.use(cookie)
app.use(directive)
app.use(image)
app.use(init)
app.use(md5)
app.use(oss)
app.use(request)
app.use(storage)
app.use(tool)
app.use(vcode)
app.use(video)
app.use(pdf)
app.mount('#app')
