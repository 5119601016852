<template>
  <div class="section-wrap">
    <div class="container-panel login-footer-panel">
      <div class="footer-nav">
        <ul class="nav-box">
          <li v-for="item in footerNav" :key="item">
            <a :target="item.nav_target" :href="item.nav_link">{{ item.nav_name }}</a>
          </li>
        </ul>
      </div>
      <div class="footer-copyright">
        <span v-if="website.copyright">{{ website.copyright }}</span>
        <span v-if="website.site_icp"><a href="https://beian.miit.gov.cn/" target="_blank">{{ website.site_icp }}</a></span>
        <span v-if="website.kf_tel">建匠网客服热线：{{ website.kf_tel }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, defineComponent, getCurrentInstance} from 'vue';

export default defineComponent({
  setup() {
    const {proxy} = getCurrentInstance();
    const footerNav = computed(() => proxy.$store.getters["system/getSystemFooterMenu"]);
    const website = computed(() => proxy.$store.getters["system/getWebsiteConfig"]);
    return {
      footerNav,
      website
    }
  },
})
</script>

<style scoped>
</style>
