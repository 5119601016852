<template>
  <a-card :bordered="false" class="card-panel">
    <a-form ref="formRef" :model="formData" class="padding-sm" layout="inline">
      <a-form-item label="关键字" name="searchText">
        <a-input v-model:value="formData.searchText" placeholder="请输入" type="text"/>
      </a-form-item>
      <a-form-item>
        <a-button v-preventReClick block type="primary" @click="submitForm">搜索</a-button>
      </a-form-item>
      <a-form-item>
        <a-button v-preventReClick block @click="resetForm">重置</a-button>
      </a-form-item>
    </a-form>
    <a-divider/>
    <a-table :columns="tableCol" :data-source="tableData" :loading="loading" :pagination="paginate"
             rowKey="id" @change="onPaginateChange">
      <template #bodyCell="{ column,record,index }">
        <template v-if="column.dataIndex === 'sn'">
          <span>{{ index + 1 }}</span>
        </template>
        <template v-if="column.dataIndex === 'operation'">
          <div class="table-body-operation">
            <a class="text-primary" @click="onShowRow(record)">预览</a>
          </div>
        </template>
      </template>
    </a-table>
  </a-card>

  <a-drawer v-model:visible="drawerData.visible" :bodyStyle="{padding:0}" :closable="false" :destroyOnClose="true"
            :keyboard="false" :maskClosable="false" :width="900" placement="right">
    <div class="layer-panel">
      <div class="layer-title">
        <div class="title">{{ drawerData.title }}</div>
        <div class="icon" @click="drawerClose">
          <close-outlined/>
        </div>
      </div>
      <div class="layer-body bg-white">
        <component :is="drawerData.component" v-if="drawerData.component" :drawerData="drawerData.data"
                   @drawerClose="drawerClose"></component>
      </div>
    </div>
  </a-drawer>
</template>

<script>
import {defineComponent, getCurrentInstance, reactive, ref, toRaw} from "vue"
import Invest from "@/views/pages/invest/Invest"
import InvestShow from "@/views/pages/invest/InvestShow"

export default defineComponent({
  components: {Invest,InvestShow},
  setup() {
    const loading = ref(false)
    const {proxy} = getCurrentInstance()
    const navData = proxy.$inits.appContentNav
    const formRef = ref()
    const formData = reactive({})
    const tableData = ref([])
    const tableCol = [
      {title: '编号', dataIndex: 'sn', width: 120,},
      {title: '标题', dataIndex: 'title', ellipsis: true,},
      {title: '购买时间', dataIndex: 'create_time', align: 'center', width: 180,},
      {title: '操作', dataIndex: 'operation', align: 'center', width: 180,}
    ]
    const initPaginate = () => ({
      total: 0,
      current: 1,
      pageSize: 8,
      page: 1,
      limit: 80,
      count: 0,
      showSizeChanger: false
    })
    const paginate = reactive(initPaginate())
    const onPaginateChange = pageNumber => {
      paginate.current = pageNumber.current
      if (pageNumber.current === Math.ceil(pageNumber.total / pageNumber.pageSize)) {
        if (pageNumber.count === pageNumber.total) {
          proxy.$message.info('没有数据了')
          return false
        }
        ++paginate.page
        requestList()
      }
    }
    //提交表单
    const submitForm = () => {
      Object.assign(paginate, initPaginate())
      tableData.value = []
      requestList()
    }
    //重置表单
    const resetForm = () => {
      formRef.value.resetFields()
      Object.assign(paginate, initPaginate())
      tableData.value = []
      requestList()
    }

    const initDrawer = () => ({visible: false, title: '', component: '', data: '',})
    const drawerData = reactive(initDrawer())
    const drawerClose = async () => {
      tableData.value = []
      if (drawerData.data) {
        let p = paginate.page
        for (let i = 0; i < p; i++) {
          paginate.page = i + 1
          await requestList()
        }
      } else {
        Object.assign(paginate, initPaginate())
        await requestList()
      }
      Object.assign(drawerData, initDrawer())
    }
    const onShowRow = r => {
      drawerData.title = '预览'
      drawerData.visible = true
      drawerData.component = 'InvestShow'
      drawerData.data = {
        id: r.id,
        title: r.title,
        desc: r.desc,
        content: r.content,
      }
    }

    const requestList = async () => {
      loading.value = !loading.value
      let d = toRaw(formData)
      await proxy.$requests.memberInvest({
        page: paginate.page,
        limit: paginate.limit,
        searchText: d.searchText,
      }).then(r => {
        loading.value = !loading.value
        if (r.code === 0) {
          tableData.value = tableData.value.concat(r.data)
          paginate.total = tableData.value.length
          paginate.count = r.count
        } else {
          proxy.$message.error(r.msg)
        }
      })
    }
    requestList()
    return {
      loading,
      navData,
      formRef,
      formData,
      tableCol,
      tableData,
      paginate,
      drawerData,
      submitForm,
      resetForm,
      onPaginateChange,
      drawerClose,
      onShowRow,
    }
  },
})
</script>

<style scoped>

</style>
