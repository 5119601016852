const state = {
    order: null,
}
const mutations = {
    setOrder(state, data) {
        state.order = data
    },
}

const getters = {
    getOrder: state => {
        return state.order
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
}

