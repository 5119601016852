<template>
  <div class="main-panel" style="width: 100%">
    <div class="bg-white" style="height: 100%">
      <div class="sub-main-panel">
        <div class="scroll-panel scrollbar">
          支付界面
        </div>
      </div>
    </div>
  </div>

</template>

<script>
</script>

<style scoped>
</style>
