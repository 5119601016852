<template>
  <a-layout>
    <a-layout-header class="app-header-panel">
      <div class="header-left">
        <div class="menu-bar" @click="closeMenu">
          <Menu/>
        </div>
        <router-link class="app-logo" to="/home"></router-link>
      </div>
      <div class="header-right">
        <SubNav/>
      </div>
    </a-layout-header>
    <a-layout-content class="app-content-panel">
      <router-view/>
    </a-layout-content>
  </a-layout>

  <a-drawer :bodyStyle="{padding:0,backgroundColor:'#f5f5f5'}" :closable="false" :visible="menuDrawer" :width="800"
            :z-index="990" placement="left" @close="menuDrawer=!menuDrawer">
    <div class="app-menu-wrap">
      <!--个人设置-->
      <div v-if="userNav">
        <div class="list-label">{{ userNav.label }}</div>
        <a-row :gutter="20">
          <a-col v-for="(item,index) in userNav.data" :key="index" :span="8">
            <div :class="path===item.link?'list-item item-active':'list-item'" @click="jumpUrl(item)">
              <component :is="item.icon" :style="{fontSize:'18px'}"></component>
              <span class="item-title">{{ item.name }}</span>
            </div>
          </a-col>
        </a-row>
      </div>
      <!--业务管理-->
      <div v-if="businessNav">
        <div class="list-label">{{ businessNav.label }}</div>
        <a-row :gutter="20">
          <a-col v-for="(item,index) in businessNav.data" :key="index" :span="8">
            <div :class="path===item.link?'list-item item-active':'list-item'" @click="jumpUrl(item)">
              <component :is="item.icon" :style="{fontSize:'18px'}"></component>
              <span class="item-title">{{ item.name }}</span>
            </div>
          </a-col>
        </a-row>
      </div>
      <!--内容管理-->
      <div v-if="appContentNav">
        <div class="list-label">{{ appContentNav.label }}</div>
        <a-row :gutter="20">
          <a-col v-for="(item,index) in appContentNav.data" :key="index" :span="8">
            <div :class="path===item.link?'list-item item-active':'list-item'" @click="jumpUrl(item)">
              <component :is="item.icon" :style="{fontSize:'18px'}"></component>
              <span class="item-title">{{ item.name }}</span>
            </div>
          </a-col>
        </a-row>
      </div>
      <!--企业管理-->
      <div v-if="companyNav">
        <div class="list-label">{{ companyNav.label }}</div>
        <a-row :gutter="20">
          <a-col v-for="(item,index) in companyNav.data" :key="index" :span="8">
            <div :class="path===item.link?'list-item item-active':'list-item'" @click="jumpUrl(item)">
              <component :is="item.icon" :style="{fontSize:'18px'}"></component>
              <span class="item-title">{{ item.name }}</span>
            </div>
          </a-col>
        </a-row>
      </div>
    </div>
  </a-drawer>
</template>
<script>
import {computed, defineComponent, getCurrentInstance, ref, watch} from 'vue'
import SubNav from "@/components/nav/SubNav"

export default defineComponent({
  components: {SubNav},
  setup() {
    const menuDrawer = ref(false)
    const {proxy} = getCurrentInstance()

    watch(proxy.$router.currentRoute, () => {
      menuDrawer.value = false
    })
    const jumpUrl = e => {
      menuDrawer.value = false
      let link = e.link
      proxy.$router.push(link)
    }
    const path = computed(() => proxy.$route.path)
    const closeMenu = () => {
      menuDrawer.value = !menuDrawer.value
    }
    return {
      menuDrawer,
      userNav: proxy.$inits.userNav,
      businessNav: proxy.$inits.businessNav,
      appContentNav: proxy.$inits.appContentNav,
      companyNav: proxy.$inits.companyNav,
      path,
      jumpUrl,
      closeMenu,
    }
  },
})
</script>
<style scoped>
.app-header-panel {
  position: fixed;
  z-index: 999;
  width: 100%;
  background: #ffffff;
  color: inherit;
  height: var(--app-header-height);
  line-height: var(--app-header-height);
  padding: 0;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 2px 4px 0 rgba(51, 51, 51, .05);
}

.app-content-panel {
  margin-top: var(--app-header-height);
  height: calc(100vh - var(--app-header-height));
  overflow: hidden;
}

.app-content-panel:before, .app-content-panel:after {
  content: "";
  display: block;
  clear: both;
}

.app-menu-wrap {
  margin-top: var(--app-header-height);
  padding: 20px;
}

.app-menu-wrap .list-label {
  padding: 16px 0;
}

.app-menu-wrap .list-item {
  background-color: #ffffff;
  padding: 14px 12px;
  border: 1px solid #e5e5e5;
  border-radius: 2px;
  margin-bottom: 20px;
  cursor: pointer;
}

.app-menu-wrap .item-title {
  padding-left: 10px;
}

.app-menu-wrap .list-item:hover, .item-active {
  color: var(--app-color-primary);
}

.menu-bar {
  background-color: var(--app-color-primary);
  color: #ffffff;
  width: var(--app-header-height);
  line-height: var(--app-header-height);
  text-align: center;
  font-size: 24px;
  cursor: pointer;
  float: left;
}
</style>
