<template>
  <a-spin :spinning="loading" tip="请稍等...">
    <a-card :bordered="false">
    <a-form ref="formRef" :model="formData" :rules="formRules" layout="vertical">
      <a-row :gutter="16">
        <a-col :span="24">
          <a-form-item label="岗位名称" name="title">
            <a-input v-model:value="formData.title" placeholder="请输入岗位名称" type="text"/>
          </a-form-item>
        </a-col>
        <a-col :span="24">
          <a-form-item label="招聘邮箱" name="mail">
            <a-input v-model:value="formData.mail" placeholder="请输入招聘邮箱" type="text"/>
          </a-form-item>
        </a-col>
        <a-col :span="24">
          <a-form-item label="工作区域" name="area">
            <a-cascader v-model:value="formData.area" :field-names="{ label: 'name', value: 'name' }"
                        :options="areaData" placeholder="请选择"/>
          </a-form-item>
        </a-col>
        <a-col :span="24">
          <a-form-item label="地址详情" name="address">
            <a-textarea v-model:value="formData.address" placeholder="请输入地址详情"/>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="薪资待遇" name="salary">
            <a-select v-model:value="formData.salary" placeholder="请输入薪资待遇">
              <a-select-option v-for="(item,index) in config_salary" :key="index" :value="item">
                {{ item }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="学历教育" name="education">
            <a-select v-model:value="formData.education" placeholder="请输入学历教育">
              <a-select-option v-for="(item,index) in config_education" :key="index" :value="item">
                {{ item }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="工作年限" name="experience">
            <a-select v-model:value="formData.experience" placeholder="请输入工作年限">
              <a-select-option v-for="(item,index) in config_experience" :key="index" :value="item">
                {{ item }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="工作性质" name="type">
            <a-select v-model:value="formData.type" placeholder="请输入工作性质">
              <a-select-option v-for="(item,index) in config_type" :key="index" :value="item">
                {{ item }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="24">
          <a-form-item label="岗位职责" name="duty" required>
            <div ref="duty_editor" class="editor-wrap"></div>
          </a-form-item>
        </a-col>
        <a-col :span="24">
          <a-form-item label="任职要求" name="demand" required>
            <div ref="demand_editor" class="editor-wrap"></div>
          </a-form-item>
        </a-col>
      </a-row>
      <br>
      <div class="text-center">
        <a-button :loading="loading" type="primary" @click="submitForm">提交</a-button>
      </div>
    </a-form>
    </a-card>
  </a-spin>
</template>

<script>
import {computed, defineComponent, getCurrentInstance, onBeforeUnmount, onMounted, reactive, ref, toRaw} from "vue"
import WangEditor from "wangeditor"

export default defineComponent({
  emits: ['drawerClose'],
  props: ['drawerData'],
  setup() {
    const loading = ref(false)
    const {proxy} = getCurrentInstance()
    proxy.$store.dispatch('job/setJobConfig')//加载招聘配置
    const config_salary = computed(() => proxy.$store.getters["job/getSalary"])
    const config_education = computed(() => proxy.$store.getters["job/getEducation"])
    const config_experience = computed(() => proxy.$store.getters["job/getExperience"])
    const config_type = computed(() => proxy.$store.getters["job/getType"])
    const areaData = proxy.$inits.area
    /*编辑器*/
    const duty_editor = ref()
    const demand_editor = ref()
    let instance_duty, instance_demand
    let editor_menus = ['bold', 'italic', 'underline', 'list']
    onMounted(() => {
      instance_duty = new WangEditor(duty_editor.value)
      instance_duty.config.height = 200
      instance_duty.config.menus = editor_menus
      instance_duty.create()
      instance_duty.txt.html(formData.duty)

      instance_demand = new WangEditor(demand_editor.value)
      instance_demand.config.height = 200
      instance_demand.config.menus = editor_menus
      instance_demand.create()
      instance_demand.txt.html(formData.demand)
    })

    onBeforeUnmount(() => {
      instance_duty.destroy()
      instance_duty = null

      instance_demand.destroy()
      instance_demand = null
    })

    const formRef = ref()
    const formData = reactive({
      id: '',
      title: '',
      mail: '',
      area: [],
      address: '',
      salary: '',
      education: '',
      experience: '',
      type: '',
      duty: '',
      demand: '',
    })

    if (proxy.$props.drawerData && Object.keys(proxy.$props.drawerData).length !== 0) {
      try {
        formData.id = proxy.$props.drawerData.id
        formData.title = proxy.$props.drawerData.title
        formData.mail = proxy.$props.drawerData.mail
        formData.area = proxy.$props.drawerData.area
        formData.address = proxy.$props.drawerData.address
        formData.salary = proxy.$props.drawerData.salary
        formData.education = proxy.$props.drawerData.education
        formData.experience = proxy.$props.drawerData.experience
        formData.type = proxy.$props.drawerData.type
        formData.duty = proxy.$props.drawerData.duty
        formData.demand = proxy.$props.drawerData.demand
      } catch (e) {
        proxy.$message.error('未知错误', 1.2).then(() => {
          proxy.$emit('drawerClose')
        }, () => {
        })
      }
    }
    const formRules = reactive({
      title: [
        {required: true, message: '岗位名称必须填写！', trigger: 'change',},
        {max: 64, message: '岗位名称最多64个字符！', trigger: 'blur',},
      ],
      mail: [
        {required: true, message: '招聘邮箱必须填写！', trigger: 'change',},
        {pattern: proxy.$inits.regex.mail, message: '招聘邮箱格式错误！', trigger: 'blur',}
      ],
      area: [
        {required: true, type: 'array', message: '工作区域必须选择！', trigger: 'change',},
      ],
      address: [
        {required: true, message: '地址详情必须填写！', trigger: 'change',},
        {max: 64, message: '详细地址不超过64个字符！', trigger: 'blur',}
      ],
      salary: [
        {required: true, message: '薪资待遇必须选择！', trigger: 'change',},
      ],
      education: [
        {required: true, message: '学历教育必须选择！', trigger: 'change',},
      ],
      experience: [
        {required: true, message: '工作年限必须选择！', trigger: 'change',},
      ],
      type: [
        {required: true, message: '工作性质必须选择！', trigger: 'change',},
      ],
      duty: [
        {required: true, message: '岗位职责必须填写！', trigger: 'blur',},
      ],
      demand: [
        {required: true, message: '任职要求必须填写！', trigger: 'blur',},
      ],
    })
    const submitForm = async () => {
      formData.duty = proxy.$tools.replaceSrc(instance_duty.txt.html())
      formData.demand = proxy.$tools.replaceSrc(instance_demand.txt.html())
      loading.value = true
      formRef.value.validate().then(() => {
        //提交方法
        requestJobSave(toRaw(formData))
      }).catch(() => {
        loading.value = false
      })
    }
    //发送请求
    const requestJobSave = obj => {
      proxy.$requests.jobSave(obj).then(r => {
        if (r.code === 0) {
          proxy.$message.success(r.msg, 1.2).then(
              () => {
                loading.value = false
                proxy.$emit('drawerClose')
              },
              () => {
              }
          )

        } else {
          loading.value = false
          proxy.$message.error(r.msg)
        }
      })
    }
    return {
      areaData,
      config_salary,
      config_education,
      config_experience,
      config_type,
      duty_editor,
      demand_editor,
      loading,
      formRef,
      formData,
      formRules,
      submitForm,
    }
  }
})
</script>
