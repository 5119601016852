<template>
  <a-card :loading="loading" class="card-panel" style="padding: 30px;">
    <div v-if="tpl?.project" class="title-panel">
      <div class="title">项目信息</div>
    </div>
    <a-descriptions v-if="tpl?.project" :column="2">
      <a-descriptions-item label="项目名称">{{ tpl.project?.project_name }}</a-descriptions-item>
      <a-descriptions-item label="项目区域">{{ tpl.project?.prov }}{{ tpl.project?.city }}{{
          tpl.project?.district
        }}
      </a-descriptions-item>
      <a-descriptions-item label="详细地址">{{ tpl.project?.address }}</a-descriptions-item>
    </a-descriptions>
    <a-divider/>
    <div v-show="tpl" class="title-panel">
      <div class="title">房产信息</div>
    </div>
    <a-descriptions v-show="tpl" :column="2">
      <a-descriptions-item :span="2" label="标题">{{ tpl?.title }}</a-descriptions-item>
      <a-descriptions-item label="门牌号">{{ tpl?.house_code }}</a-descriptions-item>
      <a-descriptions-item label="户型">{{ tpl?.bedroom }}卧室，{{ tpl?.parlor }}客厅，{{ tpl?.galley }}厨房，{{
          tpl?.toilet
        }}卫生间
      </a-descriptions-item>
      <a-descriptions-item label="楼层">{{ tpl?.active_level }} / {{ tpl?.total_level }}</a-descriptions-item>
      <a-descriptions-item label="装修">{{ tpl?.renovation }}</a-descriptions-item>
      <a-descriptions-item label="建筑类型">{{ tpl?.house_type }}</a-descriptions-item>
      <a-descriptions-item label="电梯">{{ tpl?.elevator }}</a-descriptions-item>
      <a-descriptions-item label="建筑面积">{{ tpl?.house_area }}平方米</a-descriptions-item>
      <a-descriptions-item label="产权年限">{{ tpl?.property_right }}年</a-descriptions-item>
      <a-descriptions-item label="挂牌时间">{{ tpl?.listing_date }}</a-descriptions-item>
      <a-descriptions-item label="建筑朝向">{{ tpl?.house_toward }}</a-descriptions-item>
      <a-descriptions-item label="展示图">
        <div class="form-upload-area">
          <div v-for=" (item,index) in tpl?.images" :key="index" class="form-upload-item">
            <a-image :src="signImg(item)"/>
          </div>
        </div>
      </a-descriptions-item>
    </a-descriptions>
    <a-divider/>
    <div style="text-align: right">
      <a-button :loading="btnLoading" type="primary" @click="confirmBtn">我要锁单</a-button>
    </div>
  </a-card>
</template>

<script>
import {defineComponent, getCurrentInstance, onMounted, ref} from "vue"
import dayjs from "dayjs";

export default defineComponent({
  setup() {
    const loading = ref(true)
    const {proxy} = getCurrentInstance()
    const tpl = ref()
    const signImg = (item) => {
      return proxy.$oss.sign(item)
    }

    const btnLoading = ref(false)
    const confirmBtn = async () => {
      btnLoading.value = true
      proxy.$requests.setHouseLock({id: tpl.value.id}).then(r => {
        if (r.code === 0) {
          proxy.$message.success(r.msg, 1.2).then(
              () => {
                loading.value = false
                proxy.$router.replace('/house/lock')
              },
              () => {
              }
          )
        } else {
          proxy.$router.replace({path: '/tips', query: {code: 20061, msg: r.msg}})
        }
      })
    }

    /*----初始化数据----*/
    onMounted(async () => {
      const params = proxy.$route.params
      proxy.$requests.getHouseDetail(params).then(r => {
        loading.value = false
        if (r.code === 0) {
          if (r?.data?.status === 2) {
            tpl.value = r.data
            if (tpl.value?.elevator === 1) {
              tpl.value.elevator = '有'
            } else {
              tpl.value.elevator = '无'
            }
            if (tpl.value?.listing_date) {
              tpl.value.listing_date = dayjs(tpl.value.listing_date).format('YYYY-MM-DD')
            }
            if (tpl.value?.images) {
              tpl.value.images = tpl.value.images.split(',')
            }
          } else {
            proxy.$router.replace({path: '/tips', query: {code: 20062, msg: '您访问的房产信息已锁单或已下架！'}})
          }
        } else {
          proxy.$router.replace({path: '/tips', query: {code: 20061, msg: r.msg}})
        }
      })
    });
    return {
      loading,
      tpl,
      btnLoading,
      confirmBtn,
      signImg
    }
  },
})
</script>

<style scoped>
.form-upload-area {
  display: flex;
  flex-wrap: wrap;
}

.form-upload-item {
  width: 120px;
  height: 120px;
  margin-inline-end: 8px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.02);
  border: 1px dashed #d9d9d9;
  border-radius: 8px;
  overflow: hidden;
}

</style>
