import {createStore} from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import cookie from '@/plugins/cookie/cookie'
import session from '@/plugins/storage/session'
import user from "@/store/modules/user"
import token from "@/store/modules/token"
import system from "@/store/modules/system"
import aliyun from "@/store/modules/aliyun"
import job from "@/store/modules/job"
import auth from "@/store/modules/auth"
import pay from "@/store/modules/pay"

const modules = {user, token, system, aliyun, job, auth, pay}

//store持久化
const tokenPlugins = createPersistedState({
    key: 'v_ssid',
    paths: ['token'],
    storage: {
        getItem: key => cookie.get(key),
        setItem: (key, value) => cookie.set(key, value),
        removeItem: key => cookie.remove(key)
    }
})
const authPlugins = createPersistedState({
    key: 'v_auth',
    paths: ['auth'],
    storage: {
        getItem: key => cookie.get(key),
        setItem: (key, value) => cookie.set(key, value),
        removeItem: key => cookie.remove(key)
    }
})
const systemPlugins = createPersistedState({
    key: 'v_system',
    paths: ['system'],
    storage: {
        getItem: key => session.get(key),
        setItem: (key, value) => session.set(key, value),
        removeItem: key => session.remove(key)
    }
})
const aliPlugins = createPersistedState({
    key: 'ali_ssid',
    paths: ['aliyun'],
    storage: {
        getItem: key => cookie.get(key),
        setItem: (key, value) => cookie.set(key, value),
        removeItem: key => cookie.remove(key)
    }
})
const payPlugins = createPersistedState({
    key: 'pay_order',
    paths: ['pay'],
    storage: {
        getItem: key => session.get(key),
        setItem: (key, value) => session.set(key, value),
        removeItem: key => session.remove(key)
    }
})

const debug = process.env.NODE_ENV !== 'production'
export default createStore({
    modules: modules,
    strict: debug,
    plugins: [tokenPlugins, authPlugins, systemPlugins, aliPlugins, payPlugins]
})
