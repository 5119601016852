import api from "./api"

export default {
    install: (app) => {
        app.config.globalProperties.$requests = api
        app.config.globalProperties.$requests.config({
            web: 'https://www.jianjiangwang.com/mpi',
        })
    }
}
