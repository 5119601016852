import {createRouter, createWebHistory} from 'vue-router'
import store from '@/store'
import cookie from '@/plugins/cookie/cookie'
import tool from '@/plugins/tool/tool'
import Login from '@/views/login/Index'
import Forget from '@/views/forget/Index'
import Register from '@/views/register/Index'
import Tips from '@/views/pages/tips/Index'
import Pages from '@/views/pages/App'
import Home from '@/views/pages/home/Index'
import UserProfile from '@/views/pages/user/Profile'
import UserCertify from '@/views/pages/user/Certify'
import UserSafe from '@/views/pages/user/Safe'
import UserNotice from '@/views/pages/user/Notice'
import UserLog from '@/views/pages/user/Log'
import Demand from '@/views/pages/demand/Index'
import Supply from '@/views/pages/supply/Index'
import Album from '@/views/pages/album/Index'
import Video from '@/views/pages/video/Index'
import News from '@/views/pages/news/Index'
import Job from '@/views/pages/job/Index'
import Company from '@/views/pages/company/Index'
import Invest from '@/views/pages/invest/Index'
import InvestOverview from '@/views/pages/invest/InvestOverview'
import InvestSend from '@/views/pages/invest/InvestSend'
import InvestBuy from '@/views/pages/invest/InvestBuy'
import Order from '@/views/pages/order/Index'
import Pay from '@/views/pages/pay/Index'
import PayInvest from "@/views/pages/pay/PayInvest"
import PayHouse from "@/views/pages/pay/PayHouse"
import PayOverview from "@/views/pages/pay/PayOverview"
import PayOrder from "@/views/pages/pay/PayOrder"
import PayGateWay from "@/views/pages/pay/PayGateWay"
import House from '@/views/pages/house/Index'
import HouseOverview from '@/views/pages/house/HouseOverview'
import HouseLock from "@/views/pages/house/HouseLock";
import HouseNote from "@/views/pages/house/HouseNote";
import HouseProject from "@/views/pages/house/HouseProject";

const pages = [
    {path: '', component: Home},
    {path: '/tips', component: Tips},
    {path: '/certify', component: UserCertify},
    {path: '/profile', component: UserProfile},
    {path: '/safe', component: UserSafe},
    {path: '/notice', component: UserNotice},
    {path: '/log', component: UserLog},
    {path: '/order', component: Order},
    {path: '/demand', component: Demand, meta: {auth: ['isCertify']}},
    {path: '/supply', component: Supply, meta: {auth: ['isCertify']}},
    {path: '/album', component: Album, meta: {auth: ['isCertify', 'isCompany', 'allCompany']}},
    {path: '/video', component: Video, meta: {auth: ['isCertify', 'isCompany', 'allCompany']}},
    {path: '/news', component: News, meta: {auth: ['isCertify', 'isCompany', 'allCompany']}},
    {path: '/job', component: Job, meta: {auth: ['isCertify', 'isCompany', 'allCompany']}},
    {path: '/company', component: Company, meta: {auth: ['isCertify', 'isCompany']}},
    {
        path: '/invest', component: Invest, meta: {auth: ['isCertify']}, children: [
            {path: '', component: InvestOverview},
            {path: 'send', component: InvestSend},
            {path: 'buy', component: InvestBuy},
        ]
    },
    {
        path: '/house', component: House, meta: {auth: ['isCertify']}, children: [
            {path: '', component: HouseOverview},
            {path: 'lock', component: HouseLock},
            {path: 'note/:id/:sn', component: HouseNote},
            {path: 'project', component: HouseProject},
        ]
    },
    {
        path: '/pay', component: Pay, meta: {auth: ['isCertify']}, children: [
            {path: '', component: PayOverview},
            {path: 'order', component: PayOrder},
            {path: 'invest/:id/:sn', component: PayInvest},
            {path: 'house/:id/:sn', component: PayHouse},
            {path: 'gateway', component: PayGateWay},
        ]
    },
]
const routes = [
    {path: '/', component: Login},
    {path: '/login', component: Login},
    {path: '/forget', component: Forget},
    {path: '/register', component: Register},
    {path: '/home', component: Pages, children: pages},
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        // 始终滚动到顶部
        return {top: 0}
    },
})

router.beforeEach((to, from, next) => {
    if (to.matched.length === 0) {
        from.name ? next({name: from.name}) : next({path: '/tips', query: {code: 20051}})
    }
    let config = store.getters['system/getCookieConfig']
    let login = ['/', '/login', '/forget', '/register'] //登录/注册/找回密码

    if (login.indexOf(to.path) !== -1 && cookie.hasTokenCookie(config)) {
        next({path: '/home'})
        return false
    }
    if (login.indexOf(to.path) === -1) {
        if (!cookie.hasTokenCookie(config)) {
            tool.logoutClear()
            next({path: '/login'})
            return false
        } else {
            let arrAuth = to.meta.auth ? to.meta.auth : [], auth = {}
            if (arrAuth.length > 0 || to.path === '/home') {
                store.dispatch('auth/setAuth')
                auth = store.getters['auth/getAuth']
            }
            if (arrAuth.indexOf('isCertify') > -1 && !auth.certify) { //是否实名认证
                next({path: '/tips', query: {code: 20041}})
                return false
            }
            if (arrAuth.indexOf('isCompany') > -1 && !auth.company) { //是否企业实名认证
                next({path: '/tips', query: {code: 20043}})
                return false
            }
            if (arrAuth.indexOf('allCompany') > -1 && !auth.all_company) { //是否企业信息是否完善
                next({path: '/tips', query: {code: 20044}})
                return false
            }
        }
    }
    next()
})

export default router
