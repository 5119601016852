import api from "@/plugins/request/api"

const state = {
    salary: [],
    education: [],
    experience: [],
    type: [],
}
const mutations = {
    setSalary(state, data) {
        state.salary = data
    },
    setEducation(state, data) {
        state.education = data
    },
    setExperience(state, data) {
        state.experience = data
    },
    setType(state, data) {
        state.type = data
    },
}

const getters = {
    getSalary: state => {
        return state.salary
    },
    getEducation: state => {
        return state.education
    },
    getExperience: state => {
        return state.experience
    },
    getType: state => {
        return state.type
    }
}
const actions = {
    setJobConfig(context) {
        if (context.state.salary.length === 0 || context.state.education.length === 0 || context.state.experience.length === 0 || context.state.type.length === 0) {
            api.jobConfig().then(r => {
                if (r.code === 0) {
                    let d = r.data
                    context.commit('setSalary', d.salary)
                    context.commit('setEducation', d.education)
                    context.commit('setExperience', d.experience)
                    context.commit('setType', d.type)
                }
            })
        }
    },

}
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}

