<template>
  <a-card :loading="loading" class="card-panel" title="确认订单">
    <div class="group-buy">
      <div v-for="(item, index) in tpl.goods" :key="index" class="list-buy">
        <div v-if="item.icon" :style="{backgroundImage: 'url(' + $oss.sign(item.icon) + ')'}"
             class="goods-poster"></div>
        <a-descriptions :column="1" :contentStyle="{fontSize: '13px'}" :labelStyle="{fontSize: '13px'}" size="small"
                        style="flex: 1;">
          <a-descriptions-item><b style="color: #555;font-size: 15px;">{{ item.title }}</b></a-descriptions-item>
          <a-descriptions-item label="规格">{{ item.spec ? item.spec : '无' }}</a-descriptions-item>
          <a-descriptions-item label="数量">×{{ item.rows }}</a-descriptions-item>
          <a-descriptions-item label="价格">￥{{ item.price ? item.price / 100 : 0 }}</a-descriptions-item>
          <a-descriptions-item label="优惠">
            {{ item.coupon_price && item.coupon_price > 0 ? '￥' + item.coupon_price / 100 : '无优惠' }}
          </a-descriptions-item>
          <a-descriptions-item label="运费">
            {{ item.express_price && item.express_price > 0 ? '￥' + item.express_price / 100 : '免运费' }}
          </a-descriptions-item>
        </a-descriptions>
      </div>
    </div>
    <div class="buy-action">
      <div class="list-item">
        <span style="font-size: 20px;color: #555">合计： ￥{{ totalMoney > 0 ? totalMoney / 100 : 0 }}</span>
      </div>
      <div v-if="point.value>0" class="list-item">
        <a-checkbox v-model:checked="point.status">积分抵扣，可用积分：{{ point.value > 0 ? point.value / 100 : 0 }}</a-checkbox>
      </div>
      <div class="list-item" style="text-align: center;padding: 30px 0;">
        <a-radio-group v-model:value="payType" style="margin-left: 20px;">
          <a-radio :value="1">
            <div class="pay-type-group">
              <div class="pay-type-item pay-wx">
                <wechat-outlined/>
                微信支付
              </div>
            </div>
          </a-radio>
          <a-radio :value="2">
            <div class="pay-type-group">
              <div class="pay-type-item pay-zfb">
                <alipay-outlined/>
                支付宝支付
              </div>
            </div>
          </a-radio>
        </a-radio-group>
      </div>
      <div class="list-item" style="text-align: center">
        <a-button :loading="btnLoading" type="primary" @click="buyBtn">提交订单</a-button>
      </div>
    </div>
  </a-card>
  <a-modal v-model:visible="tipsModal.visible" :closable="false" :footer="null" :maskClosable="false" :width="420"
           style="top: calc(50vh - 100px)">
    <template #title>
      {{ tipsModal.title }}
    </template>
    <div class="pay-tips-panel">
      <div class="tips-content">
        <p>请您新开的网上支付页面完成支付，支付完成前请不要关闭该窗口 </p>
      </div>
      <div class="tips-action">
        <span @click="$router.replace('/order')">已完成付款</span>
        <span @click="$router.replace('/order')">付款遇到问题</span>
      </div>
    </div>
  </a-modal>
</template>

<script>
import {computed, defineComponent, getCurrentInstance, reactive, ref} from "vue"

export default defineComponent({
  setup() {
    const loading = ref(false)
    const btnLoading = ref(false)
    const tipsModal = ref({visible: false, title: '支付提示'})
    const {proxy} = getCurrentInstance()
    const point = reactive({status: false, value: 0})
    const acceptData = reactive({
      id: null,
      accept_name: null,
      accept_phone: null,
      accept_prov: null,
      accept_city: null,
      accept_addr: null
    })
    const payType = ref(null)
    const order = computed(() => proxy.$store.getters["pay/getOrder"])
    const tpl = ref()
    tpl.value = JSON.parse(JSON.stringify(order.value))
    const buyBtn = () => {
      // tpl.value.goods[0].coupon_price = 50
      if (totalMoney.value > 0 && !payType.value) {
        proxy.$message.error('请选择支付方式！')
        return false
      }
      let obj = tpl.value
      obj.total = totalMoney.value
      obj.pay_type = payType.value ? payType.value : 1
      if (obj.is_express > 0 && !acceptData.id) {
        proxy.$message.error('请确认收件信息！')
        return false
      }
      obj.express_id = obj.is_express > 0 ? acceptData.id : null
      obj.is_point = point.status ? 1 : 0

      proxy.$requests.createOrder(obj).then(r => {
        if (r.code === 0) {
          let d = r.data
          if (d.pay_state === 1) {
            tipsModal.value.visible = true
            const routerData = proxy.$router.resolve({path: '/pay/gateway', query: {htmlData: d.data}})
            window.open(routerData.href, '_blank')
          } else {
            proxy.$router.replace('/order')
          }
        } else {
          proxy.$message.error(r.msg)
        }
      })
    }
    const totalMoney = computed(() => {
      let total = 0
      for (let item of tpl.value.goods) {
        let coupon_price = item.coupon_price ? item.coupon_price : 0
        total += item.rows * item.price - coupon_price
      }
      if (point.status) {
        return total > point.value ? total - point.value : 0
      } else {
        return total
      }
    })
    /*查询积分*/
    proxy.$requests.pointGet().then(r => {
      console.log(r)
      if (r.code === 0) {
        point.value = r.data.point * 100
      }
    })
    return {
      loading,
      btnLoading,
      tipsModal,
      payType,
      point,
      tpl,
      buyBtn,
      totalMoney,
    }
  },
})
</script>

<style scoped>
.group-buy {
  margin-top: -30px;
  padding: 0 20px;
}

.list-buy {
  display: flex;
  padding: 30px 0;
  border-bottom: 1px dashed #e6e6e6;
}

.list-buy:last-child {
  border-bottom: 1px solid #e6e6e6;
}

.list-buy .goods-poster {
  background-color: #f2f2f2;
  border-radius: 4px;
  margin-right: 20px;
  width: 115px;
  height: 115px;
  overflow: hidden;
  background-size: contain;
}

.buy-action {
  padding: 20px;
}

.buy-action .list-item {
  padding: 5px 0;
  text-align: right;
}

.pay-type-group {
  position: relative;
  width: 180px;
  height: 60px;
  line-height: 60px;
}

.pay-type-group .pay-type-item {
  position: absolute;
  background-color: #fafafa;
  height: 60px;
  left: -30px;
  right: 0;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

.pay-type-group .pay-type-item span {
  font-size: 26px;
  margin-right: 20px;
}

.ant-radio-wrapper-checked .pay-wx {
  background-color: #01ce0d;
  color: #ffffff;
}

.ant-radio-wrapper-checked .pay-zfb {
  background-color: #1890ff;
  color: #ffffff;
}

.pay-tips-panel .tips-content {
  padding-bottom: 30px;
}

.pay-tips-panel .tips-action {
  display: flex;
  justify-content: space-around;
  font-size: 16px;
}

.pay-tips-panel .tips-action span {
  padding: 8px 16px;
  background-color: #eeeeee;
  color: #999999;
  border-radius: 2px;
  cursor: pointer;
}

.pay-tips-panel .tips-action span:first-child {
  background-color: var(--app-color-primary);
  color: #ffffff;
}
</style>
