<template>
  <a-card :bordered="false">
    <a-typography>
      <a-typography-title :level="4" style="font-weight: unset;color: #333;text-align: center;">{{ tpl.title }}</a-typography-title>
      <a-typography-paragraph>
        <pre class="pre-panel">{{ tpl.desc }}{{ tpl.desc }}{{ tpl.desc }}{{ tpl.desc }}{{ tpl.desc }}{{ tpl.desc }}{{ tpl.desc }}{{ tpl.desc }}{{ tpl.desc }}</pre>
      </a-typography-paragraph>
      <a-typography-text>
        <div class="article-panel" v-html="tpl.content"></div>
      </a-typography-text>
    </a-typography>
  </a-card>
</template>

<script>

import {defineComponent, getCurrentInstance, ref} from "vue"

export default defineComponent({
  emits: ['drawerClose'],
  props: ['drawerData'],
  setup() {
    const loading = ref(false)
    const {proxy} = getCurrentInstance()
    const tpl=ref()

    /*----初始化数据----*/
    if (proxy.$props.drawerData && Object.keys(proxy.$props.drawerData).length !== 0) {
      try {
        tpl.value = proxy.$props.drawerData
        tpl.value.content = proxy.$tools.replaceSrc(proxy.$props.drawerData.content, {
          video: {width: '640', height: '360'}
        })
      } catch (e) {
        proxy.$message.error('未知错误', 1.2).then(() => {
          proxy.$emit('drawerClose')
        }, () => {
        })
      }
    }
    return {
      loading,
      tpl,
    }
  }
})
</script>

<style scoped>

</style>
