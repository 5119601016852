import VideoPlayer from 'vue-video-player/src'
import video from "@/plugins/video/video"
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'
import 'video.js/dist/lang/zh-CN'

export default {
    install: (app) => {
        app.config.globalProperties.$video = video
        app.config.productionTip = false
        app.use(VideoPlayer)
    }
}
