<template>
  <SideNav :nav-data="navData"/>
  <div class="main-panel scrollbar">
    <a-card class="card-panel">
      <a-card :bordered="false">
        <div class="title-panel">
          <div class="title">{{ columnTitle }}</div>
        </div>
        <div class="text-grey" style="padding-bottom: 15px;">操作日志记录了您在本账号中进行的所有操作。防止异常操作的出现，便于您的核查。</div>
        <a-table :columns="tableCol" :dataSource="tableData" :loading="loading" :pagination="false" rowKey="id"/>
        <div class="pagination-wrap">
          <a-button :disabled="paginate.current<2" @click="prev">上一页</a-button>
          <a-button :disabled="paginate.current===Math.ceil(paginate.total / paginate.limit)" @click="next">下一页
          </a-button>
        </div>
      </a-card>
    </a-card>
  </div>

</template>

<script>
import {defineComponent, getCurrentInstance, reactive, ref} from "vue"
import SideNav from "@/components/nav/SideNav"

export default defineComponent({
  components: {SideNav},
  setup() {
    const loading = ref(false)
    const {proxy} = getCurrentInstance()
    const navData = proxy.$inits.userNav
    const columnTitle = ref('')
    navData.data.filter((item) => {
      if (item.link === proxy.$route.path) {
        return columnTitle.value = item.name
      }
    })
    const tableData = ref([])
    const tableCol = [
      {title: '登录IP', dataIndex: 'ip', width: '25%',},
      {title: '操作', dataIndex: 'behavior', width: '50%',},
      {title: '时间', dataIndex: 'create_time', width: '25%',},
    ]
    const paginate = reactive({
      total: 0,
      current: 1,
      limit: 8,
    })
    const prev = () => {
      paginate.current--;
      requestList();
    }
    const next = () => {
      paginate.current++;
      requestList();
    }
    const requestList = async () => {
      loading.value = !loading.value
      await proxy.$requests.logsList({page: paginate.current, limit: paginate.limit}).then(r => {
        loading.value = !loading.value
        if (r.code === 0) {
          tableData.value = r.data;
          paginate.total = r.count;
        } else {
          proxy.$message.error(r.msg)
        }
      })
    }
    requestList()
    return {
      loading,
      navData,
      columnTitle,
      tableData,
      tableCol,
      paginate,
      prev,
      next
    }
  },
})
</script>

<style scoped>
.pagination-wrap{
  padding: 30px 0 0 0;
  text-align: right;
}
.pagination-wrap button{
  margin-left: 20px;
}
</style>
