import api from "@/plugins/request/api"

const state = {
    auth: {certify: false, company: false, all_company: false,}
}
const mutations = {
    setAuth(state, data) {
        state.auth.certify = data.certify
        state.auth.company = data.company
        state.auth.all_company = data.all_company
    },
}

const getters = {
    getAuth: state => {
        return state.auth
    }
}
const actions = {
    setAuth(context) {
        if (!context.state.auth.certify || !context.state.auth.company || !context.state.auth.all_company) {
            api.memberAuth({}).then(r => {
                if (r.code === 0) {
                    context.commit('setAuth', r.data)
                }
            })
        }
    },

}
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}

