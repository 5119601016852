<template>
  <SideNav :nav-data="navData"/>
  <div class="main-panel scrollbar">
    <a-row :gutter="[20,20]">
      <a-col span="24">
        <a-card class="card-panel">
          <a-result title="该功能还在开发中!">
            <template #icon>
              <svg height="200" viewBox="0 0 200 200" width="200"
                   xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <defs>
                  <linearGradient id="linear-gradient" gradientUnits="objectBoundingBox" x1="-12.251" x2="-12.251" y1="257.548"
                                  y2="256.548">
                    <stop offset="0.03" stop-color="#92c1fc" stop-opacity="0.4"/>
                    <stop offset="0.25" stop-color="#92c1fc" stop-opacity="0.2"/>
                    <stop offset="0.8" stop-color="#187fc4" stop-opacity="0"/>
                  </linearGradient>
                  <linearGradient id="linear-gradient-2" x1="0.5" x2="0.5" xlink:href="#linear-gradient" y1="0" y2="1"/>
                  <linearGradient id="linear-gradient-4" gradientUnits="objectBoundingBox" x1="0.5" x2="0.5" y2="1">
                    <stop offset="0" stop-color="#bed2f8"/>
                    <stop offset="1" stop-color="#4587f2"/>
                  </linearGradient>
                  <linearGradient id="linear-gradient-5" gradientUnits="objectBoundingBox" x1="-5.661" x2="-5.661" y1="40.843"
                                  y2="41.843">
                    <stop offset="0.102" stop-color="#d9e5fd"/>
                    <stop offset="0.454" stop-color="#ebf2fa"/>
                  </linearGradient>
                  <linearGradient id="linear-gradient-6" gradientUnits="objectBoundingBox" x1="-3.781" x2="-3.781" y1="41.238"
                                  y2="42.238">
                    <stop offset="0" stop-color="#ebf2fa"/>
                    <stop offset="0.525" stop-color="#fdfeff"/>
                    <stop offset="1" stop-color="#e9effb"/>
                  </linearGradient>
                  <linearGradient id="linear-gradient-7" gradientUnits="objectBoundingBox" x1="-11.346" x2="-11.021" y1="121.579"
                                  y2="121.216">
                    <stop offset="0" stop-color="#c0d3f8"/>
                    <stop offset="1" stop-color="#91b6f6"/>
                  </linearGradient>
                  <linearGradient id="linear-gradient-8" gradientUnits="objectBoundingBox" x1="-9.05" x2="-9.05" y1="176.839"
                                  y2="177.839">
                    <stop offset="0.15" stop-color="#acc5ea"/>
                    <stop offset="1" stop-color="#ccdcf8"/>
                  </linearGradient>
                  <linearGradient id="linear-gradient-9" gradientUnits="objectBoundingBox" x2="1" y1="0.5" y2="0.5">
                    <stop offset="0" stop-color="#ff7f53"/>
                    <stop offset="0.994" stop-color="#ffb365"/>
                  </linearGradient>
                  <linearGradient id="linear-gradient-10" gradientUnits="objectBoundingBox" x1="0.5" x2="0.5" y1="1">
                    <stop offset="0" stop-color="#ffe6ca"/>
                    <stop offset="0.994" stop-color="#f2d8bd"/>
                  </linearGradient>
                </defs>
                <g id="暂无记录" transform="translate(-1603 -65)">
                  <rect id="矩形_4409" data-name="矩形 4409" fill="#828282" height="200" opacity="0"
                        transform="translate(1603 65)" width="200"/>
                  <g id="组_4796" data-name="组 4796" transform="translate(1515.704 -507)">
                    <path id="路径_9265" d="M95.758,584.364c1.127-1.884,3.621-3.309,8.958-2.3,0,0,8.5-14.954,21.826-1.36,0,0,7.939-1.859,9.98,4.053a5.144,5.144,0,0,1-3.111,6.446c-6.836,2.59-22.511,7.1-35.5.26A5.111,5.111,0,0,1,95.758,584.364Z"
                          data-name="路径 9265"
                          fill="url(#linear-gradient)" transform="translate(-0.103 -0.04)"/>
                    <path id="路径_9266" d="M211.942,621.736s19.077-31.608,37.967-14.588c2.431,2.431,8.229-3.179,12.9,0,1.5.56,10.287-9.165,19.077,4.863s-3.74,9.725-3.74,9.725Z"
                          data-name="路径 9266"
                          fill="url(#linear-gradient-2)" transform="translate(-1.652 -0.399)"/>
                    <path id="路径_9267" d="M213.575,582.278s9.993-16.557,19.887-7.642c1.274,1.274,4.311-1.665,6.76,0,.783.294,5.389-4.8,9.993,2.548s-1.96,5.095-1.96,5.095Z"
                          data-name="路径 9267"
                          fill="url(#linear-gradient-2)" transform="translate(-1.674)"/>
                    <path id="路径_9268" d="M215.905,742.483H125.438A12.54,12.54,0,0,1,112.9,729.942V606.194a12.54,12.54,0,0,1,12.541-12.541h90.467a12.541,12.541,0,0,1,12.542,12.541V729.942A12.54,12.54,0,0,1,215.905,742.483Z"
                          data-name="路径 9268"
                          fill="#4084f2" transform="translate(-0.601 -0.653)"/>
                    <rect id="矩形_4421" data-name="矩形 4421" fill="url(#linear-gradient-4)" height="135" rx="8"
                          transform="translate(117.296 600)" width="104"/>
                    <path id="路径_9270" d="M210.33,727.458h-82.82a1.767,1.767,0,0,1-1.767-1.768V607.076a1.767,1.767,0,0,1,1.767-1.768h82.82a1.768,1.768,0,0,1,1.767,1.768V725.69A1.772,1.772,0,0,1,210.33,727.458Z"
                          data-name="路径 9270"
                          fill="url(#linear-gradient-5)" transform="translate(-0.51 -0.442)"/>
                    <path id="路径_9271" d="M164.614,726.326c-7.734-.523-50.274-3.737-76.615-24.978a1.9,1.9,0,0,1,.977-3.366c40.882-3.687,35.9-76.561,36.191-90.535a2,2,0,0,1,1.965-2.127H209.68a1.9,1.9,0,0,1,1.9,1.9l-4.323,78.258a6.594,6.594,0,0,1-.761,1.616L175.172,724.8C174.783,725.259,165.212,726.368,164.614,726.326Z"
                          data-name="路径 9271"
                          fill="url(#linear-gradient-6)" transform="translate(0 -0.442)"/>
                    <path id="路径_9272" d="M177.088,699.836c4.766.3,18.966.409,31.171-13.277-2.892,14.4-21.465,40.443-45.6,40.62,13.867-7.14,13.337-20.359,12.9-26.288C175.509,700.195,176.4,699.79,177.088,699.836Z"
                          data-name="路径 9272"
                          fill="url(#linear-gradient-7)" transform="translate(-0.999 -1.519)"/>
                    <path id="路径_9273" d="M138.955,630.39a20.91,20.91,0,0,1,0,7.79h6.78a14.944,14.944,0,0,0-.031-7.938C140.988,630.208,138.955,630.39,138.955,630.39Z"
                          data-name="路径 9273"
                          fill="#74a0fa" transform="translate(-0.685 -0.772)"/>
                    <path id="路径_9274" d="M203.832,638.145H153.967a43.39,43.39,0,0,0,0-8.052h49.865Z"
                          data-name="路径 9274"
                          fill="#74a0fa" transform="translate(-0.884 -0.77)"/>
                    <path id="路径_9275" d="M138.145,654.092a20.915,20.915,0,0,1,0,7.791h6.78a14.942,14.942,0,0,0-.03-7.939C140.175,653.906,138.145,654.092,138.145,654.092Z"
                          data-name="路径 9275"
                          fill="#74a0fa" transform="translate(-0.674 -1.086)"/>
                    <path id="路径_9276" d="M203.019,661.847H153.158a43.493,43.493,0,0,0,0-8.052h49.865v8.052Z"
                          data-name="路径 9276"
                          fill="#74a0fa" transform="translate(-0.873 -1.084)"/>
                    <path id="路径_9277" d="M136.406,679.357a16.477,16.477,0,0,1-2.949,7.73c3.6,0,7.73.144,7.73.144a9.989,9.989,0,0,0,2.419-7.938C138.885,679.258,136.406,679.357,136.406,679.357Z"
                          data-name="路径 9277"
                          fill="#74a0fa" transform="translate(-0.612 -1.422)"/>
                    <path id="路径_9278" d="M202.584,687.2H150.315a16.426,16.426,0,0,0,1.2-8.052h51.065V687.2Z"
                          data-name="路径 9278"
                          fill="#74a0fa" transform="translate(-0.835 -1.42)"/>
                    <path id="路径_9279" d="M193,590.784h-7.583a2.182,2.182,0,0,1-2.2-2.17h0a3.791,3.791,0,0,0-3.813-3.766H159.473a3.788,3.788,0,0,0-3.812,3.766h0a2.185,2.185,0,0,1-2.2,2.17h-7.582a4.937,4.937,0,0,0-4.967,4.9v13.568a4.935,4.935,0,0,0,4.967,4.9H193a4.939,4.939,0,0,0,4.967-4.907V595.682A4.944,4.944,0,0,0,193,590.784Z"
                          data-name="路径 9279"
                          fill="#afb0e7" transform="translate(-0.711 -0.17)"/>
                    <path id="路径_9280" d="M191.68,589.948h-7.4a2.142,2.142,0,0,1-2.142-2.142h0a3.721,3.721,0,0,0-3.722-3.721H158.949a3.721,3.721,0,0,0-3.721,3.721h0a2.142,2.142,0,0,1-2.142,2.142H145.68a4.85,4.85,0,0,0-4.849,4.85v13.413a4.85,4.85,0,0,0,4.849,4.85h46.008a4.851,4.851,0,0,0,4.85-4.85V594.8A4.862,4.862,0,0,0,191.68,589.948Z"
                          data-name="路径 9280"
                          fill="url(#linear-gradient-8)" transform="translate(-0.71 -0.16)"/>
                    <g id="组_4795" data-name="组 4795" transform="translate(211.469 625.284)">
                      <path id="路径_9281" d="M245.057,750.888s.61,4.266,1.219,3.961,2.133.3,2.133-.914-.3-3.352-1.828-3.656S245.057,750.888,245.057,750.888Z"
                            data-name="路径 9281"
                            fill="#eaccb0" transform="translate(-213.56 -627.647)"/>
                      <path id="路径_9282" d="M253.7,753.042s-.61,5.484,0,5.789,3.656.61,3.656-2.132a19.687,19.687,0,0,0-.609-4.875Z"
                            data-name="路径 9282"
                            fill="#eaccb0" transform="translate(-213.671 -627.668)"/>
                      <path id="路径_9283" d="M245.312,752.859s-.96-1.041-.96-.431-5.789,6.7-6.4,7.617.3,3.352,3.656,2.437,9.75-7.007,9.75-7.617-1.22-4.57-3.047-3.047S245.312,752.859,245.312,752.859Z"
                            data-name="路径 9283"
                            fill="#13274f" transform="translate(-213.464 -627.663)"/>
                      <path id="路径_9284" d="M253.357,755.917s-.683-.7-.683-.391-7.618,7.007-7.618,7.312,0,4.266,5.179,3.047,9.141-8.836,9.141-8.836-.087-2.244-2.177-1.579A9.944,9.944,0,0,1,253.357,755.917Z"
                            data-name="路径 9284"
                            fill="#13274f" transform="translate(-213.56 -627.715)"/>
                      <path id="路径_9285" d="M238.279,689.676s-1.863,31.154-1.558,35.724,6.7,24.678,7.312,24.983a7.392,7.392,0,0,0,3.656,0c.915-.3-2.742-22.546-2.437-26.2s.3-19.5,3.047-21.023,2.132,1.219,2.132,2.132-.3,21.327,0,25.288.915,21.023,1.524,21.936,4.571.61,5.484,0,1.219-40.217,2.742-44.787,1.524-16.148-1.524-17.062-17.817-2.286-19.877-.991"
                            data-name="路径 9285"
                            fill="#5f91ed" transform="translate(-213.449 -626.837)"/>
                      <path id="路径_9286" d="M251.5,650.325a20.591,20.591,0,0,1,5.027.666,8.837,8.837,0,0,1,4.57,2.6s.3,33.514-.914,37.171-22.9,2.441-23.46-4.266c-.3-3.656,1.219-15.484,1.219-18.89,0-1.827,4.57-5.788,4.57-16.452C242.509,649.934,251.5,650.325,251.5,650.325Z"
                            data-name="路径 9286"
                            fill="url(#linear-gradient-9)" transform="translate(-213.449 -626.322)"/>
                      <path id="路径_9287" d="M245.092,650.393s-5.017.141-6.094,1.219c-1.524,1.524-4.418,14.168-7.465,13.253-2.639-.791-7.1-7.523-8.254-9.314a1.087,1.087,0,0,0-.8-.494c-1.712-.175-7.083-.607-8.612.668-1.828,1.523.3,2.742.914,3.047s4.875-.61,5.484,0,7.473,14.759,12.8,13.406C242.045,669.892,244.177,654.963,245.092,650.393Z"
                            data-name="路径 9287"
                            fill="#ffe6cc" transform="translate(-213.137 -626.323)"/>
                      <path id="路径_9288" d="M287.9,658.78c-1.533.766-4.23-1.2-5.583.137a3.168,3.168,0,0,0-.6.979,41.162,41.162,0,0,1-4.857,7.627c-1.475,1.927-3.524,4.717-6.233,4.7a4.751,4.751,0,0,1-3.012-1.3c-1.151-.913-2.345-1.772-3.5-2.684a31.53,31.53,0,0,1-3.164-2.875c-1.5-1.563-3.312-3.509-3.867-5.675a22.01,22.01,0,0,1-.2-8.665s1.922.7,2.076.775a7.437,7.437,0,0,1,2.079,1.507,34.525,34.525,0,0,1,3.709,5.173c.608.9,1.23,1.788,1.907,2.636.6.757,1.417,1.384,1.994,2.142,1.128,1.481,2.582,2.075,4.135.824,2.59-2.088,4.112-5.194,6.384-7.575,1.528-1.6,3.853-1.751,5.969-1.633,1.165.065,2.719.063,3.672.856C290.643,657.256,288.511,658.475,287.9,658.78Z"
                            data-name="路径 9288"
                            fill="#ffe6cc" transform="translate(-213.713 -626.332)"/>
                      <ellipse id="椭圆_250" cx="3.395" cy="1.828" data-name="椭圆 250" fill="#ffe6cc" rx="3.395"
                               ry="1.828" transform="translate(32.52 24.983)"/>
                      <rect id="矩形_4420" data-name="矩形 4420" fill="url(#linear-gradient-10)" height="9.75" rx="2.584"
                            transform="translate(33.173 18.585)" width="5.484"/>
                      <path id="路径_9289" d="M262.222,629.656A3.818,3.818,0,0,0,258.261,626a3.932,3.932,0,0,0-3.794,2.61c-10.854-4.506-13.157,7.3-13.157,7.3,11.392-1.036,13.759,5.178,14.055,6.065s.739,2.515.739,2.515c3.071-5.118,3.321-8.717,2.541-11.193A3.782,3.782,0,0,0,262.222,629.656Z"
                            data-name="路径 9289"
                            fill="#0f244d" transform="translate(-213.511 -626)"/>
                      <path id="路径_9290" d="M242.583,635.726s-2.132,10.359,5.179,13.1c1.828.61,6.655-3.046,7.593-7.007C255.356,641.82,253.552,635.726,242.583,635.726Z"
                            data-name="路径 9290"
                            fill="#f9e5d4" transform="translate(-213.524 -626.129)"/>
                      <ellipse id="椭圆_251" cx="1.371" cy="1.523" data-name="椭圆 251" fill="#f9e5d4" rx="1.371"
                               ry="1.523" transform="translate(39.571 12.796)"/>
                    </g>
                  </g>
                </g>
              </svg>
            </template>
            <template #extra>
              <a-button type="primary" @click="$router.push('/home')">返回主页</a-button>
            </template>
          </a-result>
        </a-card>
      </a-col>
      <a-col span="24">
        <a-card class="card-panel">
        </a-card>
      </a-col>
    </a-row>
  </div>

</template>

<script>
import {defineComponent, getCurrentInstance, ref} from "vue"
import SideNav from "@/components/nav/SideNav"

export default defineComponent({
  components: {SideNav},
  setup() {
    const loading = ref(false)
    const {proxy} = getCurrentInstance()
    const navData = proxy.$inits.businessNav
    return {
      loading,
      navData,
    }
  },
})
</script>

<style scoped>

</style>
