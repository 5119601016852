<template>
  <SideNav :nav-data="navData"/>
  <div class="main-panel scrollbar">
    <a-card class="card-panel">
      <a-card :bordered="false">
        <div class="title-panel">
          <div class="title">{{ columnTitle }}</div>
          <div class="sub-title text-primary cursor" @click="onEditBtn">编辑</div>
        </div>
        <div class="text-center" style="padding: 30px 0;">
          <a-avatar v-if="tpl.avatar" :size="128" :src="$oss.sign(tpl.avatar)"/>
          <a-avatar v-else :size="128">
            <template #icon>
              <UserOutlined/>
            </template>
          </a-avatar>
        </div>
        <a-descriptions>
          <a-descriptions-item label="昵称">{{ tpl.nickname }}</a-descriptions-item>
          <a-descriptions-item label="性别">{{ tpl.sex === 1 ? '男' : '女' }}</a-descriptions-item>
          <a-descriptions-item label="手机号">{{ tpl.phone }}</a-descriptions-item>
          <a-descriptions-item label="账户ID">{{ tpl.sn }}</a-descriptions-item>
          <a-descriptions-item label="实名认证">
            <span v-if="tpl.verify === 1">已完成</span>
            <span v-else-if="tpl.verify === 2">待认证</span>
            <span v-else-if="tpl.verify === 3">待审核</span>
            <span v-else-if="tpl.verify === 4" class="text-error">审核失败</span>
            <span v-else>其他</span>
          </a-descriptions-item>
          <a-descriptions-item label="账户类型">{{ tpl.account_type === 1 ? '个人' : '企业' }}</a-descriptions-item>
          <a-descriptions-item label="电子邮箱">
            <span v-if="tpl.mail">{{ tpl.mail }}</span>
            <span v-else class="text-grey">待完善</span>
          </a-descriptions-item>
          <a-descriptions-item label="现居住地">
            <span v-if="tpl.prov">{{ tpl.prov }}{{ tpl.city }}{{ tpl.address }}</span>
            <span v-else class="text-grey">待完善</span>
          </a-descriptions-item>
        </a-descriptions>
      </a-card>
      <a-card v-if="alertData.status" :bordered="false">
        <router-link v-if="tpl.verify !== 1" to="/certify">
          <a-alert type="warning">
            <template #description>
              <p>
                <b>提示：</b>{{ alertData.text }}
              </p>
            </template>
          </a-alert>
        </router-link>
      </a-card>
      <a-card v-if="tpl.certify_id && tpl.account_type === 1 && tpl.verify===1" :bordered="false">
        <div class="title-panel">
          <div class="title">个人信息</div>
          <div class="sub-title">
            <router-link class="text-primary" to="/certify">升级至企业用户</router-link>
          </div>
        </div>
        <a-descriptions :column="1">
          <a-descriptions-item label="证件类型">身份证</a-descriptions-item>
          <a-descriptions-item label="姓名">{{ certifyData.name }}</a-descriptions-item>
          <a-descriptions-item label="身份证">{{ certifyData.id_card }}</a-descriptions-item>
        </a-descriptions>
      </a-card>
      <a-card v-if="tpl.certify_id && tpl.account_type === 2 && tpl.verify===1" :bordered="false">
        <div class="title-panel">
          <div class="title">企业信息</div>
          <div class="sub-title">
            <router-link class="text-primary" to="/certify">重新认证</router-link>
          </div>
        </div>
        <a-descriptions :column="1">
          <a-descriptions-item label="企业法人">{{ certifyData.legal_person }}</a-descriptions-item>
          <a-descriptions-item label="企业名称">{{ certifyData.company_name }}</a-descriptions-item>
          <a-descriptions-item label="社会信用代码">{{ certifyData.social_code }}</a-descriptions-item>
        </a-descriptions>
      </a-card>
    </a-card>
    <a-drawer v-model:visible="drawerData.visible" :bodyStyle="{padding:0}" :closable="false" :destroyOnClose="true"
              :keyboard="false" :maskClosable="false" :width="drawerData.width" placement="right">
      <div class="layer-panel">
        <div class="layer-title">
          <div class="title">{{ drawerData.title }}</div>
          <div class="icon" @click="drawerClose">
            <close-outlined/>
          </div>
        </div>
        <div class="layer-body bg-white">
          <a-spin :spinning="loading" tip="请稍等...">
            <a-card :bordered="false">
              <a-form ref="formRef" :model="formData" :rules="formRules" layout="vertical">
                <a-form-item label="头像" name="avatar" style="min-height: 118px;">
                  <a-upload v-model:file-list="fileList" :before-upload="beforeUpload" list-type="picture-card"
                            @change="uploadChange" @preview="handlePreview">
                    <div v-if="fileList.length < 1">
                      <plus-outlined/>
                      <div class="ant-upload-text">上传</div>
                    </div>
                  </a-upload>
                  <a-modal :footer="null" :visible="previewVisible" @cancel="cancelPreview">
                    <img :src="previewImage" alt="example" style="width: 100%"/>
                  </a-modal>
                </a-form-item>
                <a-form-item label="昵称" name="nickname">
                  <a-input v-model:value="formData.nickname" placeholder="不超过10个字符"/>
                </a-form-item>
                <a-form-item label="性别" name="sex">
                  <a-radio-group v-model:value="formData.sex">
                    <a-radio value="1">男</a-radio>
                    <a-radio value="2">女</a-radio>
                  </a-radio-group>
                </a-form-item>
                <a-form-item label="电子邮箱" name="mail">
                  <a-input v-model:value="formData.mail" placeholder="请输入邮箱"/>
                </a-form-item>
                <a-form-item label="选择省市" name="area">
                  <a-cascader v-model:value="formData.area" :field-names="{ label: 'name', value: 'name' }"
                              :options="areaData"
                              placeholder="请选择"/>
                </a-form-item>
                <a-form-item label="详细地址" name="address">
                  <a-input v-model:value="formData.address" placeholder="不超过32个字符"/>
                </a-form-item>
                <a-form-item class="text-center">
                  <a-button :loading="loading" type="primary" @click="submitForm">提 交</a-button>
                </a-form-item>
              </a-form>
            </a-card>
          </a-spin>
        </div>
      </div>
    </a-drawer>
  </div>

</template>

<script>
import {computed, defineComponent, getCurrentInstance, reactive, ref, toRaw} from "vue"
import SideNav from "@/components/nav/SideNav"

export default defineComponent({
  components: {SideNav},
  setup() {
    const loading = ref(false)
    const alertData = reactive({status: false, text: '未知错误'})
    const {proxy} = getCurrentInstance()
    const navData = proxy.$inits.userNav
    const columnTitle = ref('')
    navData.data.filter((item) => {
      if (item.link === proxy.$route.path) {
        return columnTitle.value = item.name
      }
    })
    proxy.$store.dispatch('user/setProfile')
    const tpl = computed(() => proxy.$store.getters["user/getProfile"])
    const certifyData = ref([])
    proxy.$requests.memberCertify().then(r => {
      if (r.code === 0) {
        certifyData.value = r.data.certifyRelation
        proxy.$store.commit("user/updateProfile", {verify: r.data.verify, account_type: r.data.account_type})

        if (r.data.verify !== 1) {
          alertData.status = true
          alertData.text = '实名认证失败，点击查看！'
          if (r.data.verify === 2) {
            alertData.text = '您尚未进行实名认证，点击前去认证！'
          }
          if (r.data.verify === 3) {
            alertData.text = '您已完善实名认证，系统审核中！'
          }
        }
      }
    })
    const areaData = proxy.$tools.fmtArea(proxy.$inits.area,2)
    const formRef = ref();
    const formData = reactive({nickname: '', avatar: '', sex: '', mail: '', area: [], address: ''})
    //验证上传文件
    let validFile = (_rule, value) => {
      if (value === '' || !value) {
        return Promise.reject('头像必须上传！')
      }
      if (value instanceof File) {
        if (value.type !== 'image/jpeg' && value.type !== 'image/png') {
          return Promise.reject('上传图片格式(jpg/jpeg/png)')
        }
      }
      return Promise.resolve()
    }
    const formRules = reactive({
      avatar: [
        {validator: validFile, required: true, trigger: 'change',},
      ],
      nickname: [
        {required: true, message: '昵称必须填写！', trigger: 'change',},
        {max: 10, message: '昵称不超过10个字符！', trigger: 'blur',}
      ],
      sex: [
        {required: true, message: '性别必须选择！', trigger: 'change',}
      ],
      mail: [
        {required: true, message: '电子邮箱必须填写！', trigger: 'change',},
        {pattern: proxy.$inits.regex.mail, message: '电子邮箱格式错误！', trigger: 'blur',}
      ],
      area: [
        {required: true, type: 'array', message: '省市区域必须选择！', trigger: 'change',}
      ],
      address: [
        {required: true, message: '详细地址必须填写！', trigger: 'change',},
        {max: 32, message: '详细地址不超过32个字符！', trigger: 'blur',}
      ],
    });
    const initDrawer = () => ({visible: false, title: '', width: '800',})
    const drawerData = reactive(initDrawer())
    const drawerClose = async () => {
      Object.assign(drawerData, initDrawer())
    }
    const onEditBtn = () => {
      drawerData.title = '编辑'
      drawerData.visible = true
      formData.nickname = tpl.value.nickname
      formData.avatar = tpl.value.avatar
      formData.sex = tpl.value.sex ? tpl.value.sex.toString() : '1'
      formData.mail = tpl.value.mail
      formData.area = tpl.value.prov && tpl.value.city ? [tpl.value.prov, tpl.value.city] : []
      formData.address = tpl.value.address

      proxy.$store.dispatch('aliyun/setStsConfig')//上传操作前，检测STS
      if (tpl.value.avatar) {
        fileList.value = [
          proxy.$tools.setFileList(1, tpl.value.avatar, proxy.$oss.sign(tpl.value.avatar))
        ]
      }
    }
    /*-----上传----*/
    const previewVisible = ref(false)
    const previewImage = ref('')
    const fileList = ref([])
    //取消预览
    const cancelPreview = () => {
      previewVisible.value = false
    }
    //预览图片
    const handlePreview = async file => {
      previewImage.value = file.thumbUrl
      previewVisible.value = true
    }
    const beforeUpload = () => {
      return false
    }
    const handleChange = ({fileList: newFileList}) => {
      fileList.value = newFileList
    }
    const uploadChange = async (r) => {
      if (r.fileList.length > 0) {
        let fileData = r.file
        if (fileData.type === 'image/jpeg' || fileData.type === 'image/png') {
          let isSize = fileData.size / 1024 / 1024 < 0.2, baseFile = await proxy.$tools.getBase64(fileData)
          if (!isSize) {
            let obj = await proxy.$img.imgCompress(baseFile, {width: 200, quality: 0.75})
            baseFile = obj.img
            let d = proxy.$img.dataURLtoFile(baseFile, 'icon.jpg')
            fileList.value = [{
              lastModified: d.lastModified,
              lastModifiedDate: d.lastModifiedDate,
              originFileObj: d,
              percent: 0,
              size: d.size,
              thumbUrl: baseFile,
              type: d.type,
              name: fileData.name,
              uid: fileData.uid,
            }]
            formData.avatar = d
          } else {
            formData.avatar = fileData
          }
        } else {
          fileList.value = []
        }
        formRef.value.validate('avatar')
      } else {
        fileList.value = []
        formData.avatar = ''
      }
    }
    //提交表单
    const submitForm = () => {
      loading.value = true
      formRef.value.validate().then(async () => {
        if (formData.avatar instanceof File) {
          let poster_file = formData.avatar
          await proxy.$oss.upload(
              'avatar',
              proxy.$tools.makeFileName() + proxy.$tools.getFileExt(poster_file.name),
              poster_file
          ).then(r => {
            if (r && r.res.statusCode === 200) {
              formData.avatar = r.name
            } else {
              proxy.$message.error('上传中断，请刷新页面后重试！')
              loading.value = false
              return false
            }
          })
        }
        if (!formData.avatar) {
          proxy.$message.error('头像数据丢失！')
          loading.value = false
          return false
        }
        requestAction(toRaw(formData))
      }).catch(() => {
        loading.value = false
      })
    }
    const requestAction = obj => {
      proxy.$requests.editProfile(obj).then(r => {
        if (r.code === 0) {
          proxy.$message.success(r.msg, 2).then(
              () => {
                loading.value = false
                drawerClose()
                proxy.$store.commit('user/setProfile', [])
                proxy.$store.dispatch('user/setProfile')
              },
              () => {
              }
          )
        } else {
          loading.value = false
          proxy.$message.error(r.msg)
        }
      })
    }
    return {
      loading,
      alertData,
      navData,
      columnTitle,
      userInfoNav: proxy.$inits.userInfoNav,
      tpl,
      certifyData,
      drawerData,
      drawerClose,
      onEditBtn,
      areaData,
      formRef,
      formData,
      formRules,
      fileList,
      previewImage,
      previewVisible,
      cancelPreview,
      handlePreview,
      beforeUpload,
      handleChange,
      uploadChange,
      submitForm,
    }
  },
})
</script>
