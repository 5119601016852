<template>
  <SideNav :nav-data="navData"/>
  <div class="main-panel scrollbar">
    <a-card class="card-panel">
      <a-card :bordered="false">
        <div class="title-panel">
          <div class="title">{{ columnTitle }}</div>
        </div>
        <a-spin :spinning="loading" tip="请稍等...">
          <a-form ref="formRef" :model="formData" :rules="formRules" layout="vertical"
                  style="margin: 100px auto;width: 500px;">
            <a-form-item class="form-item" label="原始密码" name="pwd">
              <a-input v-model:value="formData.pwd" placeholder="请输入原始密码" type="password">
                <template #prefix>
                  <LockOutlined style="color: rgba(0, 0, 0, 0.25)"/>
                </template>
              </a-input>
            </a-form-item>
            <a-form-item class="form-item" label="新密码" name="new_pwd">
              <a-input v-model:value="formData.new_pwd" placeholder="请输入新密码,只能由英文、数字、下划线组成"
                       type="password">
                <template #prefix>
                  <LockOutlined style="color: rgba(0, 0, 0, 0.25)"/>
                </template>
              </a-input>
            </a-form-item>
            <a-form-item class="form-item" label="确认密码" name="check_pwd">
              <a-input v-model:value="formData.check_pwd" placeholder="请再输一次新密码" type="password">
                <template #prefix>
                  <LockOutlined style="color: rgba(0, 0, 0, 0.25);font-size: 14px"/>
                </template>
              </a-input>
            </a-form-item>
            <div class="form-item text-center">
              <a-button :loading="loading" style="width: 180px" type="primary" @click="submitForm">提 交</a-button>
            </div>
          </a-form>
        </a-spin>
      </a-card>
    </a-card>
  </div>

</template>

<script>
import {defineComponent, getCurrentInstance, reactive, ref, toRaw} from "vue"
import SideNav from "@/components/nav/SideNav"

export default defineComponent({
  components: {SideNav},
  setup() {
    const loading = ref(false)
    const {proxy} = getCurrentInstance()
    const navData = proxy.$inits.userNav
    const columnTitle = ref('')
    navData.data.filter((item) => {
      if (item.link === proxy.$route.path) {
        return columnTitle.value = item.name
      }
    })
    let formId = proxy.$tools.randomStr()//表单ID
    const formRef = ref()

    const formData = reactive({pwd: '', new_pwd: '', check_pwd: ''})
    let validatePwd = async (rule, value) => {
      if (value === '') {
        return Promise.reject('请输入新密码！')
      } else {
        if (formData.check_pwd !== '') {
          formRef.value.validate('check_pwd')
        }
        return Promise.resolve()
      }
    }
    let validatePwd2 = async (rule, value) => {
      if (value === '') {
        return Promise.reject('请再次输入新密码！')
      } else if (value !== formData.new_pwd) {
        return Promise.reject("两次输入的新密码不一致!")
      } else {
        return Promise.resolve()
      }
    }
    const formRules = reactive({
      pwd: [
        {required: true, message: '请输入原始密码', trigger: 'blur',},
      ],
      new_pwd: [
        {validator: validatePwd, required: true, trigger: 'change',},
        {min: 5, max: 12, message: '密码长度5-12位！', trigger: 'blur',},
        {pattern: proxy.$inits.regex.password, message: '密码只能英文、数字、下划线组成！', trigger: 'blur',}
      ],
      check_pwd: [
        {validator: validatePwd2, required: true, trigger: 'blur',},
      ],
    })
    //表单提交
    const submitForm = () => {
      loading.value = true
      formRef.value.validate().then(() => {
        let data = toRaw(formData)
        proxy.$requests.userForget({
          pwd: proxy.$md5.make(data.pwd),
          new_pwd: proxy.$md5.make(data.new_pwd),
          check_pwd: proxy.$md5.make(data.check_pwd),
          form_id: formId,
        }).then(r => {
          if (r.code === 0) {
            formRef.value.resetFields() //重置表单
            proxy.$message.success(r.msg, 1.2).then(() => proxy.$router.replace('/login'), () => {
              loading.value = false
            })
          } else {
            loading.value = false
            if (data.pwd) {
              formRef.value.resetFields(['pwd', 'new_pwd', 'check_pwd']) //重置表单
            }
            proxy.$message.error(r.msg)
          }
        })
      }).catch(() => {
        loading.value = false
      })
    }
    return {
      loading,
      navData,
      columnTitle,
      formId: formId,
      userInfoNav: proxy.$inits.userInfoNav,
      formRef,
      formData,
      formRules,
      submitForm,
    }
  },
})
</script>

<style scoped>

</style>
