<template>
  <SideNav :nav-data="navData"/>
  <div class="main-panel scrollbar">
    <a-card class="card-panel">
      <a-card :bordered="false">
        <div class="title-panel">
          <div class="title">实名认证</div>
        </div>
        <div style="padding: 30px 0;width: 900px;margin: 0 auto;">
          <a-steps :current="steps.current">
            <a-step v-for="(item,index) in steps.data" :key="index">
              <template #title>{{ item.title }}</template>
            </a-step>
          </a-steps>
        </div>
        <div v-if="steps.current===0" class="step-panel">
          <div class="certify-type-panel">
            <div v-for="(item,index) in certifyType" :key="index" class="list-item">
              <a-skeleton :loading="loading" :paragraph="{ rows: 1 }" avatar>
                <div class="item-group" @click="selectType(item.value)">
                  <span class="icon-box"><component :is="item.icon"></component></span>
                  <span class="text-box">{{ item.name }}</span>
                </div>
              </a-skeleton>
            </div>
          </div>
        </div>
        <div v-else-if="steps.current===1" class="step-panel">
          <a-spin :spinning="loading" tip="请稍等...">
            <div v-if="alertData.state" style="padding: 30px 0;">
              <a-alert :after-close="alertClose" :message="alertData.text" closable show-icon type="error"/>
            </div>
            <a-form v-if="typeData===1" ref="formRef" :model="profileData" :rules="profileRules" class="form-panel"
                    layout="vertical">
              <a-form-item label="姓名" name="name">
                <a-input v-model:value="profileData.name" placeholder="请输入姓名" type="text"/>
              </a-form-item>
              <a-form-item label="身份证号码" name="id_card">
                <a-input v-model:value="profileData.id_card" placeholder="请输入身份证号码" type="text"/>
              </a-form-item>
              <a-form-item label="身份证（头像面）" name="card01" style="min-height: 118px;">
                <a-upload v-model:file-list="fileListCard01" :before-upload="beforeUpload"
                          list-type="picture-card" @change="uploadCard01Change" @preview="handlePreview">
                  <div v-if="fileListCard01.length < 1">
                    <plus-outlined/>
                    <div class="ant-upload-text">添加</div>
                  </div>
                </a-upload>
              </a-form-item>
              <a-form-item label="身份证（国徽面）" name="card02" style="min-height: 118px;">
                <a-upload v-model:file-list="fileListCard02" :before-upload="beforeUpload"
                          list-type="picture-card" @change="uploadCard02Change" @preview="handlePreview">
                  <div v-if="fileListCard02.length < 1">
                    <plus-outlined/>
                    <div class="ant-upload-text">添加</div>
                  </div>
                </a-upload>
              </a-form-item>
              <br>
              <div class="text-center">
                <a-button v-if="steps.current > 0&&steps.current <2" style="margin-right: 100px" @click="prevSteps">
                  上一步
                </a-button>
                <a-button :loading="loading" type="primary" @click="submitForm">提交</a-button>
              </div>
            </a-form>
            <a-form v-if="typeData===2" ref="formRef" :model="companyData" :rules="companyRules" class="form-panel"
                    layout="vertical">
              <a-form-item label="企业名称" name="company_name">
                <a-input v-model:value="companyData.company_name" placeholder="请输入企业名称" type="text"/>
              </a-form-item>
              <a-form-item label="社会信用代码" name="social_code">
                <a-input v-model:value="companyData.social_code" placeholder="请输入社会信用代码" type="text"/>
              </a-form-item>
              <a-form-item label="企业法人" name="legal_person">
                <a-input v-model:value="companyData.legal_person" placeholder="请输入企业法人" type="text"/>
              </a-form-item>
              <a-form-item label="营业执照（图片）" name="company_license" style="min-height: 118px;">
                <a-upload v-model:file-list="fileListLicense" :before-upload="beforeUpload"
                          list-type="picture-card" @change="uploadLicenseChange" @preview="handlePreview">
                  <div v-if="fileListLicense.length < 1">
                    <plus-outlined/>
                    <div class="ant-upload-text">添加</div>
                  </div>
                </a-upload>
              </a-form-item>
              <a-form-item label="法人授权书（图片）" name="auth_cert" style="min-height: 118px;">
                <a-upload v-model:file-list="fileListCert" :before-upload="beforeUpload"
                          list-type="picture-card" @change="uploadCertChange" @preview="handlePreview">
                  <div v-if="fileListCert.length < 1">
                    <plus-outlined/>
                    <div class="ant-upload-text">添加</div>
                  </div>
                </a-upload>
              </a-form-item>
              <br>
              <div class="text-center">
                <a-button v-if="steps.current > 0&&steps.current <2" style="margin-right: 100px" @click="prevSteps">
                  上一步
                </a-button>
                <a-button :loading="loading" type="primary" @click="submitForm">提交</a-button>
              </div>
            </a-form>
          </a-spin>
        </div>
        <div v-else-if="steps.current===2" class="step-panel">
          <a-result status="success" sub-title="您的实名认证资料已经提交成功，平台会在2-5个工作日内返回审核结果！" title="提交成功"/>
        </div>
        <div v-else-if="steps.current===3" class="step-panel">
          <a-result sub-title="您的实名认证资料还在审核中，平台会在2-5个工作日内返回审核结果！" title="实名认证审核中"/>
        </div>
        <div v-else class="step-panel">
          <a-result status="warning" sub-title="页面状态读取错误！" title="提示"/>
        </div>
      </a-card>
    </a-card>
  </div>

  <a-modal :footer="null" :visible="previewVisible" :width="600" @cancel="cancelPreview">
    <img :src="previewImage" alt="example" style="width: 100%;"/>
  </a-modal>
</template>

<script>
import {defineComponent, getCurrentInstance, reactive, ref, toRaw} from "vue"
import SideNav from "@/components/nav/SideNav"

export default defineComponent({
  components: {SideNav},
  setup() {
    const loading = ref(false)
    const {proxy} = getCurrentInstance()
    proxy.$store.dispatch('aliyun/setStsConfig')//上传操作前，检测STS
    const navData = proxy.$inits.userNav
    const steps = ref({current: 0, data: [{title: '选择类型'}, {title: '填写数据'}, {title: '返回结果'}]})
    const certifyType = ref([
      {icon: 'idCard', name: '个人认证', value: 1},
      {icon: 'Building', name: '企业认证', value: 2}
    ])
    const typeData = ref(0)
    const nextSteps = () => {
      steps.value.current++
    }
    const prevSteps = () => {
      steps.value.current--
    };
    const selectType = index => {
      typeData.value = index
      nextSteps()
    }
    const formRef = ref()
    /*----个人----*/
    const profileData = reactive({name: '', id_card: '', card01: '', card02: ''})
    const validCard01 = (_rule, value) => {
      if (value === '' || !value) {
        return Promise.reject('身份证（头像面）必须上传！')
      }
      return Promise.resolve()
    }
    const validCard02 = (_rule, value) => {
      if (value === '' || !value) {
        return Promise.reject('身份证（国徽面）必须上传！')
      }
      return Promise.resolve()
    }
    const profileRules = reactive({
      name: [
        {required: true, message: '姓名必须填写！', trigger: 'change',},
        {pattern: proxy.$inits.regex.chs, min: 2, max: 20, message: '请填写正确的中文姓名！', trigger: 'blur',}
      ],
      id_card: [
        {required: true, message: '身份证号码必须填写！', trigger: 'change',},
        {min: 18, max: 18, message: '身份证号码不正确！', trigger: 'blur',},
      ],
      card01: [
        {validator: validCard01, required: true, message: '身份证（头像面）必须上传！', trigger: 'blur',},
      ],
      card02: [
        {validator: validCard02, required: true, message: '身份证（国徽面）必须上传！', trigger: 'blur',},
      ],
    })
    /*----企业----*/
    const companyData = reactive({
      company_name: '',
      social_code: '',
      legal_person: '',
      company_license: '',
      auth_cert: ''
    })
    const validLicense = (_rule, value) => {
      if (value === '' || !value) {
        return Promise.reject('营业执照必须上传！')
      }
      return Promise.resolve()
    }
    const validCert = (_rule, value) => {
      if (value === '' || !value) {
        return Promise.reject('法人授权书必须上传！')
      }
      return Promise.resolve()
    }
    const companyRules = reactive({
      company_name: [
        {required: true, message: '企业名称必须填写！', trigger: 'change'},
        {max: 100, message: '企业名称不超过100个字符！', trigger: 'blur',}
      ],
      legal_person: [
        {required: true, message: '企业法人必须填写！', trigger: 'change',},
        {max: 50, message: '企业法人不超过50个字符！', trigger: 'blur',}
      ],
      social_code: [
        {required: true, message: '社会信用代码必须填写！', trigger: 'change',},
        {min: 18, max: 18, message: '社会信用代码错误！', trigger: 'blur',}
      ],
      company_license: [
        {validator: validLicense, required: true, message: '营业执照必须上传！', trigger: 'blur',},
      ],
      auth_cert: [
        {validator: validCert, required: true, message: '法人授权书必须上传！', trigger: 'blur',},
      ],
    })
    /*----上传----*/
    const previewVisible = ref(false)
    const previewImage = ref('')
    const fileListCard01 = ref([])
    const fileListCard02 = ref([])
    const fileListLicense = ref([])
    const fileListCert = ref([])
    const cancelPreview = () => {
      previewVisible.value = false
    }
    const handlePreview = async file => {
      if (!file.url && !file.preview) {
        file.preview = await proxy.$tools.getBase64(file.originFileObj)
      }

      previewImage.value = file.url || file.preview
      previewVisible.value = true
    }
    const beforeUpload = () => {
      return false
    }
    const uploadCard01Change = async (r) => {
      if (r.fileList.length > 0) {
        let fileData = r.file
        if (fileData.type === 'image/jpeg' || fileData.type === 'image/png') {
          let isSize = fileData.size / 1024 / 1024 < 1, baseFile = await proxy.$tools.getBase64(fileData)
          if (!isSize) {
            let obj = await proxy.$img.imgCompress(baseFile, {width: 1000})
            baseFile = obj.img
            let d = proxy.$img.dataURLtoFile(baseFile, 'icon.jpg')
            fileListCard01.value = [{
              lastModified: d.lastModified,
              lastModifiedDate: d.lastModifiedDate,
              originFileObj: d,
              percent: 0,
              size: d.size,
              thumbUrl: baseFile,
              type: d.type,
              name: fileData.name,
              uid: fileData.uid,
            }]
            profileData.card01 = d
          } else {
            profileData.card01 = fileData
          }
        } else {
          fileListCard01.value = []
        }
        formRef.value.validate('card01')
      } else {
        fileListCard01.value = []
        profileData.card01 = ''
      }
    }
    const uploadCard02Change = async (r) => {
      if (r.fileList.length > 0) {
        let fileData = r.file
        if (fileData.type === 'image/jpeg' || fileData.type === 'image/png') {
          let isSize = fileData.size / 1024 / 1024 < 1, baseFile = await proxy.$tools.getBase64(fileData)
          if (!isSize) {
            let obj = await proxy.$img.imgCompress(baseFile, {width: 1000})
            baseFile = obj.img
            let d = proxy.$img.dataURLtoFile(baseFile, 'icon.jpg')
            fileListCard02.value = [{
              lastModified: d.lastModified,
              lastModifiedDate: d.lastModifiedDate,
              originFileObj: d,
              percent: 0,
              size: d.size,
              thumbUrl: baseFile,
              type: d.type,
              name: fileData.name,
              uid: fileData.uid,
            }]
            profileData.card02 = d
          } else {
            profileData.card02 = fileData
          }
        } else {
          fileListCard02.value = []
        }
        formRef.value.validate('card02')
      } else {
        fileListCard02.value = []
        profileData.card02 = ''
      }
    }
    const uploadLicenseChange = async (r) => {
      if (r.fileList.length > 0) {
        let fileData = r.file
        if (fileData.type === 'image/jpeg' || fileData.type === 'image/png') {
          let isSize = fileData.size / 1024 / 1024 < 1, baseFile = await proxy.$tools.getBase64(fileData)
          if (!isSize) {
            let obj = await proxy.$img.imgCompress(baseFile, {width: 1000})
            baseFile = obj.img
            let d = proxy.$img.dataURLtoFile(baseFile, 'icon.jpg')
            fileListLicense.value = [{
              lastModified: d.lastModified,
              lastModifiedDate: d.lastModifiedDate,
              originFileObj: d,
              percent: 0,
              size: d.size,
              thumbUrl: baseFile,
              type: d.type,
              name: fileData.name,
              uid: fileData.uid,
            }]
            companyData.company_license = d
          } else {
            companyData.company_license = fileData
          }
        } else {
          fileListLicense.value = []
        }
        formRef.value.validate('company_license')
      } else {
        fileListLicense.value = []
        companyData.company_license = ''
      }
    }
    const uploadCertChange = async (r) => {
      if (r.fileList.length > 0) {
        let fileData = r.file
        if (fileData.type === 'image/jpeg' || fileData.type === 'image/png') {
          let isSize = fileData.size / 1024 / 1024 < 1, baseFile = await proxy.$tools.getBase64(fileData)
          if (!isSize) {
            let obj = await proxy.$img.imgCompress(baseFile, {width: 1000})
            baseFile = obj.img
            let d = proxy.$img.dataURLtoFile(baseFile, 'icon.jpg')
            fileListCert.value = [{
              lastModified: d.lastModified,
              lastModifiedDate: d.lastModifiedDate,
              originFileObj: d,
              percent: 0,
              size: d.size,
              thumbUrl: baseFile,
              type: d.type,
              name: fileData.name,
              uid: fileData.uid,
            }]
            companyData.auth_cert = d
          } else {
            companyData.auth_cert = fileData
          }
        } else {
          fileListCert.value = []
        }
        formRef.value.validate('auth_cert')
      } else {
        fileListCert.value = []
        companyData.auth_cert = ''
      }
    }
    const submitForm = () => {
      loading.value = true
      formRef.value.validate().then(async () => {
        let data = {}
        if (typeData.value === 1) {
          if (profileData.card01 instanceof File) {
            await proxy.$oss.upload(
                'id_card',
                proxy.$tools.makeFileName() + proxy.$tools.getFileExt(profileData.card01.name),
                profileData.card01
            ).then(r => {
              if (r && r.res.statusCode === 200) {
                profileData.card01 = r.name
              } else {
                proxy.$message.error('上传中断，请刷新页面后重试！')
                loading.value = false
                return false
              }
            })
          }
          if (profileData.card02 instanceof File) {
            await proxy.$oss.upload(
                'id_card',
                proxy.$tools.makeFileName() + proxy.$tools.getFileExt(profileData.card02.name),
                profileData.card02
            ).then(r => {
              if (r && r.res.statusCode === 200) {
                profileData.card02 = r.name
              } else {
                proxy.$message.error('上传中断，请刷新页面后重试！')
                loading.value = false
                return false
              }
            })
          }

          if (!profileData.card01 || !profileData.card02) {
            proxy.$message.error('省负责数据丢失！')
            loading.value = false
            return false
          }
          data = toRaw(profileData)
        } else if (typeData.value === 2) {
          if (companyData.company_license instanceof File) {
            await proxy.$oss.upload(
                'company_license',
                proxy.$tools.makeFileName() + proxy.$tools.getFileExt(companyData.company_license.name),
                companyData.company_license
            ).then(r => {
              if (r && r.res.statusCode === 200) {
                companyData.company_license = r.name
              } else {
                proxy.$message.error('上传中断，请刷新页面后重试！')
                loading.value = false
                return false
              }
            })
          }
          if (companyData.auth_cert instanceof File) {
            await proxy.$oss.upload(
                'auth_cert',
                proxy.$tools.makeFileName() + proxy.$tools.getFileExt(companyData.auth_cert.name),
                companyData.auth_cert
            ).then(r => {
              if (r && r.res.statusCode === 200) {
                companyData.auth_cert = r.name
              } else {
                proxy.$message.error('上传中断，请刷新页面后重试！')
                loading.value = false
                return false
              }
            })
          }

          if (!companyData.company_license || !companyData.auth_cert) {
            proxy.$message.error('省负责数据丢失！')
            loading.value = false
            return false
          }
          data = toRaw(companyData)
        } else {
          proxy.$message.error('未识别认证类型！')
          loading.value = false
          return false
        }
        data.certify_type = typeData.value

        //提交方法
        requestSaveData(data)
      }).catch(() => {
        loading.value = false
      })
    }
    const requestSaveData = (obj) => {
      proxy.$requests.certifySave(obj).then(r => {
        if (r.code === 0) {
          proxy.$message.success(r.msg, 1.2).then(
              () => {
                loading.value = false
                nextSteps()
                proxy.$store.commit('user/setProfile', '')
                proxy.$store.commit('auth/setAuth', {})
              },
              () => {
              }
          )

        } else {
          loading.value = false
          proxy.$message.error(r.msg)
        }
      })
    }
    /*----初始化----*/
    const alertData = reactive({state: false, text: ''})
    const alertClose = () => {
      alertData.state = false
      alertData.text = ''
    }
    loading.value = true
    proxy.$requests.waitCertify().then(r => {
      if (r.code === 0) {
        setTimeout(() => {
          loading.value = false
          let data = r.data
          if (data && data.status === 1) {
            steps.value.current = 3
          }
          if (data && data.status === 2) {
            typeData.value = data.type
            steps.value.current = 1
            alertData.state = true
            alertData.text = data.verify_content ? '提示：' + data.verify_content : '提示：提交内容，审核不通过，请重新填写'
            if (data.type === 1) {
              profileData.name = data.name
              profileData.id_card = data.id_card
              profileData.card01 = data.card01
              profileData.card02 = data.card02
              fileListCard01.value.push(proxy.$tools.setFileList(1, data.card01, proxy.$oss.sign(data.card01)))
              fileListCard02.value.push(proxy.$tools.setFileList(1, data.card02, proxy.$oss.sign(data.card02)))
            }
            if (data && data.type === 2) {
              companyData.company_name = data.company_name
              companyData.social_code = data.social_code
              companyData.legal_person = data.legal_person
              companyData.company_license = data.company_license
              companyData.auth_cert = data.auth_cert
              fileListLicense.value.push(proxy.$tools.setFileList(1, data.company_license, proxy.$oss.sign(data.company_license)))
              fileListCert.value.push(proxy.$tools.setFileList(1, data.auth_cert, proxy.$oss.sign(data.auth_cert)))
            }
          }
        }, 500)
      } else {
        proxy.$router.replace('/home')
      }
    })
    return {
      loading,
      navData,
      steps,
      certifyType,
      selectType,
      prevSteps,
      formRef,
      typeData,
      profileData,
      profileRules,
      companyData,
      companyRules,
      previewVisible,
      previewImage,
      fileListCard01,
      fileListCard02,
      fileListLicense,
      fileListCert,
      cancelPreview,
      handlePreview,
      beforeUpload,
      uploadCard01Change,
      uploadCard02Change,
      uploadLicenseChange,
      uploadCertChange,
      submitForm,
      alertData,
      alertClose,
    }
  },
})
</script>

<style scoped>
.step-panel {
  margin-top: 30px;
  min-height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.certify-type-panel {
  width: 320px;
}

.certify-type-panel .list-item {
  margin-bottom: 40px;
}

.certify-type-panel .item-group {
  font-size: 26px;
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 30px;
  border: 1px solid #eee;
  border-radius: 4px;
  cursor: pointer;
  color: #666;
}

.certify-type-panel .item-group:hover {
  border: 1px solid var(--app-color-primary);
  color: var(--app-color-primary);
}

.certify-type-panel .item-group:last-child {
  margin-bottom: 0;
}

.icon-box {
  font-size: 56px;
}

.form-panel {
  width: 600px;
}
</style>
