import OSS from 'ali-oss'
import data from "@/plugins/init/data"
import store from '@/store'

let oss = data.ossConfig

function clientService() {
    let sts = store.getters['aliyun/getSts']
    return !sts || !oss ? false : new OSS({
        accessKeyId: sts.accessKeyId,
        accessKeySecret: sts.accessKeySecret,
        stsToken: sts.securityToken,
        refreshSTSToken: true,
        bucket: oss.bucket,
        region: oss.region,
    })
}

export default {
    // 上传流
    putBuffer: async function (dir, name, File) {
        try {
            return clientService() ? clientService().put(dir + '/' + name, new Buffer(File)) : ''
        } catch (e) {
            console.log(e)
        }
    },
    //上传文件
    upload: async function (dir, name, File) {
        try {
            return clientService() ? clientService().put(dir + '/' + name, File) : ''
        } catch (e) {
            console.log(e)
        }
    },
    //签名
    sign: function (url) {
        try {
            if (!url || url.indexOf("://") > -1) {
                return url
            } else {
                let link = oss.host_url.charAt(oss.host_url.length - 1) === '/' ? oss.host_url : oss.host_url + '/'
                return link + url
            }
        } catch (e) {
            console.log(e)
        }
    },
    //去掉域名
    displace: function (url) {
        try {
            let link = oss.host_url.charAt(oss.host_url.length - 1) === '/' ? oss.host_url : oss.host_url + '/'
            return url.replace(link, '')
        } catch (e) {
            console.log(e)
        }
    },
}
