<template>
  <a-card :bordered="false">
    <a-steps :current="current">
      <a-step v-for="item in steps" :key="item.title" :title="item.title"/>
    </a-steps>
    <a-spin :spinning="loading" tip="请稍等...">
      <div v-if="current === 0" class="steps-content">
        <a-form ref="formRef" :model="formData" :rules="formRules" layout="vertical">
          <a-form-item label="相册名称" name="album_title">
            <a-input v-model:value="formData.album_title" placeholder="请输入名称" type="text"/>
          </a-form-item>
          <a-form-item label="相册描述" name="album_desc">
            <a-textarea v-model:value="formData.album_desc" :auto-size="{ minRows: 3, maxRows: 5 }"
                        placeholder="请输入描述"/>
          </a-form-item>

          <div class="text-center">
            <a-button :loading="loading" type="primary" @click="submitForm">下一步</a-button>
          </div>
        </a-form>
      </div>
      <div v-else-if="current === 1" class="steps-content">
        <div v-if="formData.photos.length < uploadLimit" style="padding-bottom: 20px;">
          <a-upload :before-upload="beforeUpload" :multiple="true" :showUploadList="false" accept=".jpg,.png,.jpeg"
                    @change="uploadChange">
            <a-button>
              <upload-outlined/>
              选择图片
            </a-button>
          </a-upload>
        </div>
        <a-table :columns="tableCol" :data-source="formData.photos" :pagination="false" bordered size="middle">
          <template #bodyCell="{ column,index,text, record }">
            <template v-if="column.dataIndex === 'thumb'">
              <div class="thumb-img">
                <a-image :src="record.thumb"/>
              </div>
            </template>
            <template v-if="column.dataIndex === 'title'">
              <div v-if="editCellKey.key===index" class="editable-cell">
                <a-input v-model:value="editCellKey.text"/>
                <check-outlined class="text-primary cursor" @click="ocSaveCell(index)"/>
              </div>
              <div v-else class="editable-cell">
                <div class="text-ellipsis">{{ text || ' ' }}</div>
                <edit-outlined class="text-primary cursor" @click="ocEditCell(index)"/>
              </div>
            </template>
            <template v-if="column.dataIndex === 'poster'">
              <a-switch :checked="formData.poster === index" checked-children="是" un-checked-children="否"
                        @change="onPoster(index)"/>
            </template>
            <template v-if="column.dataIndex === 'operation'">
              <a-popconfirm v-if="formData.photos.length" title="确定执行删除操作?" @confirm="onTableDel(index)">
                <a class="text-primary">删除</a>
              </a-popconfirm>
            </template>
          </template>
        </a-table>

        <div class="text-center" style="padding: 30px 0">
          <a-button style="margin-right: 100px;" @click="--current">上一步</a-button>
          <a-button :loading="loading" type="primary" @click="submitForm">提交</a-button>
        </div>
      </div>
      <div v-else class="steps-content">
        <a-result v-if="result" status="success" title="操作成功"></a-result>
        <a-result v-else status="error" title="操作失败"></a-result>
        <br>
        <div class="steps-action text-center">
          <a-button :loading="loading" type="primary" @click="close"> 关闭</a-button>
        </div>
      </div>
    </a-spin>
  </a-card>
</template>

<script>
import {defineComponent, getCurrentInstance, reactive, ref, toRaw,} from "vue"

export default defineComponent({
  emits: ['drawerClose'],
  props: ['drawerData'],
  setup() {
    const loading = ref(false)
    const {proxy} = getCurrentInstance()
    proxy.$store.dispatch('aliyun/setStsConfig');//上传操作前，检测STS
    const formRef = ref()
    const result = ref(true)
    const current = ref(0)
    const editCellKey = reactive({key: '', text: ''})
    const formData = reactive({id: '', album_title: '', album_desc: '', poster: 0, photos: []})
    const steps = ref([{title: '创建相册'}, {title: '上传照片'}, {title: '完成'}])
    const tableCol = [
      {title: '图片', dataIndex: 'thumb', align: 'center', width: 120},
      {title: '标题', dataIndex: 'title'},
      {title: '是否封面', dataIndex: 'poster', align: 'center', width: 120},
      {title: '操作', dataIndex: 'operation', align: 'center', width: 120},
    ];
    const next = (r = 1) => {
      if (r !== 1) {
        result.value = false
      }
      current.value++
    }

    const close = () => {
      proxy.$emit('drawerClose')
    }
    const formRules = reactive({
      album_title: [
        {required: true, message: '相册名称必须填写！', trigger: 'change',},
        {max: 64, message: '相册名称最多64个字符！', trigger: 'blur',},
      ],
      album_desc: [
        {required: true, message: '相册详情必须填写！', trigger: 'change',},
        {max: 200, message: '相册详情最多200字符！', trigger: 'blur',},
      ],
    })
    //提交表单
    const submitForm = async () => {
      loading.value = true
      if (current.value === 0) {
        formRef.value.validate().then(() => {
          loading.value = false
          next()
        }).catch(() => {
          loading.value = false
        })
      } else if (current.value === 1) {
        if (formData.photos.length === 0) {
          loading.value = false
          proxy.$message.error('必须上传照片！')
          return false
        }
        for (let i = 0; i < formData.photos.length; i++) {
          if (formData.photos[i].img instanceof File) {
            let ext = proxy.$tools.getFileExt(formData.photos[i].img.name);
            let fileName = proxy.$tools.makeFileName();
            await proxy.$oss.upload('album', fileName + ext, formData.photos[i].img).then(r => {
              if (r && r.res.statusCode === 200) {
                formData.photos[i].img = r.name
              } else {
                proxy.$message.error('上传中断，请刷新页面后重试！')
                return false;
              }
            })

          }
        }

        proxy.$requests.albumSave(toRaw(formData)).then(r => {
          if (r.code === 0) {
            setTimeout(function () {
              loading.value = false
              next()
            }, 2000)
          } else {
            loading.value = false
            proxy.$message.error(r.msg)
          }
        })
      } else {
        next()
      }
    }
    const onTableDel = key => {
      if (key === formData.poster) {
        formData.poster = 0
      }
      formData.photos.splice(key, 1)
    }
    const onPoster = r => {
      formData.poster = r
    }
    const ocEditCell = index => {
      editCellKey.key = index
      editCellKey.text = formData.photos[index].title
    }
    const ocSaveCell = index => {
      formData.photos[index].title = editCellKey.text
      editCellKey.text = ''
      editCellKey.key = ''
    }

    /*-----文件上传部分-----*/
    const uploadLimit = ref(10)
    const beforeUpload = () => {
      return false
    }
    const uploadChange = async (r) => {
      let fileData = r.file, sn = proxy.$tools.randomStr(10)
      let info = {title: sn, img: '', thumb: ''}
      if (fileData.type === 'image/jpeg' || fileData.type === 'image/png') {
        let isSize = fileData.size / 1024 / 1024 < 1, baseFile = await proxy.$tools.getBase64(fileData)
        if (!isSize) {
          let obj = await proxy.$img.imgCompress(baseFile, {width: 1000, quality: 0.75})
          baseFile = obj.img
          fileData = proxy.$img.dataURLtoFile(baseFile, sn + '.jpg')
        }
        info.img = fileData
        info.thumb = baseFile
        if (formData.photos.length < uploadLimit.value) {
          formData.photos.push(info)
        }
      } else {
        proxy.$message.error('请选择图片上传')
      }
    }
    /*----初始化数据----*/
    if (proxy.$props.drawerData && Object.keys(proxy.$props.drawerData).length !== 0) {
      try {
        formData.id = proxy.$props.drawerData.id
        formData.album_title = proxy.$props.drawerData.title
        formData.album_desc = proxy.$props.drawerData.desc
        let poster = 0, photos = proxy.$props.drawerData.photos, arr = []
        for (let i = 0; i < photos.length; i++) {
          if (photos[i].pic_link === proxy.$props.drawerData.icon) {
            poster = i
          }
          arr.push({
            thumb: proxy.$oss.sign(photos[i].pic_link),
            img: photos[i].pic_link,
            title: photos[i].pic_title,
          })
        }
        formData.poster = poster
        formData.photos = arr
      } catch (e) {
        proxy.$message.error('未知错误', 1.2).then(() => {
          proxy.$emit('drawerClose')
        }, () => {
        })
      }
    }
    return {
      loading,
      result,
      current,
      steps,
      next,
      formRef,
      formData,
      formRules,
      close,
      submitForm,
      tableCol,
      editCellKey,
      onTableDel,
      onPoster,
      uploadLimit,
      beforeUpload,
      uploadChange,
      ocSaveCell,
      ocEditCell,
    }
  }
})
</script>
<style>
.steps-content {
  margin-top: 50px;
}

.thumb-img {
  height: 32px;
  width: 32px;
  display: inline-block;
  overflow: hidden;
}

.editable-cell {
  position: relative;
  padding-right: 40px;
}

.editable-cell span {
  position: absolute;
  margin-top: -7px;
  top: 50%;
  right: 0;
}
</style>
