import api from "@/plugins/request/api"

const state = {
    footer_menu: [{"id":6,"menu_id":2,"parent_id":0,"nav_link":"https://www.jianjiangwang.com","nav_name":"网站首页","nav_target":"_self","nav_icon":"","sort":0,"link":"https://www.jianjiangwang.com"},{"id":7,"menu_id":2,"parent_id":0,"nav_link":"https://www.jianjiangwang.com","nav_name":"关于我们","nav_target":"_self","nav_icon":"","sort":0,"link":"https://www.jianjiangwang.com"},{"id":8,"menu_id":2,"parent_id":0,"nav_link":"https://www.jianjiangwang.com","nav_name":"服务协议","nav_target":"_self","nav_icon":"","sort":0,"link":"https://www.jianjiangwang.com"},{"id":9,"menu_id":2,"parent_id":0,"nav_link":"https://www.jianjiangwang.com","nav_name":"平台发布规范","nav_target":"_self","nav_icon":"","sort":0,"link":"https://www.jianjiangwang.com"},{"id":10,"menu_id":2,"parent_id":0,"nav_link":"https://www.jianjiangwang.com","nav_name":"平台指南","nav_target":"_self","nav_icon":"","sort":0,"link":"https://www.jianjiangwang.com"},{"id":11,"menu_id":2,"parent_id":0,"nav_link":"https://www.jianjiangwang.com","nav_name":"免责声明","nav_target":"_self","nav_icon":"","sort":0,"link":"https://www.jianjiangwang.com"},{"id":12,"menu_id":2,"parent_id":0,"nav_link":"https://www.jianjiangwang.com","nav_name":"联系我们","nav_target":"_self","nav_icon":"","sort":0,"link":"https://www.jianjiangwang.com"}],
    website_config: {"business_tel":"0512-67862381","kf_tel":"0512-67862381","kf_qq":"121625706","copyright":"© 2021-2023 上海栩平建筑科技有限公司版权所有","site_icp":"沪ICP备18007906-7号","site_domain":"https://www.jianjiangwang.com"},
    cookie_config: {"token_name":"_c_mid","exp_name":"_c_m_t","domain":".jianjiangwang.com"},
    update:false,
}
const mutations = {
    setFooterMenu(state, data) {
        state.footer_menu = data
    },
    setWebsiteConfig(state, data) {
        state.website_config = data
    },
    setCookieConfig(state, data) {
        state.cookie_config = data
    },
    setConfigStatus(state, data) {
        state.update = data
    },
}

const getters = {
    getSystemFooterMenu(state) {
        return state.footer_menu
    },
    getWebsiteConfig(state) {
        return state.website_config
    },
    getCookieConfig(state) {
        return state.cookie_config
    },
}
const actions = {
    setSystemConfig(context, payload = {}) {
        if (!context.state.update) {
            api.listSystem(payload).then(r => {
                if (r.code === 0) {
                    let d = r.data
                    context.commit('setFooterMenu', d.footer_menu)
                    context.commit('setWebsiteConfig', d.website_config)
                    context.commit('setCookieConfig', d.cookie_config)
                    context.commit('setConfigStatus', true)
                }
            })
        }
    },

}
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}

