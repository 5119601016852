import {request} from "./http"

let appUrl = {
    web: '',
}
export default {
    config: function (obj) {
        appUrl.web = obj && obj.web ? obj.web : appUrl.web
    },
    /*系统配置*/
    listSystem: function (params = {}) {
        return request.http(appUrl.web, '/system/config', 'GET', params)
    },
    /*获取验证码*/
    getCode: function (params = {}) {
        return request.http(appUrl.web, '/system/get_code', 'POST', params)
    },
    /*登录*/
    appLogin: function (params = {}) {
        return request.http(appUrl.web, '/system/app_login', 'POST', params)
    },
    /*注册*/
    appRegister: function (params = {}) {
        return request.http(appUrl.web, '/system/app_register', 'POST', params)
    },
    /*找回密码*/
    appForget: function (params = {}) {
        return request.http(appUrl.web, '/system/app_forget', 'POST', params)
    },
    /*用户上传*/
    ossSts: function (params = {}) {
        return request.http(appUrl.web, '/member/oss_sts', 'GET', params)
    },
    /*home页数据*/
    homeData: function (params = {}) {
        return request.http(appUrl.web, '/member/home_data', 'GET', params)
    },
    /*编辑
    /*用户详情*/
    userProfile: function (params = {}) {
        return request.http(appUrl.web, '/member/user_profile', 'GET', params)
    },
    /*编辑用户详情*/
    editProfile: function (params = {}) {
        return request.http(appUrl.web, '/member/edit_profile', 'POST', params)
    },
    /*用户修改密码*/
    userForget: function (params = {}) {
        return request.http(appUrl.web, '/member/change_pwd', 'POST', params)
    },
    /*未读站内信列表*/
    unreadMsgList: function (params = {}) {
        return request.http(appUrl.web, '/member/unread_msg', 'GET', params)
    },
    /*站内信列表*/
    msgList: function (params = {}) {
        return request.http(appUrl.web, '/member/msg_list', 'GET', params)
    },
    /*站内信相关操作*/
    msgAction: function (params = {}) {
        return request.http(appUrl.web, '/member/msg_action', 'POST', params)
    },
    /*日志列表*/
    logsList: function (params = {}) {
        return request.http(appUrl.web, '/member/logs_list', 'GET', params)
    },
    /*实名认证保存*/
    certifySave: function (params = {}) {
        return request.http(appUrl.web, '/member/certify_save', 'POST', params)
    },
    /*获取实名认证*/
    memberCertify: function (params = {}) {
        return request.http(appUrl.web, '/member/get_certify', 'GET', params)
    },
    /*获取用户权限*/
    memberAuth: function (params = {}) {
        return request.http(appUrl.web, '/member/get_auth', 'GET', params)
    },
    /*获取待验证实名认证*/
    waitCertify: function (params = {}) {
        return request.http(appUrl.web, '/member/wait_certify', 'GET', params)
    },
    /*相册列表*/
    albumList: function (params = {}) {
        return request.http(appUrl.web, '/album/album_list', 'GET', params)
    },
    /*相册保存*/
    albumSave: function (params = {}) {
        return request.http(appUrl.web, '/album/album_save', 'POST', params)
    },
    /*相册删除*/
    albumDel: function (params = {}) {
        return request.http(appUrl.web, '/album/album_del', 'POST', params)
    },
    /*视频列表*/
    videoList: function (params = {}) {
        return request.http(appUrl.web, '/video/video_list', 'GET', params)
    },
    /*视频保存*/
    videoSave: function (params = {}) {
        return request.http(appUrl.web, '/video/video_save', 'POST', params)
    },
    /*视频删除*/
    videoDel: function (params = {}) {
        return request.http(appUrl.web, '/video/video_del', 'POST', params)
    },
    /*新闻列表*/
    newsList: function (params = {}) {
        return request.http(appUrl.web, '/news/news_list', 'GET', params)
    },
    /*新闻保存*/
    newsSave: function (params = {}) {
        return request.http(appUrl.web, '/news/news_save', 'POST', params)
    },
    /*新闻删除*/
    newsDel: function (params = {}) {
        return request.http(appUrl.web, '/news/news_del', 'POST', params)
    },
    /*招聘配置*/
    jobConfig: function (params = {}) {
        return request.http(appUrl.web, '/job/job_config', 'GET', params)
    },
    /*招聘列表*/
    jobList: function (params = {}) {
        return request.http(appUrl.web, '/job/job_list', 'GET', params)
    },
    /*招聘保存*/
    jobSave: function (params = {}) {
        return request.http(appUrl.web, '/job/job_save', 'POST', params)
    },
    /*招聘删除*/
    jobDel: function (params = {}) {
        return request.http(appUrl.web, '/job/job_del', 'POST', params)
    },
    /*用户实名认证公司详情*/
    userCompany: function (params = {}) {
        return request.http(appUrl.web, '/company/get_company', 'GET', params)
    },
    /*编辑完善企业信息*/
    editCompany: function (params = {}) {
        return request.http(appUrl.web, '/company/edit_company', 'POST', params)
    },
    /*会员获取积分*/
    pointGet: function (params = {}) {
        return request.http(appUrl.web, '/member/point_get', 'GET', params)
    },
    /*投资咨询我的购买*/
    memberInvest: function (params = {}) {
        return request.http(appUrl.web, '/invest/member_invest', 'GET', params)
    },
    /*投资咨询发布列表*/
    investList: function (params = {}) {
        return request.http(appUrl.web, '/invest/invest_list', 'GET', params)
    },
    /*投资咨询保存*/
    investSave: function (params = {}) {
        return request.http(appUrl.web, '/invest/invest_save', 'POST', params)
    },
    /*投资咨询删除*/
    investDel: function (params = {}) {
        return request.http(appUrl.web, '/invest/invest_del', 'POST', params)
    },
    /*投资咨询详情*/
    investDetail: function (params = {}) {
        return request.http(appUrl.web, '/invest/invest_detail', 'GET', params)
    },
    /*生成支付订单*/
    createOrder: function (params = {}) {
        return request.http(appUrl.web, '/order/create_order', 'POST', params)
    },
    /*个人中心订单列表*/
    orderList: function (params = {}) {
        return request.http(appUrl.web, '/order/order_list', 'GET', params)
    },
    /*个人中心订单评价*/
    orderEval: function (params = {}) {
        return request.http(appUrl.web, '/order/order_eval', 'POST', params)
    },
    /*个人中心关闭订单*/
    orderClose: function (params = {}) {
        return request.http(appUrl.web, '/order/order_close', 'POST', params)
    },
    /*个人中心删除订单*/
    orderDel: function (params = {}) {
        return request.http(appUrl.web, '/order/order_del', 'POST', params)
    },
    /*个人中心删除待支付订单立即支付*/
    orderPayLimit: function (params = {}) {
        return request.http(appUrl.web, '/order/get_pay_limit', 'GET', params)
    },
    /*个人中心订单备注*/
    orderNote: function (params = {}) {
        return request.http(appUrl.web, '/order/order_note', 'POST', params)
    },
    /*我的项目，项目列表*/
    listHouseProject: function (params = {}) {
        return request.http(appUrl.web, '/house/list_project', 'GET', params)
    },
    /*我的项目，保存项目信息*/
    saveHouseProject: function (params = {}) {
        return request.http(appUrl.web, '/house/save_project', 'POST', params)
    },
    /*我的项目，补充材料*/
    setProjectAddendum: function (params = {}) {
        return request.http(appUrl.web, '/house/set_addendum', 'POST', params)
    },
    /*我的项目，获取项目信息*/
    getHouseProject: function (params = {}) {
        return request.http(appUrl.web, '/house/get_project', 'GET', params)
    },
    /*我的项目，房产信息图库*/
    getHouseImg: function (params = {}) {
        return request.http(appUrl.web, '/house/house_images', 'GET', params)
    },
    /*我的项目，房产信息图库,图片上传/删除记录操作*/
    setHouseImgChange: function (params = {}) {
        return request.http(appUrl.web, '/house/images_change', 'POST', params)
    },
    /*房产中心，锁单订单详情*/
    getHouseDetail: function (params = {}) {
        return request.http(appUrl.web, '/house/house_detail', 'GET', params)
    },
    /*房产中心，锁单操作*/
    setHouseLock:function (params = {}) {
        return request.http(appUrl.web, '/house/set_lock', 'POST', params)
    },
    /*房产中心我的锁单*/
    listHouseLock: function (params = {}) {
        return request.http(appUrl.web, '/house/house_lock', 'GET', params)
    },



    /*房产中心我----------------------------------------------------的锁单*/
    house3Lock: function (params = {}) {
        return request.http(appUrl.web, '/house/house_lock', 'GET', params)
    },
    /*房产中心我的待售清单*/
    noteList: function (params = {}) {
        return request.http(appUrl.web, '/house/note_list', 'GET', params)
    },
    /*房产中心发布列表*/
    houseList: function (params = {}) {
        return request.http(appUrl.web, '/house/house_list', 'GET', params)
    },
    /*创建待售清单时，房产信息*/
    listContractHouse: function (params = {}) {
        return request.http(appUrl.web, '/house/list_contract_house', 'POST', params)
    },
    /*房产中心保存*/
    houseSave: function (params = {}) {
        return request.http(appUrl.web, '/house/house_save', 'POST', params)
    },
    /*房产中心删除*/
    houseDel: function (params = {}) {
        return request.http(appUrl.web, '/house/house_del', 'POST', params)
    },
    /*房产中心详情*/
    houseDetail: function (params = {}) {
        return request.http(appUrl.web, '/house/house_detail', 'GET', params)
    },
    /*待售清单明细提交平台*/
    addHouseNote: function (params = {}) {
        return request.http(appUrl.web, '/house/add_note', 'POST', params)
    },
    /*我的待售清单-撤销*/
    cancelHouseNote: function (params = {}) {
        return request.http(appUrl.web, '/house/cancel_note', 'POST', params)
    },
    /*我的待售清单-查看*/
    getHouseNote: function (params = {}) {
        return request.http(appUrl.web, '/house/get_note', 'GET', params)
    }
}
