<template>
  <SideNav :nav-data="navData"/>
  <div class="main-panel scrollbar">
    <a-card class="card-panel">
      <a-card :bordered="false">
        <div class="title-panel">
          <div class="title">{{ columnTitle }}</div>
        </div>
        <div class="table-header-operation">
          <a-button :disabled="!hasSelected" :loading="loading" type="primary" @click="delFn">删除</a-button>
          <a-button :disabled="!hasSelected" :loading="loading" type="primary" @click="readFn">标记已读</a-button>
        </div>
        <a-table :columns="tableCol" :data-source="tableData" :loading="loading" :pagination="paginate"
                 :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" rowKey="id"
                 @change="onPaginateChange">
          <template #bodyCell="{ column,record }">
            <template v-if="column.dataIndex === 'status'">
              <span v-if="record.status === 1">未读</span>
              <span v-else class="text-grey">已读</span>
            </template>
            <template v-if="column.dataIndex === 'operation'">
              <a class="text-primary" @click="onShowRow(record)">查看</a>
            </template>
          </template>
        </a-table>
      </a-card>
    </a-card>
  </div>

  <a-drawer v-model:visible="drawerData.visible" :bodyStyle="{padding:0}" :closable="false" :destroyOnClose="true"
            :keyboard="false" :maskClosable="false" :width="drawerData.width" placement="right">
    <div class="layer-panel">
      <div class="layer-title">
        <div class="title">{{ drawerData.title }}</div>
        <div class="icon" @click="drawerClose">
          <close-outlined/>
        </div>
      </div>
      <div class="layer-body bg-white">
        <component :is="drawerData.component" v-if="drawerData.component" :drawerData="drawerData.data"
                   @drawerClose="drawerClose"></component>
      </div>
    </div>
  </a-drawer>
</template>

<script>
import {computed, defineComponent, getCurrentInstance, reactive, ref, toRefs, watch} from "vue"
import SideNav from "@/components/nav/SideNav"
import NoticeDetail from "@/components/tpl/NoticeDetail"

export default defineComponent({
  components: {SideNav,NoticeDetail},
  setup() {
    const loading = ref(false)
    const {proxy} = getCurrentInstance()
    const navData = proxy.$inits.userNav
    const columnTitle = ref('')
    navData.data.filter((item) => {
      if (item.link === proxy.$route.path) {
        return columnTitle.value = item.name
      }
    })
    const state = reactive({
      selectedRowKeys: [],
      loading: false,
    });
    const hasSelected = computed(() => state.selectedRowKeys.length > 0);
    const readFn = () => {
      state.loading = true;
      let arrKeys = Array.from(state.selectedRowKeys)
      msgAction({type: 1, arr_id: arrKeys}).then(r => {
        setTimeout(() => {
          state.loading = false
        }, 1000)
        if (r.code === 0) {
          tableData.value.forEach(item => {
            if (arrKeys.indexOf(item.id) > -1) {
              item.status = 2
            }
          })
          proxy.$message.success(r.msg)
          state.selectedRowKeys = []
        } else {
          proxy.$message.error(r.msg)
        }
      })
    }

    const delFn = () => {
      state.loading = true
      let arrKeys = Array.from(state.selectedRowKeys), data = []
      msgAction({type: 2, arr_id: arrKeys}).then(r => {
        setTimeout(() => {
          state.loading = false
        }, 1000)
        if (r.code === 0) {
          tableData.value.forEach(item => {
            if (arrKeys.indexOf(item.id) === -1) {
              data.push(item)
            }
          })
          state.selectedRowKeys = []
          paginate.total = data.length
          tableData.value = data
          if (data.length === 0) {
            requestList()
          }
        } else {
          proxy.$message.error(r.msg)
        }
      })
    }
    const msgAction = (obj) => {
      return proxy.$requests.msgAction(obj)
    }
    const onSelectChange = selectedRowKeys => {
      state.selectedRowKeys = selectedRowKeys
    }

    const initDrawer = () => ({visible: false, title: '站内信', width: 600, component: 'NoticeDetail', data: '',})
    const drawerData = reactive(initDrawer())
    const drawerClose = () => {
      Object.assign(drawerData, initDrawer())
    }
    watch(proxy.$router.currentRoute, () => {
      Object.assign(drawerData, initDrawer())
    })
    const tableData = ref([])
    const tableCol = [
      {title: '消息标题', dataIndex: 'title', ellipsis: true,},
      {
        title: '状态', dataIndex: 'status', width: 150, align: 'center', sorter: {
          compare: (a, b) => a.status - b.status,
          multiple: 1,
        },
      },
      {title: '消息类型', dataIndex: 'type', align: 'center', width: 150,},
      {title: '创建时间', dataIndex: 'effect_time', align: 'center', width: 200,},
      {title: '操作', dataIndex: 'operation', width: 130, align: 'center',},
    ]
    const initPaginate = () => ({
      total: 0,
      current: 1,
      pageSize: 8,
      page: 1,
      limit: 80,
      count: 0,
      showSizeChanger: false
    })
    const paginate = reactive(initPaginate())
    const onPaginateChange = pageNumber => {
      paginate.current = pageNumber.current
      if (pageNumber.current === Math.ceil(pageNumber.total / pageNumber.pageSize)) {
        if (pageNumber.count === pageNumber.total) {
          proxy.$message.info('没有数据了')
          return false
        }
        ++paginate.page
        requestList()
      }
    }
    const requestList = async () => {
      loading.value = !loading.value
      await proxy.$requests.msgList({
        page: paginate.page,
        limit: paginate.limit,
      }).then(r => {
        loading.value = !loading.value
        if (r.code === 0) {
          tableData.value = tableData.value.concat(r.data)
          paginate.total = tableData.value.length
          paginate.count = r.count
        } else {
          proxy.$message.error(r.msg)
        }
      })
    }
    requestList()
    const onShowRow = item => {
      drawerData.data = item
      drawerData.visible = true
      if (item.status === 1) {
        let arrKeys = []
        arrKeys.push(item.id)
        msgAction({type: 1, arr_id: arrKeys}).then(r => {
          if (r.code === 0) {
            tableData.value.forEach(item => {
              if (arrKeys.indexOf(item.id) > -1) {
                item.status = 2
              }
            })
          }
        })
      }
    }
    return {
      loading,
      navData,
      columnTitle,
      userInfoNav: proxy.$inits.userInfoNav,
      tableData,
      tableCol,
      paginate,
      onPaginateChange,
      onShowRow,
      hasSelected,
      ...toRefs(state),
      readFn,
      delFn,
      onSelectChange,
      drawerClose,
      drawerData,
    }
  },
})
</script>

<style scoped>

</style>
