<template>
  <a-spin :spinning="loading" tip="请稍等...">
    <a-card :bordered="false">
      <a-form ref="formRef" :label-col="{span: 3,offset:1}" :model="formData" :rules="formRules"
              :wrapper-col="{span: 19}">
        <a-form-item label="视频名称" name="title">
          <a-input v-model:value="formData.title" placeholder="请输入名称" type="text"/>
        </a-form-item>
        <a-form-item label="视频描述" name="desc">
          <a-textarea v-model:value="formData.desc" :auto-size="{ minRows: 3, maxRows: 5 }" placeholder="请输入描述"/>
        </a-form-item>

        <a-form-item label="上传视频" name="link" style="height: 118px">
          <a-upload v-model:file-list="fileList" :before-upload="beforeUpload"
                    list-type="picture-card" @change="uploadChange" @preview="handlePreview">
            <div v-if="fileList.length < 1">
              <plus-outlined/>
              <div class="ant-upload-text">添加</div>
            </div>
          </a-upload>
          <a-modal :footer="null" :visible="previewVisible" @cancel="cancelPreview">
            <img :src="previewImage" alt="example" style="width: 100%"/>
          </a-modal>
        </a-form-item>
        <br>
        <div class="text-center">
          <a-button :loading="loading" type="primary" @click="submitForm">提交</a-button>
        </div>
      </a-form>
    </a-card>
  </a-spin>
</template>

<script>
import {defineComponent, getCurrentInstance, reactive, ref, toRaw} from "vue"

export default defineComponent({
  emits: ['drawerClose'],
  props: ['drawerData'],
  setup() {
    const loading = ref(false)
    const {proxy} = getCurrentInstance()
    proxy.$store.dispatch('aliyun/setStsConfig')//上传操作前，检测STS

    const formRef = ref()
    const formData = reactive({id: '', title: '', desc: '', icon: '', link: ''})
    let validVideo = async (_rule, value) => {
      if (value === '' || !value) {
        return Promise.reject('请选择视频上传！')
      }
      if (formData.icon === '' || !formData.icon) {
        return Promise.reject('获取视频封面失败！')
      }
      if (value instanceof File) {
        if (value.type !== 'video/mp4') {
          return Promise.reject('请选择MP4格式视频上传！')
        }
        if (value.size / 1024 / 1024 > 200) {
          return Promise.reject('视频大于200MB,请重新压缩后上传！')
        }
      }
      return Promise.resolve()
    }
    const formRules = {
      title: [
        {required: true, message: '视频名称必须填写！', trigger: 'change',},
        {max: 64, message: '视频名称最多64个字符！', trigger: 'blur',},
      ],
      desc: [
        {required: true, message: '视频描述必须填写！', trigger: 'change',},
        {max: 200, message: '视频描述最多200字符！', trigger: 'blur',},
      ],
      link: [
        {required: true, validator: validVideo, trigger: 'change',},
      ],
    }
    const submitForm = () => {
      loading.value = true
      formRef.value.validate().then(async () => {
        if (formData.link instanceof File) {
          let video_file = formData.link
          await proxy.$oss.upload(
              'video',
              proxy.$tools.makeFileName() + proxy.$tools.getFileExt(video_file.name),
              video_file
          ).then(r => {
            if (r && r.res.statusCode === 200) {
              formData.link = r.name
            } else {
              proxy.$message.error('上传中断，请刷新页面后重试！')
              loading.value = false
              return false
            }
          })
        }
        if (formData.icon instanceof File) {
          let poster_file = formData.icon
          await proxy.$oss.upload(
              'picture',
              proxy.$tools.makeFileName() + proxy.$tools.getFileExt(poster_file.name),
              poster_file
          ).then(r => {
            if (r && r.res.statusCode === 200) {
              formData.icon = r.name
            } else {
              proxy.$message.error('上传中断，请刷新页面后重试！')
              loading.value = false
              return false
            }
          })
        }
        if (!formData.link || !formData.icon) {
          proxy.$message.error('视频数据丢失！')
          loading.value = false
          return false
        }
        //提交方法
        requestSaveData(toRaw(formData))
        //提交方法
      }).catch(() => {
        loading.value = false
      })
    }
    //发送请求
    const requestSaveData = obj => {
      proxy.$requests.videoSave(obj).then(r => {
        if (r.code === 0) {
          proxy.$message.success(r.msg, 1.2).then(
              () => {
                loading.value = false
                proxy.$emit('drawerClose')
              },
              () => {
              }
          )

        } else {
          loading.value = false
          proxy.$message.error(r.msg)
        }
      })
    }
    /*-----文件上传部分-----*/
    const previewVisible = ref(false)
    const previewImage = ref('')
    const fileList = ref([])
    //取消预览
    const cancelPreview = () => {
      previewVisible.value = false
    }
    //预览图片
    const handlePreview = async file => {
      previewImage.value = file.thumbUrl
      previewVisible.value = true
    }
    const beforeUpload = () => {
      return false
    }
    const handleChange = ({fileList: newFileList}) => {
      fileList.value = newFileList
    }
    const uploadChange = async (r) => {
      if (r.fileList.length > 0) {
        if (r.file.type === 'video/mp4') {
          let baseFile = await proxy.$video.videoCapture(r.file)
          let poster = proxy.$img.dataURLtoFile(baseFile, 'poster.jpg')
          fileList.value = [{
            uid: 1,
            name: poster.name,
            type: poster.type,
            size: poster.size,
            status: 'done',
            thumbUrl: baseFile,
          }]
          formData.link = r.file
          formData.icon = poster
        } else {
          fileList.value = []
        }
        formRef.value.validate('link')
      } else {
        fileList.value = []
        formData.link = ''
        formData.icon = ''
      }
    }
    /*----初始化数据----*/
    if (proxy.$props.drawerData && Object.keys(proxy.$props.drawerData).length !== 0) {
      try {
        formData.id = proxy.$props.drawerData.id
        formData.title = proxy.$props.drawerData.title
        formData.desc = proxy.$props.drawerData.desc
        formData.icon = proxy.$props.drawerData.icon
        formData.link = proxy.$props.drawerData.link
        fileList.value = [
          proxy.$tools.setFileList(1, proxy.$props.drawerData.icon, proxy.$oss.sign(proxy.$props.drawerData.icon))
        ]
      } catch (e) {
        proxy.$message.error('未知错误', 1.2).then(() => {
          proxy.$emit('drawerClose')
        }, () => {
        })
      }
    }
    return {
      loading,
      formRef,
      formData,
      formRules,
      fileList,
      submitForm,
      beforeUpload,
      handleChange,
      uploadChange,
      cancelPreview,
      handlePreview,
      previewVisible,
      previewImage,
    }
  }
})
</script>

<style scoped>
</style>
