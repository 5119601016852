export default {
    /*获取sessionStorage*/
    get: function (key) {
        let value = sessionStorage.getItem(key)
        if (value && value.substring(0, 1) === '{' && value.substring(value.length - 1, value.length) === '}') {
            try {
                value = JSON.parse(value)
            } catch (e) {
                return value
            }
        } else if (value && value.substring(0, 1) === '[' && value.substring(value.length - 1, value.length) === ']') {
            try {
                value = JSON.parse(value)
            } catch (e) {
                return value
            }
        }
        return value
    },

    /*创建sessionStorage*/
    set: function (key, value) {
        if (value && value.constructor === Array || value.constructor === Object) {
            value = JSON.stringify(value)
        }
        return sessionStorage.setItem(key, value)
    },

    /*清除指定sessionStorage*/
    remove: function (key) {
        return sessionStorage.removeItem(key)
    },

    /*清除所有sessionStorage*/
    clear: function () {
        return sessionStorage.clear()
    },
}
