<template>
  <div class="bg-white form-panel">
    <div class="left-side">
      <div class="label">注册</div>
      <a-form ref="formRef" :model="formData" :rules="formRules" layout="vertical">
        <a-form-item class="form-item" name="name" required>
          <a-input v-model:value="formData.name" placeholder="请输入姓名" type="text">
            <template #prefix>
              <UserOutlined style="color: rgba(0, 0, 0, 0.25)"/>
            </template>
          </a-input>
        </a-form-item>
        <a-form-item class="form-item" name="phone" required>
          <a-input v-model:value="formData.phone" placeholder="请输入手机号码" type="text">
            <template #prefix>
              <MobileOutlined style="color: rgba(0, 0, 0, 0.25)"/>
            </template>
          </a-input>
        </a-form-item>
        <a-form-item class="form-item" name="code" required>
          <a-input v-model:value="formData.code" placeholder="请输入验证码" type="text">
            <template #prefix>
              <SafetyOutlined style="color: rgba(0, 0, 0, 0.25)"/>
            </template>
          </a-input>
          <button v-if="second === 90" v-preventReClick class="ck-code" @click="getCode">点击获取验证码</button>
          <div v-else class="ck-code">{{ second }}秒后可重新获取</div>
        </a-form-item>
        <a-form-item class="form-item" name="pwd">
          <a-input v-model:value="formData.pwd" placeholder="请输入密码6-12位" type="password">
            <template #prefix>
              <LockOutlined style="color: rgba(0, 0, 0, 0.25)"/>
            </template>
          </a-input>
        </a-form-item>
        <a-form-item class="form-item" name="check_pwd">
          <a-input v-model:value="formData.check_pwd" placeholder="请再次输入密码6-12位" type="password">
            <template #prefix>
              <LockOutlined style="color: rgba(0, 0, 0, 0.25)"/>
            </template>
          </a-input>
        </a-form-item>
        <a-form-item class="form-item" name="inviter">
          <a-input v-model:value="formData.inviter" placeholder="请输入邀请码，可为空" type="text">
            <template #prefix>
              <SendOutlined style="color: rgba(0, 0, 0, 0.25)"/>
            </template>
          </a-input>
        </a-form-item>
        <a-form-item class="form-btn">
          <a-button :loading="loading" block type="primary" size="large" @click="submitForm">提 交</a-button>
        </a-form-item>
      </a-form>
    </div>
    <div class="right-side">
      <login-nav></login-nav>
    </div>
  </div>
</template>

<script>
import LoginNav from "@/components/nav/LoginNav.vue"
import {defineComponent, getCurrentInstance, reactive, ref, toRaw} from 'vue'

export default defineComponent({
  components: {
    LoginNav
  },
  setup() {
    const loading = ref(false)
    const {proxy} = getCurrentInstance()
    let formId = proxy.$tools.randomStr()//表单ID
    const formRef = ref()

    const formData = reactive({
      name: '',
      phone: '',
      code: '',
      pwd: '',
      check_pwd: '',
      inviter: '',
    })

    let validatePwd = async (rule, value) => {
      if (value === '') {
        return Promise.reject('请输入密码！')
      } else {
        if (formData.check_pwd !== '') {
          formRef.value.validate('check_pwd')
        }

        return Promise.resolve()
      }
    }
    let validatePwd2 = async (rule, value) => {
      if (value === '') {
        return Promise.reject('请再次输入密码！')
      } else if (value !== formData.pwd) {
        return Promise.reject("两次输入的密码不一致!")
      } else {
        return Promise.resolve()
      }
    }

    const formRules = reactive({
      name: [
        {required: true, message: '姓名必须填写！', trigger: 'change',},
        {min: 2, max: 12, message: '请填写真实姓名！', trigger: 'blur',},
        {pattern: proxy.$inits.regex.chs, message: '请填写真实姓名！', trigger: 'blur',}
      ],
      phone: [
        {required: true, message: '手机号码必须填写！', trigger: 'change',},
        {
          pattern: proxy.$inits.regex.phone,
          message: '手机号码不正确！',
          trigger: 'blur',
        }
      ],
      code: [
        {required: true, message: '验证码必须填写！', trigger: 'change'},
        {min: 4, max: 6, message: '验证码不正确！', trigger: 'blur',},
        {pattern: proxy.$inits.regex.number, message: '验证码不正确！', trigger: 'blur',}
      ],
      pwd: [
        {validator: validatePwd, trigger: 'change',},
        {min: 5, max: 12, message: '密码长度5-12位！', trigger: 'blur',},
        {pattern: proxy.$inits.regex.password, message: '密码只能英文、数字、下划线组成！', trigger: 'blur',}
      ],
      check_pwd: [
        {validator: validatePwd2, trigger: 'change',}
      ],
    })

    //其他
    const second = ref(90)
    let timer = null
    const timerFn = () => {
      timer = setInterval(() => {
        second.value = second.value - 1
        if (second.value <= 0) {
          clearInterval(timer)
          second.value = 90
        }
      }, 1000)
    }

    const getCode = () => {
      formRef.value.validate('phone').then(() => {
        let data = {form_id: formId, type: 1, phone: toRaw(formData).phone}
        proxy.$requests.getCode(data).then(r => {
          if (r.code === 0) {
            timerFn()
          } else {
            proxy.$message.error(r.msg)
          }
        })
      }).catch(err => {
        console.log('error', err)
      })
    }

    //表单提交
    const submitForm = () => {
      loading.value = !loading.value
      formRef.value.validate().then(() => {
        let data = toRaw(formData)
        proxy.$requests.appRegister({
          form_id: formId,
          name: data.name,
          phone: data.phone,
          code: data.code,
          pwd: proxy.$md5.make(data.pwd),
          check_pwd: proxy.$md5.make(data.check_pwd),
          inviter: data.inviter,
        }).then(r => {
          loading.value = !loading.value
          if (r.code === 0) {
            formRef.value.resetFields() //重置表单
            clearInterval(timer)
            second.value = 90
            proxy.$message.success(r.msg, 1.2).then(() => proxy.$router.replace('/login'), () => {
            })
          } else {
            proxy.$message.error(r.msg)
          }
        })
      }).catch(() => {
        loading.value = !loading.value
      })
    }
    return {
      loading,
      second,
      formRef,
      formData,
      formRules,
      submitForm,
      getCode,
    }
  },
})
</script>

<style scoped>
</style>
