export default {
    install: (app) => {
        // 防重复点击(指令实现)
        app.directive('preventReClick', {
            mounted: function (el) {
                el.addEventListener('click', () => {
                    if (!el.disabled) {
                        el.disabled = true
                        setTimeout(() => {
                            el.disabled = false
                        }, 2500)
                    }
                })
            }
        })
    }
}
