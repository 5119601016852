import axios from 'axios'
import store from '@/store'
import qs from 'qs'
import router from "@/router"

// 添加请求拦截器
axios.interceptors.request.use(config => {
    config.headers['Authorization'] = store.state.token.token ? store.state.token.token.value : null
    // axios.defaults.headers.common['Authorization'] = store.state.token.token
    // axios.defaults.withCredentials=true  //开启发送cookie
    return config
}, error => {
    return Promise.reject(error)
})
// 添加响应拦截器
axios.interceptors.response.use(response => {
    return response
}, error => {
    return Promise.resolve(error.response)
})

/**
 * get/post请求操作
 **/
function url_http(webUrl, url, method, params) {
    if (method.toUpperCase() === 'GET' && qs.stringify(params).length !== 0) {
        url = url + '?' + qs.stringify(params)
    }
    return axios({
        method: method,
        baseURL: webUrl,
        url: url,
        data: params,
        timeout: 10000,
        // withCredentials: true,
        headers: { 'Content-Type': 'application/json' }
    }).then( // 先判断响应状态码
        (response) => {
            if (response && (response.status === 200 || response.status === 304 || response.status === 400)) {
                if (response.data && response.data.code && response.data.code > 1) {
                    router.push({path: '/tips', query: {code: response.data.code}})
                }

                return response.data
            }
            return {code: -1, status: -404, msg: '网络异常'}
        }
    )
}


/**
 * 同时发起多个请求
 **/
function more_http(fn) {
    if (fn instanceof Array) {
        axios.all(fn).then(axios.spread(function (acct, perms) {
            console.log(acct)
            console.log(perms)
        }))
    } else {
        console.log('请求参数类型错误，必须是数组')
    }
}

export const request = {
    http: url_http,
    moreHttp: more_http
}
