<template>
  <div class="main-panel scrollbar" style="width: 100%;">
    <a-row :gutter="[20,20]">
      <a-col span="24">
        <a-card class="card-panel" style="padding: 50px 0">
          <a-result :status="result.status" :sub-title="result.sub_title" :title="result.title">
            <template #extra>
              <a-space :size="50">
                <a-button @click="$router.replace('/home')">返回主页</a-button>
                <a-button v-if="code==20041||code==20042" type="primary" @click="$router.replace('/profile')">立即认证
                </a-button>
                <a-button v-if="code==20043" type="primary" @click="$router.replace('/profile')">立即升级</a-button>
                <a-button v-if="code==20044" type="primary" @click="$router.replace('/company')">立即完善</a-button>
              </a-space>
            </template>

            <div class="desc">
              <p style="font-size: 16px">
                <strong>提示：</strong>
              </p>
            </div>
            <div v-if="code==20041||code==20042" class="desc">
              <p>
                <warning-outlined :style="{ color: '#faad14' }"/>
                您的账号尚未进行实名认证
              </p>
            </div>
            <div v-else-if="code==20043" class="desc">
              <p>
                <warning-outlined :style="{ color: '#faad14' }"/>
                您的账号权限不足，该功能需要企业实名认证
              </p>
            </div>
            <div v-else-if="code==20044" class="desc">
              <p>
                <warning-outlined :style="{ color: '#faad14' }"/>
                为了方便您使用建匠网平台的招聘、新闻、视频等栏目功能，您账号实名认证的企业信息需要进一步完善
              </p>
            </div>
            <div v-else-if="code==20051" class="desc">
              <p>
                <warning-outlined :style="{ color: '#faad14' }"/>
                未知路由访问，请确认链接地址是否正确
              </p>
            </div>
            <div v-else class="desc">
              <p>
                暂无描述！
              </p>
            </div>
          </a-result>
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import {defineComponent, getCurrentInstance, ref, watch} from "vue"

export default defineComponent({
  setup() {
    const {proxy} = getCurrentInstance()
    const codeData = proxy.$inits.codeData
    const code = ref(null)
    const result = ref()
    code.value = proxy.$route.query.code ? proxy.$route.query.code : 20000
    if (!codeData || !codeData[code.value]) {
      code.value = 20000
    }
    result.value = codeData[code.value]
    watch(() => proxy.$route.query, () => {
          code.value = proxy.$route.query.code ? proxy.$route.query.code : 20000
          result.value = codeData && codeData[code.value] ? codeData[code.value] : codeData[20000]
        }
    )

    if(proxy.$route.query.code && proxy.$route.query.msg){
      result.value = {status: 'error', title: '错误提示', sub_title: proxy.$route.query.msg}
    }
    return {
      code,
      result,
    }
  },
})
</script>
<style scoped>
.desc p {
  margin-bottom: 1em;
}
</style>
