<template>
  <SideNav :nav-data="navData"/>
  <div class="main-panel ">
    <div class="bg-white" style="height: 100%">
      <div class="sub-side-panel">
        <div class="scroll-panel scrollbar">
          <ul class="side-nav">
            <li v-for="(item ,index) in subNavData" :key="index">
              <router-link :to="item.link">{{ item.name }}</router-link>
            </li>
          </ul>
        </div>
      </div>
      <div class="sub-main-panel">
        <div class="scroll-panel scrollbar">
          <router-view/>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {defineComponent, getCurrentInstance, ref} from "vue"
import SideNav from "@/components/nav/SideNav"

export default defineComponent({
  components: {SideNav},
  setup() {
    const loading = ref(false)
    const {proxy} = getCurrentInstance()
    const navData = proxy.$inits.businessNav
    return {
      loading,
      navData,
      subNavData: [
        {name: '概览', link: '/invest'},
        {name: '我的发布', link: '/invest/send'},
        {name: '我的购买', link: '/invest/buy'},
      ],
    }
  },
})
</script>

<style scoped>

</style>
